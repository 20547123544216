import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InterfaceService } from 'src/app/services/interface.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';

@Component({
  selector: 'app-exhibitions',
  templateUrl: './exhibitions.component.html',
  styleUrls: ['./exhibitions.component.scss'],
})
export class ExhibitionsComponent implements OnInit, AfterViewChecked {
  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  yearsArr = [];

  filteredExhibitions = [];
  exhibitionsByYear = {};
  filteredIndex;
  firstLoad = false;

  get exhibitions() {
    return this.dataService.exhibitions;
  }

  constructor(
    public interfaceService: InterfaceService,
    public dataService: DataService,
    private router: Router,
    private smoothScroll: SmoothScrollService,
    public navigationService: NavigationService,
    public filters: FiltersService
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.interfaceService.displayDark = true;
    this.interfaceService.footerBackgroundChange = true;

    const tempYearArr = [];
    this.exhibitions[this.currentLanguage].forEach((exhibition, i) => {
      tempYearArr.push(exhibition.year);
      if (!this.exhibitionsByYear[exhibition.year]) {
        this.exhibitionsByYear[exhibition.year] = [];
      }
      this.exhibitionsByYear[exhibition.year].push(i);
    });

    const tempYearObj = {};
    tempYearArr.forEach((year) => {
      tempYearObj[year] = true;
    });
    this.yearsArr = Object.keys(tempYearObj);

    if (this.navigationService.isFiltered) {
      this.filteredExhibitions = this.navigationService.storedFilters;
    }

    window.addEventListener('scroll', this.interfaceService.reveal);
  }

  ngAfterViewChecked(): void {
    if (this.smoothScroll.scrollTarget && !this.firstLoad) {
      this.interfaceService.revealTimeout();
      this.smoothScroll.scrollToId(this.smoothScroll.scrollTarget);
      this.firstLoad = true;
    } else if (!this.firstLoad && !this.smoothScroll.scrollTarget) {
      this.interfaceService.revealTimeout();

      this.firstLoad = true;
    }

    if (this.filters.filterTriggered) {
      this.interfaceService.revealTimeout();
      this.filters.filterTriggered = false;
    }
    if (this.interfaceService.languageChanged) {
      this.interfaceService.revealTimeout();
      this.interfaceService.languageChanged = false;
    }
  }

  storeScrollTarget(id, i) {
    this.smoothScroll.scrollTarget = i;
    this.dataService.exhibitionId = id;
    this.router.navigate(['/exhibitions/' + id]);
  }

  isSidebarOpen() {
    if (this.interfaceService.isSidebarOpen === true) {
      this.interfaceService.toggleSidebar();
    }
  }

  filterByYear(year) {
    this.isSidebarOpen();

    this.filteredExhibitions = this.exhibitionsByYear[year];
    this.navigationService.storedFilters = this.filteredExhibitions;

    this.navigationService.isFiltered = true;

    this.filters.filterTriggered = true;
  }

  viewAll() {
    this.isSidebarOpen();
    this.filteredExhibitions = [];
    this.smoothScroll.scrollTarget = null;
    this.navigationService.resetFilters();
    this.firstLoad = false;
  }

  viewUpcomingEvents() {
    this.isSidebarOpen();

    const currentYear = new Date().getFullYear();
    const upcomingYears = Object.keys(this.exhibitionsByYear).filter(
      (year) => Number(year) >= currentYear
    );

    let tempYearArr = [];

    upcomingYears.forEach((year) => {
      tempYearArr.push(this.exhibitionsByYear[year]);
    });

    this.filteredExhibitions = [].concat.apply([], tempYearArr);
    this.navigationService.isFiltered = true;

    this.navigationService.storedFilters = this.filteredExhibitions;
    this.filters.filterTriggered = true;

    return this.filteredExhibitions;
  }

  getIndexId(exhibition, exhibitionName) {
    const exhibitionId = this.exhibitions[this.currentLanguage].findIndex(
      (expo) => {
        return expo.name === exhibitionName;
      }
    );
    return exhibitionId;
  }
}
