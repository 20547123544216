import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { InterfaceService } from 'src/app/services/interface.service';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss'],
})
export class PublicationsComponent implements OnInit, AfterViewChecked {
  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get publications() {
    return this.dataService.publications;
  }

  firstLoad = false;
  formDetails = {};
  

  constructor(
    public interfaceService: InterfaceService,
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    this.interfaceService.displayDark = true;
    this.interfaceService.footerBackgroundChange = true;

    this.interfaceService.reveal();
    window.addEventListener('scroll', this.interfaceService.reveal);
  }

  ngAfterViewChecked(): void {
    if (!this.firstLoad) {
      this.interfaceService.reveal();
      this.firstLoad = true;
    }
  }

  openForm(name) {
    this.formDetails['type'] = 'publication';
    this.formDetails['name'] = name;
    this.interfaceService.displayForm = true;
  }

  navigateExternal(url) {
    window.open(url, '_blank');
  }
}
