import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'src/app/services/connection.service';
import { DataService } from 'src/app/services/data.service';
import { InterfaceService } from 'src/app/services/interface.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  formData: { email: string };
  version = environment.VERSION;

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get organizationData() {
    return this.dataService.organizationData;
  }

  instagramLink = 'https://www.instagram.com/jbagotarqueologia/';

  exhibitionUrl = environment.exhibitionUrl;

  constructor(
    public interfaceService: InterfaceService,
    public navigationService: NavigationService,
    private dataService: DataService,
    private connectionService: ConnectionService
  ) {}

  ngOnInit(): void {}

  navigateExternal(url) {
    window.open(url, '_blank');
  }

  handleSubmit(form) {
    const { value: values } = form;
    console.log('FORM', form);
    console.log('form values', values);
    this.connectionService.addSubscriber(values).subscribe((response) => {
      console.log('RESPONSE', response);
    });
    form.reset();
  }
}
