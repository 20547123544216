<div *ngIf="artist" class="bg-light-offWhiteDarker py-20 flex justify-center items-center text-gray-darkest md:pt-0">
    <div class="3xlmin:w-350 max-width artist-container flex md:flex-col">

        <div class="img-container mr-12 md:mr-0 md:mb-6 reveal">
            <img class="w-full h-full object-cover"
                [src]="getArtistImg() ? getArtistImg() : 'assets/img/thumbnail-placeholder.jpg'" alt="">
        </div>
        <div class="artist-content">
            <h3 class="uppercase text-2xl reveal">{{artist.name[currentLanguage]}} <span
                    *ngIf="artist.surname && artist.surname[currentLanguage]">{{artist.surname[currentLanguage]}}</span>
            </h3>
            <div>
                <div class="font-montserrat reveal text-sm">
                    <span
                        *ngIf="artist.birth_place && artist.birth_place[currentLanguage]">{{artist.birth_place[currentLanguage]}}</span>
                    <span>, </span>
                    <span
                        *ngIf="artist.birth_date && artist.birth_date[currentLanguage]">{{artist.birth_date[currentLanguage]}}</span>
                    <span> - </span>
                    <span
                        *ngIf="artist.death_place && artist.death_place[currentLanguage]">{{artist.death_place[currentLanguage]}}</span>
                    <span>, </span>
                    <span
                        *ngIf="artist.death_date && artist.death_date[currentLanguage]">{{artist.death_date[currentLanguage]}}</span>
                </div>
            </div>

            <div class="text-sm font-montserrat reveal">

                <p *ngIf="artist.client_bio && artist.client_bio[currentLanguage]" class="whitespace-pre-line mt-6"
                    [innerHtml]="artist.client_bio[currentLanguage]">
                </p>
            </div>


        </div>


    </div>

</div>