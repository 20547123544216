<div *ngIf="artworks" class="pt-40 text-sm sm:pt-24 sm:pb-14 lg:pt-32 3xlmin:flex 3xlmin:flex-col 3xlmin:items-center">
    <div class="relative z-10 border-b w-full flex justify-center text-xs reveal ">

        <div
            class="flex justify-between items-center w-full px-36 pb-8 justify-change md:px-6 md:border-gray-dropdown md:pb-4 lg:px-10 lg:items-start lg:justify-center lg:flex-wrap  md:justify-between md:items-center 3xlmin:w-350 3xlmin:px-0">
            <div class="flex lg:mb-6 md:justify-center md:mb-0">
                <div class="flex items-center cursor-pointer mr-12 lg:mr-9 xxs:mr-1  layout-1"
                    (click)="interfaceService.toggleSidebar()">
                    <span class=" font-bold mr-2 whitespace-nowrap">{{ generalTexts[currentLanguage].filter }}</span>
                    <img src="assets/img/filter.svg" alt="">
                </div>

                <div class="flex  md:hidden lg:flex-wrap md:flex-nowrap">
                    <div class="dropdown">
                        <button class="border border-gray-medium w-48 h-10 uppercase  mr-8 dropbtn lg:mr-5 ">{{
                            generalTexts[currentLanguage].cultures }}</button>
                        <div class="dropdown-content w-48">
                            <label *ngFor="let item of culturesArr; let i = index" class="custom-checkbox capitalize">
                                <input [id]="item" type="checkbox" (change)="triggerCheck(item, $event)">
                                <p class="checkmark"></p>
                                <span *ngIf="entityCache[item]">{{entityCache[item].name[currentLanguage]}}</span>
                            </label>

                        </div>
                    </div>
                    <div class="dropdown">
                        <button class="border border-gray-medium w-48 h-10 uppercase  mr-8 dropbtn lg:mr-5">{{
                            generalTexts[currentLanguage].typologies }}</button>
                        <div class="dropdown-content w-48">
                            <label *ngFor="let item of typologiesArr; let i = index" class="custom-checkbox capitalize">
                                <input [id]="item" type="checkbox" (change)="triggerCheck(item, $event)">
                                <p class="checkmark"></p>
                                <span *ngIf="entityCache[item]">{{entityCache[item].name[currentLanguage]}}</span>
                            </label>

                        </div>
                    </div>
                    <div class="dropdown">
                        <button class="border border-gray-medium w-48 h-10 uppercase  mr-8 dropbtn lg:mr-5">{{
                            generalTexts[currentLanguage].materials }}</button>
                        <div class="dropdown-content w-48">

                            <label *ngFor="let item of materialsArr; let i = index" class="custom-checkbox capitalize">
                                <input [id]="item" type="checkbox" (change)="triggerCheck(item, $event)">
                                <p class="checkmark"></p>
                                <span *ngIf="entityCache[item]">{{entityCache[item].name[currentLanguage]}}</span>
                            </label>

                        </div>
                    </div>
                </div>
            </div>
            <div class="search-container">
                <input type="text" (keyup)="filters.search($event)" [(ngModel)]="filters.searchValue"
                    class="form-input search-bg focus:border-gray-medium focus:ring-0 rounded-full border border-gray-medium w-80 h-10 pl-5  md:border-gray-dropdown"
                    [placeholder]="generalTexts[currentLanguage].search" />

            </div>
        </div>

    </div>

    <div class="flex items-center cursor-pointer px-6 pt-4 layout-2" (click)="interfaceService.toggleSidebar()">
        <span class=" font-bold mr-2 whitespace-nowrap">{{ generalTexts[currentLanguage].filter }}</span>
        <img src="assets/img/filter.svg" alt="">
    </div>

    <div *ngIf="!filters.displayFiltered && artworks"
        class="px-36 flex flex-wrap justify-center my-24 sm:px-4 sm:my-6 md:px-8 lg:px-12 lg:my-16 3xlmin:w-350 3xlmin:px-0">
        <div *ngFor="let artwork of artworks; let i = index" class="reveal">
            <a *ngIf="artwork" [routerLink]="['/artworks/' + artwork.customId]">
                <div [id]="artwork.customId"
                    class="flex flex-col items-center text-gray-darkest mb-14 mx-6 w-64 cursor-pointer md:w-56 sm:w-40 sm:mx-3 sm:mb-6 xs:mx-1 xs:w-full relative"
                    (click)="storeScrollTarget(artwork.customId)">

                    <div class=" w-64 h-64 md:w-56 md:h-56 sm:w-40 sm:h-40">
                        <div class="w-64 h-64 md:w-56 md:h-56 sm:w-40 sm:h-40 placeholder-container">
                        </div>
                        <img *ngIf="getCarouselMainImg(artwork.customId, 'imageCarousel')"
                            class=" w-64 h-64 object-cover md:w-56 md:h-56 sm:w-40 sm:h-40 xs:w-full xs:h-auto"
                            [src]="getCarouselMainImg(artwork.customId, 'imageCarousel')" alt="">
                    </div>
                    <hr class="border-t w-12 border-gray-medium mt-5 mb-4">

                    <div class="font-trajan text-center"><span *ngIf="artwork.name"
                            class="uppercase line-clamp-3">{{artwork.name[currentLanguage]}}</span>
                    </div>
                    <div class="text-center text-xs"><span
                            *ngIf="getFirstEntityName(artwork.customId, 'culture') && getFirstEntityName(artwork.customId, 'culture')[currentLanguage]">{{getFirstEntityName(artwork.customId,
                            'culture')[currentLanguage]}}
                            ·
                        </span><span
                            *ngIf="getFirstEntityName(artwork.customId, 'period') && getFirstEntityName(artwork.customId, 'period')[currentLanguage]">{{getFirstEntityName(artwork.customId,
                            'period')[currentLanguage]}}</span>
                    </div>


                </div>
            </a>
        </div>

    </div>



    <div *ngIf="filters.displayFiltered && !filters.filteredArtworks.length" class="py-8 text-center"><span>{{
            generalTexts[currentLanguage].not_found }}</span></div>
    <div *ngIf="filters.displayFiltered && filters.filteredArtworks.length"
        class="px-36 flex flex-wrap justify-center my-24 sm:px-4 sm:my-6 md:px-8 lg:my-16 lg:px-12 3xlmin:w-350 3xlmin:px-0">

        <div *ngFor="let artwork of filters.filteredArtworks" class="reveal">

            <a *ngIf="artwork" [routerLink]="['/artworks/' + artwork.customId]">
                <div [id]="artwork.customId"
                    class="flex flex-col items-center text-gray-darkest mb-14 mx-6 w-64 cursor-pointer md:w-56 sm:w-40 sm:mx-3 sm:mb-6 xs:mx-1 xs:w-full"
                    (click)="storeScrollTarget(artwork.customId)">

                    <div class=" w-64 h-64 md:w-56 md:h-56 sm:w-40 sm:h-40 relative">
                        <div class="w-64 h-64 md:w-56 md:h-56 sm:w-40 sm:h-40 placeholder-container">
                        </div>

                        <img *ngIf="getCarouselMainImg(artwork.customId, 'imageCarousel')"
                            class=" w-64 h-64 object-cover md:w-56 md:h-56 sm:w-40 sm:h-40 xs:w-full xs:h-auto"
                            [src]="getCarouselMainImg(artwork.customId, 'imageCarousel')" alt="">
                    </div>
                    <hr class="border-t w-12 border-gray-medium mt-5 mb-4">

                    <div class="font-trajan text-center"><span *ngIf="artwork.name"
                            class="uppercase line-clamp-3">{{artwork.name[currentLanguage]}}</span>
                    </div>
                    <div class="text-center text-xs"><span *ngIf="getFirstEntityName(artwork.customId,
                        'culture')">{{getFirstEntityName(artwork.customId,
                            'culture')[currentLanguage]}} ·
                        </span><span
                            *ngIf="getFirstEntityName(artwork.customId, 'period')">{{getFirstEntityName(artwork.customId,
                            'period')[currentLanguage]}}</span></div>
                </div>

            </a>

        </div>

    </div>

</div>

<!-- SIDEBAR -->
<app-content-sidebar>
    <div class="flex w-full h-full overflow-y-auto hide-scrollbar">
        <div class="flex flex-col justify-between  py-5 w-full">

            <div class="mb-6 pb-4 border-b px-6 flex flex-col">
                <h4 class="text-gray-outline text-lg mb-2">{{ generalTexts[currentLanguage].cultures }}</h4>

                <label *ngFor="let item of culturesArr; let i = index" class="custom-checkbox capitalize">
                    <input [id]="'sidebar-' + item" type="checkbox" (change)="triggerCheck(item, $event)">
                    <p class="checkmark"></p>
                    <span *ngIf="entityCache[item]">{{entityCache[item].name[currentLanguage]}}</span>
                </label>
            </div>

            <div class="mb-6 pb-4 border-b px-6 flex flex-col">
                <h4 class="text-gray-outline text-lg mb-2">{{ generalTexts[currentLanguage].typologies }}</h4>

                <label *ngFor="let item of typologiesArr; let i = index" class="custom-checkbox capitalize">
                    <input [id]="'sidebar-' + item" type="checkbox" (change)="triggerCheck(item, $event)">
                    <p class="checkmark"></p>
                    <span *ngIf="entityCache[item]">{{entityCache[item].name[currentLanguage]}}</span>
                </label>
            </div>


            <div class="mb-6 pb-20 border-b px-6 flex flex-col">
                <h4 class="text-gray-outline text-lg mb-2">{{ generalTexts[currentLanguage].materials }}</h4>

                <label *ngFor="let item of materialsArr; let i = index" class="custom-checkbox capitalize">
                    <input [id]="'sidebar-' + item" type="checkbox" (change)="triggerCheck(item, $event)">
                    <p class="checkmark"></p>
                    <span *ngIf="entityCache[item]">{{entityCache[item].name[currentLanguage]}}</span>
                </label>
            </div>


        </div>
    </div>

</app-content-sidebar>