<div class="pt-40 pb-12 text-sm sm:pt-24">
    <div class="mb-12 pb-14 sm:border-0 sm:pb-0 sm:mb-6 reveal">

        <div class="flex justify-center mb-3">
            <h3 class="uppercase text-center text-gray-darkest text-2xl sm:px-12">{{
                generalTexts[currentLanguage].paintings }}</h3>
        </div>
        <div class="flex justify-between px-6 pb-3 border-b sm:border-gray-dropdown  reveal">
            <div class="smmin:hidden flex items-center mr-9 cursor-pointer xxs:mr-1 layout-1"
                (click)="interfaceService.toggleSidebar()">
                <span class="font-bold mr-2 whitespace-nowrap">{{ generalTexts[currentLanguage].filter }}</span>
                <img src="assets/img/filter.svg" alt="">
            </div>
            <div class="search-container">
                <input type="text" (keyup)="filters.search($event, 'paintings')" [(ngModel)]="filters.searchValue"
                    class="form-input rounded-full border focus:border-gray-medium focus:ring-0 h-10 pl-5 search-bg sm:w-40 border-gray-dropdown"
                    [placeholder]="generalTexts[currentLanguage].search">
            </div>
        </div>
    </div>

    <div class="flex items-center cursor-pointer px-6 pt-4 layout-2 reveal" (click)="interfaceService.toggleSidebar()">
        <span class=" font-bold mr-2 whitespace-nowrap">{{ generalTexts[currentLanguage].filter }}</span>
        <img src="assets/img/filter.svg" alt="">
    </div>

    <div class="px-20 flex sm:px-4 xs:px-4">

        <div class="block sm:hidden reveal">
            <div class="w-56 mb-14 flex flex-col">
                <h4 class="text-gray-outline text-xl border-b pb-4 mb-4 capitalize">{{
                    generalTexts[currentLanguage].artists }}</h4>

                <label *ngFor="let item of artistsArr; let i = index" class="custom-checkbox capitalize">
                    <input [id]="item" type="checkbox" (change)="triggerCheck(item, $event)">
                    <p class="checkmark"></p>
                    <span *ngIf="entityCache[item]">
                        <span
                            *ngIf="entityCache[item].surname[currentLanguage]">{{entityCache[item].surname[currentLanguage]}},
                        </span>

                        {{entityCache[item].name[currentLanguage]}}</span>
                </label>
            </div>
        </div>

        <div class="flex justify-center sm:ml-0 w-full painting-main-container sm:my-6">

            <ngx-masonry *ngIf="!filters.displayFiltered && paintings" [options]="masonryOptions" #imageContainer
                [ordered]="true" class="flex flex-wrap w-full ml-6 sm:ml-0">
                <div ngxMasonryItem class="masonry-item" *ngFor="let painting of paintings; let i = index;"
                    class="painting">
                    <a *ngIf="painting" [routerLink]="['/paintings/' + painting.customId]" class="">
                        <div [id]="painting.customId" (click)="storeScrollTarget(painting.customId)"
                            class="flex flex-col items-center text-gray-darkest cursor-pointer mb-9 sm:mx-0 sm:mb-6 xs:mx-1 xs:w-full height-set">


                            <img class=" xs:h-auto" [src]="getCarouselMainImg(painting.customId, 'imageCarousel')"
                                alt="">
                            <div class="flex justify-center items-center flex-col content">

                                <hr class="border-t w-12 border-gray-medium mt-5 mb-3">
                                <div class=" text-center w-full"><span *ngIf="painting.name"
                                        class="uppercase text-gray-darkest font-trajan line-clamp-3">{{painting.name[currentLanguage]}}</span>
                                </div>
                                <div class="text-center "><span
                                        *ngIf="getFirstEntityName(painting.customId, 'artist')">{{getFirstEntityName(painting.customId,
                                        'artist')[currentLanguage]}}
                                    </span></div>
                            </div>
                        </div>
                    </a>
                </div>
            </ngx-masonry>
            <!-- <ngx-masonry *ngIf="!filters.displayFiltered && paintings" [options]="masonryOptions" #imageContainer [ordered]="true"
                class="flex flex-wrap w-full ml-6 sm:ml-0">
                <div ngxMasonryItem class="masonry-item" *ngFor="let painting of paintings; let i = index;"
                    class="painting">
                    <a *ngIf="painting" [routerLink]="['/paintings/' + painting.customId]" class="">
                        <div [id]="painting.customId" (click)="storeScrollTarget(painting.customId)"
                            class="flex flex-col items-center text-gray-darkest cursor-pointer w-full mb-9 sm:mx-0 sm:mb-6 xs:mx-1 xs:w-full">
                            <img class="w-full xs:h-auto" [src]="getCarouselMainImg(painting.customId, 'imageCarousel')"
                                alt="">
                            <hr class="border-t w-12 border-gray-medium mt-5 mb-3">
                            <span>{{painting.sortIndex}}</span>
                            <div class=" text-center w-full"><span *ngIf="painting.name"
                                    class="uppercase text-gray-darkest font-trajan line-clamp-3">{{painting.name[currentLanguage]}}</span>
                            </div>
                            <div class="text-center "><span
                                    *ngIf="getFirstEntityName(painting.customId, 'artist')">{{getFirstEntityName(painting.customId,
                                    'artist')[currentLanguage]}}
                                </span></div>
                        </div>
                    </a>
                </div>
            </ngx-masonry> -->


            <div *ngIf="filters.displayFiltered && !filters.filteredArtworks.length" class="p-8 text-center"><span>{{
                    generalTexts[currentLanguage].not_found }}</span></div>



            <ngx-masonry *ngIf="filters.displayFiltered && filters.filteredArtworks.length" [options]="masonryOptions"
                [ordered]="true" class="flex flex-wrap w-full ml-6 sm:ml-0">
                <div ngxMasonryItem class="masonry-item" *ngFor="let painting of filters.filteredArtworks"
                    class="painting">
                    <a *ngIf="painting" [routerLink]="['/paintings/' + painting.customId]" class="">




                        <div [id]="painting.customId" (click)="storeScrollTarget(painting.customId)"
                            class="flex flex-col items-center text-gray-darkest cursor-pointer mb-9 sm:mx-0 sm:mb-6 xs:mx-1 xs:w-full height-set">


                            <img class=" xs:h-auto" [src]="getCarouselMainImg(painting.customId, 'imageCarousel')"
                                alt="">
                            <div class="flex justify-center items-center flex-col content">

                                <hr class="border-t w-12 border-gray-medium mt-5 mb-3">
                                <div class=" text-center w-full"><span *ngIf="painting.name"
                                        class="uppercase text-gray-darkest font-trajan line-clamp-3">{{painting.name[currentLanguage]}}</span>
                                </div>
                                <div class="text-center "><span
                                        *ngIf="getFirstEntityName(painting.customId, 'artist')">{{getFirstEntityName(painting.customId,
                                        'artist')[currentLanguage]}}
                                    </span></div>
                            </div>
                        </div>


<!-- 

                        <div [id]="painting.customId" (click)="storeScrollTarget(painting.customId)"
                            class="flex flex-col items-center text-gray-darkest cursor-pointer w-full mb-9 sm:mx-0 sm:mb-6 xs:mx-1 xs:w-full">
                            <img class="w-full xs:h-auto" [src]="getCarouselMainImg(painting.customId, 'imageCarousel')"
                                alt="">
                            <hr class="border-t w-12 border-gray-medium mt-5 mb-3">

                            <div class=" text-center w-full"><span *ngIf="painting.name"
                                    class="uppercase text-gray-darkest font-trajan line-clamp-3">{{painting.name[currentLanguage]}}</span>
                            </div>
                            <div class="text-center "><span
                                    *ngIf="getFirstEntityName(painting.customId, 'artist')">{{getFirstEntityName(painting.customId,
                                    'artist')[currentLanguage]}}
                                </span></div>
                        </div> -->
                    </a>
                </div>
            </ngx-masonry>

        </div>


    </div>


</div>

<!-- SIDEBAR -->
<app-content-sidebar>
    <div class="flex flex-col py-5 border-b h-full overflow-y-auto hide-scrollbar text-sm">
        <div class="px-6 mb-6 pb-3 border-b flex flex-col">
            <h4 class="text-gray-outline text-xl mb-4">{{ generalTexts[currentLanguage].artists }}</h4>

            <label *ngFor="let item of artistsArr; let i = index" class="custom-checkbox mb-2 capitalize">
                <input [id]="'sidebar-' + item" type="checkbox" (change)="triggerCheck(item, $event)">
                <p class="checkmark"></p>
                <span *ngIf="entityCache[item] && entityCache[item].surname[currentLanguage]">{{entityCache[item].surname[currentLanguage]}},
                </span>
                <span *ngIf="entityCache[item]">{{entityCache[item].name[currentLanguage]}}</span>
            </label>

        </div>

    </div>
</app-content-sidebar>