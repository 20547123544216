import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
} from '@angular/core';
import { ConnectionService } from 'src/app/services/connection.service';
import { DataService } from 'src/app/services/data.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InterfaceService } from 'src/app/services/interface.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';

@Component({
  selector: 'app-artworks',
  templateUrl: './artworks.component.html',
  styleUrls: ['./artworks.component.scss'],
})
export class ArtworksComponent implements OnInit, AfterViewChecked {
  hideSearchIcon = false;
  firstLoad = false;

  get filterEntities() {
    return this.dataService.worksOfArtData.web_entities;
  }

  get culturesArr() {
    const arr = Object.keys(this.artworkFilters.culture);
    return this.interfaceService.sortItems(arr, 'name');
  }

  get materialsArr() {
    const arr = Object.keys(this.artworkFilters.material);
    return this.interfaceService.sortItems(arr, 'name');
  }

  get typologiesArr() {
    const arr = Object.keys(this.artworkFilters.typology);
    return this.interfaceService.sortItems(arr, 'name');
  }

  get artworks() {
    return this.dataService.exhibitionArtworks;
  }

  get artworkFilters() {
    return this.filters.artworkFilters;
  }

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get entityCache() {
    return this.dataService.entityCache;
  }

  constructor(
    public interfaceService: InterfaceService,
    public dataService: DataService,
    public filters: FiltersService,
    private smoothScroll: SmoothScrollService,
    private elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private navigationService: NavigationService,
    private connectionService: ConnectionService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.interfaceService.reveal();
  }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.interfaceService.displayDark = true;
    this.interfaceService.isLightboxOpen = false;
    this.interfaceService.footerBackgroundChange = true;

    //????
    this.filters.displayFiltered = false;
    
    if (!this.navigationService.backBtnClicked) {
      this.filters.resetArtworkFilters();
      
      const exh = 'eues20009worksofart';
      
      if (!this.artworks || !this.artworks.length) {
        this.dataService.fetchAllArtworks(exh, 'collection').then((res) => {
          // console.log('RES', res);
          
          this.setFilters();
          this.firstLoad = false;
        });
      } else {
        this.setFilters();
        this.firstLoad = false;
      }
    }
    
    this.interfaceService.reveal();
    window.addEventListener('scroll', this.interfaceService.reveal);
  }

  ngAfterViewChecked(): void {
    if (this.smoothScroll.scrollTarget && !this.firstLoad) {
      if (this.filters.savedFilteredArtworks.length) {
        this.filters.displayFilteredResults((item) => this.toggleCheck(item));
        this.changeDetector.detectChanges();
        this.navigationService.backBtnClicked = false;
      } else {
        this.smoothScroll.scrollToId(this.smoothScroll.scrollTarget);
      }

      this.firstLoad = true;
    } else if (
      !this.firstLoad &&
      this.artworks &&
      !this.smoothScroll.scrollTarget
    ) {
      this.firstLoad = true;
    }
  }

  setFilters() {
    this.filterEntities.forEach((entity) => {
      this.filters.buildFilters(entity);
    });

    this.artworks.forEach((artwork) => {
      this.filters.storedFilters = [];
      artwork.entities.forEach((entity) => {
        if (entity && entity.customId && this.filters.displayFilter(entity)) {
          this.filters.addArtworkToFilters(artwork, entity);
        }
      });
    });
    this.firstLoad = false;

    this.interfaceService.revealTimeout();
  }


  toggleCheck(item) {
    return this.filters.toggleCheck(item);
  }

  triggerCheck(item, e?) {
    this.filters.triggerCheckboxFilters(item, e);
  }

  storeScrollTarget(id: string) {
    this.smoothScroll.scrollTarget = id;
  }

  getCarouselMainImg(artworkId, mediaType) {
    return this.dataService.getMainMedia(artworkId, mediaType);
  }

  getFirstEntityName(artwork, property) {
    if (this.dataService.getEntityList(artwork, property)) {
      return this.dataService.getEntityList(artwork, property)[0].name;
    } else {
      return null;
    }
  }
}
