<div class=" h-full resizedSm:h-auto text-sm lg:h-auto py-24 px-16 flex justify-center items-center work-container">
    <div class="flex pt-12 inner-container">

        <div class="w-96 resized-img">
            <img class="w-full" src="assets/img/mock-images/work-with-us-img.png" alt="">
        </div>

        <div class="content-container">
            <div class="  w-full text-gray-outline mb-14 mt-4 lg:mb-10 lg:mt-0 sm:mb-8">

                <h3 class="font-trajanBold uppercase text-2xl mb-4">{{ generalTexts[currentLanguage].work_with_us }}
                </h3>
                <div><span>{{ generalTexts[currentLanguage].available_positions }}:</span></div>
            </div>

            <div class="border-b-2 border-t-2 p-8 job-role-container">
                <div *ngFor="let job of availablePositions[currentLanguage]">
                    <h4 class="text-2xl font-montserrat mb-4">{{ job.role }}</h4>
                    <div class="mb-6 text-gray-grayText"><span>{{ job.education }}</span></div>
                    <div class="mb-1"><span class="font-bold">{{ generalTexts[currentLanguage].requirements }}</span>
                    </div>
                    <div class="mb-6 text-gray-grayText"><span>{{ job.requirements }}</span></div>
                    <div class="text-gray-outline"><span>{{ contact[currentLanguage] }} <a
                                class="font-bold " href="mailto:job@jbagot.art">job&#64;jbagot.art</a></span></div>
                </div>

            </div>
        </div>

    </div>

</div>