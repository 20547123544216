<div class="3xlmin:w-full 3xlmin:flex 3xlmin:flex-col 3xlmin:items-center text-sm">
    <div class="w-full h-bg-img flex flex-col justify-center items-center exhibition-top-section text-white sm:h-80"
        [ngStyle]="{'background-image': 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(' + headerImg +')'}">
    </div>

    <div class=" expo-details-container text-white flex flex-col items-center w-full absolute top-0 ">
        <h3 class="text-2xl font-bold pb-1 reveal whitespace-pre-line text-center">{{ exhibition.name }}</h3>
        <div class="reveal"><span class="uppercase">{{ exhibition.date}}</span></div>
        <div class="reveal whitespace-pre-line text-center"><span>{{ exhibition.location}}</span></div>
    </div>
    <div class="absolute top-0 w-full flex justify-center arrow-down ">
        <img class=" cursor-pointer reveal" src="./../../../../assets/img/arrow-down-long.png" alt=""
            (click)="scrollIntoView('scrollTarget')">
    </div>


    <div id="scrollTarget"
        class="py-16 px-10% flex sm:px-6 sm:flex-col sm:pt-2 sm:pb-12 md:flex-col 3xlmin:w-350 3xlmin:px-0">
        <div
            class="py-8 pr-16 text-justify flex flex-col items-start sm:pr-0  sm:items-center sm:pb-12 sm:pt-4 md:pr-0 md:pt-0">
            <p class="expo-desc reveal whitespace-pre-line">{{ exhibition.description}}</p>
            <hr class="border-t w-10 mt-8 mb-4 reveal">
            <div class="flex justify-between items-center w-full lg:flex-col lg:items-start sm:items-center">
                <div class="flex flex-col items-start w-80 mr-4 lg:w-auto lg:mr-0 sm:items-center reveal">
                    <p class="font-bold uppercase whitespace-pre-line sm:text-center">{{exhibition.name}}</p>
                    <p class="font-bold sm:text-center">{{exhibition.date}}</p>
                    <p class="font-bold text-left whitespace-pre-line sm:text-center">{{exhibition.location}}</p>
                </div>

                <div class="flex text-xs flex-wrap justify-end btn-container">
                    <button *ngIf="exhibition.videoPath" (click)="openVideoPlayer()"
                        [ngClass]="{' video-btn sm:mx-2': exhibition.url}"
                        class="uppercase whitespace-nowrap bg-black text-white h-10 w-36 mt-0 sm:w-48 sm:mt-4 sm:px-0 lg:mt-6 flex items-center justify-center reveal"><span
                            class=" whitespace-pre">{{ generalTexts[currentLanguage].watch}} <span class="font-bold">{{
                                generalTexts[currentLanguage].video}}</span> </span></button>

                    <a *ngIf="exhibition.url" href="{{exhibition.url}}" target="_blank"
                        [ngClass]="{'web-btn ml-4': exhibition.videoPath}"
                        class="uppercase whitespace-nowrap bg-black text-white h-10 w-36 mt-0 sm:w-48 sm:mt-4 sm:px-0 lg:mt-6 flex items-center justify-center reveal"><span
                            class=" whitespace-pre">{{ generalTexts[currentLanguage].go_to}} <span class="font-bold">{{
                                generalTexts[currentLanguage].web}}</span> </span></a>

                </div>
            </div>
        </div>
        <div>
            <div class="h-full w-128 sm:w-full  lg:w-96 md:w-full md:h-auto squared-container reveal">
                <img class="w-full " src="{{exhibition.imgPathSquared}}" alt="">
            </div>
        </div>
    </div>

    <div *ngIf="exhibition.exhibitionImgPaths.length" class="bg-light-offWhite py-20 h-full w-full ">
        <div class="px-10% sm:text-center reveal">
            <h3 class="text-2xl uppercase">{{ generalTexts[currentLanguage].expo_images }}</h3>
            <span class="uppercase">{{ generalTexts[currentLanguage].location }}</span>
        </div>
        <div class="ml-10% mt-6 reveal sm:ml-0">
            <app-exhibitions-swiper [expoImages]="exhibition.exhibitionImgPaths"></app-exhibitions-swiper>
        </div>
    </div>

    <div *ngIf="exhibition.artworks.length && currentLanguage"
        class="pt-20 pb-12 flex flex-col items-center px-36 sm:px-6 lg:px-16 3xlmin:w-350 3xlmin:px-0">
        <h3 class="text-2xl sm:text-center uppercase reveal">{{ generalTexts[currentLanguage].expo_works }}</h3>
        <span class="uppercase sm:text-center reveal">{{ generalTexts[currentLanguage].selection }}</span>
        <div class="flex flex-wrap justify-center mt-12">

            <div *ngFor="let artwork of exhibitionArtworks">
                <div *ngIf="artwork && artworksCache[artwork.customId]"
                    [ngClass]="isCustom ? 'cursor-default' : 'cursor-pointer'"
                    class="flex flex-col items-center text-gray-darkest mb-14 mx-6 w-88 md:w-56 sm:w-40 sm:mx-3 sm:mb-6 xs:mx-1 xs:w-full artwork-details reveal"
                    (click)="navigateToArtWorkDetails(artwork.customId)">
                    <div class="w-full">
                        <img class=" w-88 h-88 object-cover md:w-56 md:h-56 sm:w-40 sm:h-40 xs:w-full xs:h-auto"
                            [src]="getCarouselMainImg(artwork.customId, 'imageCarousel')" alt="">
                    </div>
                    <hr class="border-t w-12 border-gray-medium mt-5 mb-4">

                    <div class="font-trajan text-center"><span
                            class="uppercase line-clamp-3">{{artwork.name[currentLanguage]}}</span>
                    </div>
                    <div class="text-center text-xs">
                        <div *ngIf="artworkEntitiesCache[artwork.customId]" class="text-center text-xs">
                            <span
                                *ngIf="getFirstEntityName(artwork.customId, 'culture') && getFirstEntityName(artwork.customId, 'culture')[currentLanguage]">{{getFirstEntityName(artwork.customId,
                                'culture')[currentLanguage]}}</span><span
                                *ngIf="getFirstEntityName(artwork.customId, 'culture') && getFirstEntityName(artwork.customId, 'culture')[currentLanguage] && getFirstEntityName(artwork.customId, 'period') && getFirstEntityName(artwork.customId, 'period')[currentLanguage]">
                                · </span>
                            <span
                                *ngIf="getFirstEntityName(artwork.customId, 'period') && getFirstEntityName(artwork.customId, 'period')[currentLanguage]">{{getFirstEntityName(artwork.customId,
                                'period')[currentLanguage]}}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>

<div>
    <app-video-player></app-video-player>
</div>