import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { InterfaceService } from 'src/app/services/interface.service';

@Component({
  selector: 'app-privacy-cookies-layout',
  templateUrl: './privacy-cookies-layout.component.html',
  styleUrls: ['./privacy-cookies-layout.component.scss'],
})
export class PrivacyCookiesLayoutComponent implements OnInit, AfterViewChecked {
  path = window.location.pathname;

  currentPage: any;
  firstLoad = false;

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get currentData() {
    return this.dataService.data;
  }

  constructor(
    public interfaceService: InterfaceService,
    public dataService: DataService
  ) {}

  ngAfterViewChecked(): void {
    if (
      document.getElementsByClassName(
        'list-item'
      ) as HTMLCollectionOf<HTMLElement>
    ) {
      const listItems = document.getElementsByClassName(
        'list-item'
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < listItems.length; i++) {
        listItems[i].style.color = 'black';
      }
    }

    if (document.getElementsByTagName('td') as HTMLCollectionOf<HTMLElement>) {
      const cells = document.getElementsByTagName(
        'td'
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < cells.length; i++) {
        cells[i].style.paddingRight = '24px';
        cells[i].style.whiteSpace = 'nowrap';
      }
    }

    if (
      (document.getElementsByTagName(
        'table'
      ) as HTMLCollectionOf<HTMLElement>) &&
      this.path === '/conditions'
    ) {
      const table = document.getElementsByTagName(
        'table'
      ) as HTMLCollectionOf<HTMLElement>;
      if (table) {
        table[0].style.marginLeft = '40%';
      }
    }

    if (
      document.getElementsByClassName(
        'table-points'
      ) as HTMLCollectionOf<HTMLElement>
    ) {
      const tablePoints = document.getElementsByClassName(
        'table-points'
      ) as HTMLCollectionOf<HTMLElement>;

      for (let i = 0; i < tablePoints.length; i++) {
        tablePoints[i].style.marginLeft = '15%';
      }
    }

    if (!this.firstLoad || this.interfaceService.languageChanged) {
      this.interfaceService.reveal();
      this.firstLoad = true;
      this.interfaceService.languageChanged = false;
    }
  }

  ngOnInit(): void {
    this.interfaceService.displayDark = true;
    this.interfaceService.footerBackgroundChange = true;

    if (this.path === '/privacy') {
      this.currentPage = 'privacy';
    } else if (this.path === '/cookies') {
      this.currentPage = 'cookies';
    } else if (this.path === '/conditions') {
      this.currentPage = 'conditions';
    } else if (this.path === '/legal') {
      this.currentPage = 'legal';
    }

    this.interfaceService.reveal();
    window.addEventListener('scroll', this.interfaceService.reveal);
  }
}
