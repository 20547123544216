<div class=" w-full h-full bg-black flex text-sm relative">

    <div class=" absolute home-swiper-container flex-grow top-0">
        <app-home-swiper></app-home-swiper>
    </div>
    <div
        class="flex justify-between items-center uppercase font-trajan text-xs py-2 px-4 bg-white w-full absolute bottom-0 bar-container">
        <div class=" flex md:hidden">
            <span class="cursor-pointer mr-4" (click)="navigateExternal('https://blog.jbagot.com')">Blog</span>
            <span class="cursor-pointer mr-4" (click)="navigateExternal(exhibitionUrl)">{{
                generalTexts[currentLanguage].private_gallery }}</span>
            <span class="cursor-pointer mr-4" (click)="navigationService.navigateTo('appraisers')">{{
                generalTexts[currentLanguage].appraisers }}</span>
            <span class="cursor-pointer"
                (click)="navigationService.navigateTo('work-with-us')">{{generalTexts[currentLanguage].work_with_us}}</span>
        </div>
        <div class="flex items-center cursor-pointer z-10" (click)="scrollIntoView('scrollTarget')">
            <span class="mr-4">{{ generalTexts[currentLanguage].scroll }}</span>
            <div class="border border-gray-outline rounded-full w-6 h-6 flex justify-center items-center">
                <img class="w-2" src="assets/img/arrow-down.svg" alt="">
            </div>
        </div>
    </div>


</div>

<!------------------------------- LAST AQCUISITIONS -------------------------------------->

<div class="w-full text-sm bg-white 3xlmin:flex 3xlmin:justify-center 3xlmin:items-center resizedSm:h-auto resizedSm:pb-8 resizedXl:max-h-224"
    id="scrollTarget">

    <div class="pt-16 pb-32 px-10% sm:pt-16 w-full max-size xl:pb-16">
        <div class="flex justify-between mb-9 sm:justify-center ">
            <div class="uppercase text-gray-darkest text-left sm:text-center">
                <h3 class="text-3xl mb-1 sm:text-3xl">{{ generalTexts[currentLanguage].acquisitions }}</h3>
                <span class="">{{ generalTexts[currentLanguage].artworks }}</span>
            </div>
            <div class="block sm:hidden">
                <button class="flex items-center justify-center border border-gray-medium px-6 py-3"
                    (click)="navigationService.navigateTo('artworks')">
                    <span class="text-gray-darkest text-sm whitespace-nowrap">{{ generalTexts[currentLanguage].discover
                        }}</span>
                    <img class="h-3 w-2 ml-3" src="assets/img/simple-arrow-right-dark.svg" alt="">
                </button>
            </div>
        </div>

        <div id="latestArtworksContainer"
            class="flex justify-between items-start flex-wrap lg:justify-center sm:flex-col sm:items-center resizedXl:py-16">
            <div *ngFor="let artwork of latestArtworks; let i = index" class="artwork-container reveal sm:w-full"
                [ngClass]="{'hidden': windowWidth <= 1786 && i === 3}">
                <a *ngIf="artwork"
                    [routerLink]=" artwork.type === 'ancient' ? ['/artworks/' + artwork.customId] : ['/paintings/' + artwork.customId]"
                    class="flex flex-col items-center w-88 text-gray-darkest lg:mb-10 sm:mb-6 sm:w-full">
                    <div class="w-full  overflow-hidden cursor-pointer latest-img-container flex justify-center">
                        <img class="w-full h-full overflow-hidden latest-img"
                            [ngClass]="{'scale-img': artwork.type === 'modern'}"
                            [src]="getCarouselMainImg(artwork.customId, 'imageCarousel')" alt="">
                    </div>
                    <hr class="border-t w-12 border-gray-medium mt-5 mb-6">

                    <div class="font-trajan text-center mb-1"><span *ngIf="artwork.name"
                            class="uppercase line-clamp-3 text-base">{{artwork.name[currentLanguage]}}</span></div>
                    <div *ngIf="artwork.type === 'ancient'" class="text-center "><span
                            *ngIf="getFirstEntityName(artwork.customId, 'culture')">{{getFirstEntityName(artwork.customId,
                            'culture')[currentLanguage]}}
                            <span
                                *ngIf="getFirstEntityName(artwork.customId, 'culture') && getFirstEntityName(artwork.customId, 'culture')[currentLanguage] && getFirstEntityName(artwork.customId, 'period') && getFirstEntityName(artwork.customId, 'period')[currentLanguage]">
                                · </span> <span *ngIf="getFirstEntityName(artwork.customId, 'period')">
                                {{getFirstEntityName(artwork.customId, 'period')[currentLanguage]}}</span> </span>
                    </div>
                    <div *ngIf="artwork.type === 'modern'" class="text-center "><span
                            *ngIf="getArtistFullName(artwork.customId)">{{getArtistFullName(artwork.customId)[currentLanguage]}}
                        </span>
                    </div>
                </a>
            </div>
            <div class="smmin:hidden flex justify-center pb-4 pt-8 reveal">
                <button class="flex items-center justify-center border border-gray-medium px-6 py-3"
                    (click)="navigationService.navigateTo('artworks')">
                    <span class="text-gray-darkest text-sm">{{ generalTexts[currentLanguage].discover }}</span>
                    <img class="h-3 w-2 ml-3" src="assets/img/simple-arrow-right-dark.svg" alt="">
                </button>
            </div>
        </div>
    </div>


</div>



<!------------------------------- YOUNG COLLECTORS -------------------------------------->



<div class="w-full text-sm bg-gray young-section lg:h-auto resizedSm:h-auto resizedSm:py-16 center-content">
    <div class="flex items-center justify-center py-32 px-10% md:flex-col md:py-16 young-container max-size">

        <div class="mr-16  md:w-auto md:mr-0 md:mb-12 w-1/2 reveal">
            <h3 class="text-gray-darkest uppercase text-3xl mb-8 text-left sm:text-3xl md:text-center">{{
                generalTexts[currentLanguage].young }}
            </h3>
            <p class="text-gray-grayText whitespace-pre-line text-justify" [innerHTML]="youngCollectorsText.content">
            </p>
            <div class="mt-16  md:hidden">
                <button class="flex items-center justify-center border border-gray-medium px-6 py-3"
                    (click)="navigationService.navigateTo('young-collectors')">
                    <span class="text-gray-darkest text-sm ">{{ generalTexts[currentLanguage].discover }}</span>
                    <img class="h-3 w-2 ml-3" src="assets/img/simple-arrow-right-dark.svg" alt="">
                </button>
            </div>
        </div>



        <div class="flex flex-col md:w-auto items-center w-1/2 reveal">
            <div class="flex mb-4 xs:mb-2">
                <div class="w-32  sm:w-20 sm:h-20 xs:w-12 xs:h-12"></div>
                <div class="ml-4 flex sm:ml-3 xs:ml-2" *ngFor="let img of youngCollectorsImgPaths | slice:0:3">
                    <img class="w-32  object-cover sm:w-20 sm:h-20 xs:w-12 xs:h-12" [src]="img" alt="">
                </div>
            </div>
            <div class="mb-4 flex xs:mb-2">
                <div class="mr-4 flex sm:mr-3 xs:mr-2" *ngFor="let img of youngCollectorsImgPaths | slice:3:6">
                    <img class="w-32  object-cover sm:w-20 sm:h-20 xs:w-12 xs:h-12" [src]="img" alt="">
                </div>
                <div class="w-32  sm:w-20 sm:h-20 xs:w-12 xs:h-12"></div>
            </div>
            <div class="flex">
                <div class="w-32  mr-4 sm:mr-3 xs:mr-2 sm:w-20 sm:h-20 xs:w-12 xs:h-12"></div>
                <div class="mr-4 flex sm:mr-3 xs:mr-2" *ngFor="let img of youngCollectorsImgPaths | slice:6:8">
                    <img class="w-32  object-cover sm:w-20 sm:h-20 xs:w-12 xs:h-12" [src]="img" alt="">
                </div>
                <div class="w-32  sm:w-20 sm:h-20 xs:w-12 xs:h-12"></div>

            </div>

        </div>


        <div class="mdmin:hidden my-14 reveal">
            <button class="flex items-center justify-center border border-gray-medium px-6 py-3"
                (click)="navigationService.navigateTo('young-collectors')">
                <span class="text-gray-darkest text-sm">{{ generalTexts[currentLanguage].discover }}</span>
                <img class="h-3 w-2 ml-3" src="assets/img/simple-arrow-right-dark.svg" alt="">
            </button>
        </div>

    </div>
</div>

<!------------------------------- FERIAS & EXPOSICIONES -------------------------------------->


<div class="w-full bg-white text-sm md:h-auto resizedSm:h-auto resizedSm:pb-16 max-h-224 ">
    <div class="py-32 sm:pb-16 md:h-auto sm:pt-16 3xlmin:flex 3xlmin:flex-col align-center-items reveal">

        <div
            class="flex justify-between mb-9 px-10% w-full max-size md:text-center md:mb-8 md:flex-col md:justify-center">
            <div class="uppercase text-gray-darkest">
                <h3 class="text-3xl mb-1">{{ generalTexts[currentLanguage].exhibitions_title }}</h3>
                <span class="text-base">{{ generalTexts[currentLanguage].artworks }}</span>
            </div>
            <div class="md:hidden">
                <button class="flex items-center justify-center border border-gray-medium px-6 py-3"
                    (click)="navigationService.navigateTo('exhibitions')">
                    <span class="text-gray-darkest text-sm ">{{ generalTexts[currentLanguage].discover }}</span>
                    <img class="h-3 w-2 ml-3" src="assets/img/simple-arrow-right-dark.svg" alt="">
                </button>
            </div>
        </div>

        <div class="ml-10% mt-9 resizedXl:mt-16 expo-container">
            <app-exhibitions-swiper></app-exhibitions-swiper>
        </div>

        <div class="mdmin:hidden flex justify-center">
            <button class="flex items-center justify-center border border-gray-medium py-3 px-6 mt-8"
                (click)="navigationService.navigateTo('exhibitions')">
                <span class="text-gray-darkest">{{ generalTexts[currentLanguage].discover }}</span>
                <img class="h-3 w-2 ml-3" src="assets/img/simple-arrow-right-dark.svg" alt="">
            </button>
        </div>


    </div>

</div>



<!-- PUBLICATIONS -->

<!-- <div class="h-auto w-full bg-gray flex items-center text-sm sm:block sm:pt-16 xlmin:h-full 3xlmin:flex 3xlmin:justify-center resizedSm:h-auto"> -->

<div class="w-full bg-gray flex items-center text-sm sm:block sm:pt-16 3xlmin:flex 3xlmin:justify-center">
    <!-- <div class="h-full w-full bg-gray flex items-center sm:block sm:pt-16 sm:h-auto lg:h-auto" > -->

    <!-- MOBILE -->

    <div class="smmin:hidden flex flex-col items-center reveal">
        <div class="uppercase text-gray-darkest mb-8 text-center">
            <h3 class="text-3xl mb-1">{{ generalTexts[currentLanguage].publications_title }}</h3>
            <span class="text-base">{{ generalTexts[currentLanguage].catalogues }}</span>
        </div>
        <app-publications-mobile-swiper class="w-full" id="publication-swiper"></app-publications-mobile-swiper>
        <div class="">
            <button class="flex items-center justify-center border border-gray-medium px-6 py-3 mt-16 mb-14"
                (click)="navigationService.navigateTo('publications')">
                <span class="text-gray-darkest text-sm">{{ generalTexts[currentLanguage].discover }}</span>
                <img class="h-3 w-2 ml-3" src="assets/img/simple-arrow-right-dark.svg" alt="">
            </button>
        </div>
    </div>
    <!-- MOBILE -->

    <!-- <div class="mx-10% lg:mx-12 py-28 w-full block sm:hidden 3xlmin:w-350"> -->
    <div class="px-10% lg:mx-12 py-28 w-full block sm:hidden  max-size reveal">
        <div class="flex justify-between mb-9">
            <div class="uppercase text-gray-darkest">
                <h3 class="text-3xl mb-1">{{ generalTexts[currentLanguage].publications_title }}</h3>
                <span class="text-base">{{ generalTexts[currentLanguage].catalogues }}</span>
            </div>
            <div class="">
                <button class="flex items-center justify-center border border-gray-medium py-3 px-6"
                    (click)="navigationService.navigateTo('publications')">
                    <span class="text-gray-darkest text-sm">{{ generalTexts[currentLanguage].discover }}</span>
                    <img class="h-3 w-2 ml-3" src="assets/img/simple-arrow-right-dark.svg" alt="">
                </button>
            </div>
        </div>

        <div class="flex w-full justify-center flex-wrap  ">
            <!-- <div class="flex justify-center flex-wrap sm:hidden "> -->
            <div *ngFor="let publication of publications"
                class="flex flex-col items-center  text-gray-darkest mx-8 w-72 lgmin:remove-margin lg:mb-12 sm:mb-0 reveal">
                <img class="" [src]="publication.imgPath" alt="">
                <!-- <hr class="border-t w-12 border-gray-medium mt-5 mb-6">

                <div class="text-xs font-bold mb-4"><span>{{publication.name}}</span></div>
                <div class="flex justify-center w-72 font-trajan"><span
                        class="uppercase text-base text-center line-clamp-2 mb-4">{{publication.subtitle}}</span></div>
                <div class="text-xs"><span>{{publication.pages}}</span></div> -->

                <!-- <div class="flex w-72 justify-between sm:w-full">
                    <button (click)="navigateExternal(publication.link)"
                        class="flex items-center justify-center border border-gray-medium text-xs px-4 py-2 mt-8 h-10 sm:w-44 sm:h-11 xs:w-40">
                        <span class="text-gray-darkest ">{{ generalTexts[currentLanguage].see_publication }}</span>
                        <img class="h-3 w-2 ml-2" src="assets/img/simple-arrow-right-dark.svg" alt="">
                    </button>
                    <button (click)="navigateExternal('mailto:' + publication.contact)"
                        class="flex items-center justify-center bg-gray-dark text-xs text-white px-4 py-2 mt-8 h-10 sm:w-44 sm:h-11 xs:w-40">
                        <span>{{ generalTexts[currentLanguage].ask }} </span><span class="font-bold pl-2">
                            {{publication.price}}</span>
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</div>