import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConnectionService } from 'src/app/services/connection.service';
import { DataService } from 'src/app/services/data.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InterfaceService } from 'src/app/services/interface.service';
import { MediaService } from 'src/app/services/media.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';

@Component({
  selector: 'app-painting-details',
  templateUrl: './painting-details.component.html',
  styleUrls: ['./painting-details.component.scss'],
})
export class PaintingDetailsComponent implements OnInit {
  formData: { name: string; email: string; phone: string; text: string };

  currentArtwork;
  startRange = 0;
  endRange = 6;
  artworkId;
  lightboxArr = [];
  tagList = [];

  firstLoad = false;
  formDetails = {};

  get currentImage() {
    return this.mediaService.currentImage;
  }

  get artworkEntitiesCache() {
    return this.dataService.artworkEntitiesCache;
  }

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get organizationData() {
    return this.dataService.organizationData;
  }

  constructor(
    public interfaceService: InterfaceService,
    public connectionService: ConnectionService,
    private dataService: DataService,
    private domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private smoothScroll: SmoothScrollService,
    private filters: FiltersService,
    private navigationService: NavigationService,
    public mediaService: MediaService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.interfaceService.reveal();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    if (this.filters.filteredArtworks.length) {
      this.navigationService.backBtnClicked = true;
      this.filters.savedFilteredArtworks = this.filters.filteredArtworks;
      this.filters.storedKeyword = this.filters.searchValue;
    }
  }

  ngOnInit(): void {
    this.interfaceService.footerBackgroundChange = false;
    this.interfaceService.displayDark = true;

    this.artworkId = this.activatedRoute.snapshot.params['id'];

    this.connectionService.getArtworkByCustomId(this.artworkId).subscribe(
      (res) => {
        // console.log('RES', res);
        if (
          res.organization.id !== this.organizationData.id ||
          res.visibility === 'hidden'
        ) {
          return;
        } else {
          this.currentArtwork = res;
          this.dataService.setArtworkData(this.currentArtwork);
          // console.log('CURRENT ARTWORK', this.currentArtwork);
          if (this.currentArtwork.entities) {
            this.tagList = this.currentArtwork.entities.filter((e) =>
              this.filters.displayFilter(e)
            );
          }
        }
      },
      (error) => {
        console.log('ERROR', error);
      },
      () => {
        window.addEventListener('scroll', this.interfaceService.reveal);
        this.interfaceService.reveal();
      }
    );
  }

  ngAfterViewChecked(): void {
    if (!this.firstLoad && this.currentArtwork) {
      this.interfaceService.reveal();
      this.formDetails['type'] = 'artwork';
      this.formDetails['ref'] = this.currentArtwork.client_reference;
      if (
        this.getMediaList('imageCarousel') &&
        this.getMediaList('imageCarousel').length
      ) {
        this.formDetails['img'] = this.getMediaList('imageCarousel')[0].path;
      }
      this.firstLoad = true;
    }
  }

  sanitizeImage(resource) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(resource);
  }

  scrollIntoView(id: string) {
    this.smoothScroll.smoothVerticalScrolling(id);
  }

  prevImg(currentImage) {
    if (currentImage > 0) {
      if (currentImage <= this.startRange) {
        this.startRange = this.startRange - 1;
        this.endRange = this.endRange - 1;
      }
      this.mediaService.currentImage = currentImage - 1;
    } else {
      console.log('PASSED IMAGE');
    }
  }

  nextImg(currentImage) {
    if (currentImage < this.getMediaList('imageCarousel').length - 1) {
      if (currentImage >= this.endRange) {
        this.startRange = this.startRange + 1;
        this.endRange = this.endRange + 1;
      }
      this.mediaService.currentImage = currentImage + 1;
    } else {
      console.log('PASSED IMAGE');
    }
  }

  openLightbox(current) {
    this.mediaService.lightboxArr = this.getMediaList('imageCarousel');

    this.mediaService.currentImage = current;
    this.interfaceService.isLightboxOpen = true;
  }

  getMediaList(mediaType) {
    return this.dataService.getMediaList(this.artworkId, mediaType);
  }

  getFirstEntityName(property) {
    if (this.dataService.getEntityList(this.artworkId, property)) {
      return this.dataService.getEntityList(this.artworkId, property)[0].name;
    } else {
      return null;
    }
  }

  getArtistSurname() {
    if (this.dataService.getEntityList(this.artworkId, 'artist')[0].surname) {
      return this.dataService.getEntityList(this.artworkId, 'artist')[0]
        .surname;
    } else {
      return null;
    }
  }

  getArtist() {
    if (
      this.dataService.getEntityList(this.artworkId, 'artist') &&
      this.dataService.getEntityList(this.artworkId, 'artist')[0]
    ) {
      return this.dataService.getEntityList(this.artworkId, 'artist')[0];
    } else {
      return null;
    }
  }

  getEntityList(artwork, entityType) {
    if (this.dataService.getEntityList(artwork, entityType)) {
      const entList = this.dataService.getEntityList(artwork, entityType);
      if (entList.filter((e) => e.type).length) {
        return entList.filter((e) => e.type);
      } else {
        return entList.filter((e) => !e.type);
      }
    }
  }
}
