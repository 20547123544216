import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from '../../services/data.service';
import { InterfaceService } from '../../services/interface.service';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
})
export class LightboxComponent implements OnInit {
  @Input() lightboxArr;
  @Input() currentImage;
  @Output() resetCurrentImg = new EventEmitter();

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  constructor(
    public interfaceService: InterfaceService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {}

  closeLightBox() {
    this.interfaceService.isLightboxOpen = false;
    if (this.currentImage > this.lightboxArr.length - 1) {
      this.resetCurrentImg.emit(0);
    } else {
      this.resetCurrentImg.emit(this.currentImage);
    }
  }
}
