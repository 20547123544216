{
    "slides": [
        {
            "name": {
                "es": "FERIARTE 2024",
                "en": "FERIARTE 2024"
            },
            "mobileName": null,
            "imgPath": "assets/img/home-swiper-images/home-feriarte2024.jpg",
            "mobileGradientImgPath": "assets/img/home-swiper-images/home-feriarte2024-mobile-gradient.jpg",
            "subtitleText": {
                "en": "NOVEMBER 23 - DECEMBER 1 <br> Ifema, Madrid fair",
                "es": "23 Noviembre - 1 Diciembre <br> Ifema, Feria de Madrid"
            },
            "navigateTo": "exhibitions/0",
            "type": "exhibition",
            "text": "left",
            "id": "0"
        },
        {
            "name": {},
            "mobileName": null,
            "imgPath": "assets/img/home-swiper-images/home-femme-picasso.jpg",
            "mobileGradientImgPath": "assets/img/home-swiper-images/home-femme-picasso-mobile-gradient.jpg",
            "subtitleText": null,
            "navigateTo": "artworks/T_5089",
            "type": "artwork",
            "text": "left",
            "id": "T_5089"
        },
        {
            "name": {},
            "mobileName": null,
            "imgPath": "assets/img/home-swiper-images/home-votivo-penamon.jpg",
            "mobileGradientImgPath": "assets/img/home-swiper-images/home-votivo-penamon-mobile-gradient.jpg",
            "subtitleText": null,
            "navigateTo": "artworks/T_5057",
            "type": "artwork",
            "text": "left",
            "id": "T_5057"
        },
        {
            "name": {},
            "mobileName": null,
            "imgPath": "assets/img/home-swiper-images/home-miro-sin-titulo.jpg",
            "mobileGradientImgPath": "assets/img/home-swiper-images/home-miro-sin-titulo-mobile-gradient.jpg",
            "subtitleText": null,
            "navigateTo": "paintings/T_4796",
            "type": "artwork",
            "text": "left",
            "id": "T_4796"
        },
        {
            "name": {},
            "mobileName": null,
            "imgPath": "assets/img/home-swiper-images/home-estela.jpg",
            "mobileGradientImgPath": "assets/img/home-swiper-images/home-estela-mobile-gradient.jpg",
            "subtitleText": null,
            "navigateTo": "artworks/T_4690",
            "type": "artwork",
            "text": "right",
            "id": "T_4690"
        },
        {
            "name": {},
            "mobileName": null,
            "imgPath": "assets/img/home-swiper-images/home-baco.jpg",
            "mobileGradientImgPath": "assets/img/home-swiper-images/home-baco-mobile-gradient.jpg",
            "subtitleText": null,
            "navigateTo": "artworks/T_4633",
            "type": "artwork",
            "text": "left",
            "id": "T_4633"
        },
        {
            "name": {},
            "mobileName": null,
            "imgPath": "assets/img/home-swiper-images/home-torso-doriforo.jpg",
            "mobileGradientImgPath": "assets/img/home-swiper-images/home-torso-doriforo-mobile-gradient.jpg",
            "subtitleText": null,
            "navigateTo": "artworks/T_4443",
            "type": "artwork",
            "text": "right",
            "id": "T_4443"
        },
        {
            "name": {},
            "mobileName": null,
            "imgPath": "assets/img/home-swiper-images/home-signum.jpg",
            "mobileGradientImgPath": "assets/img/home-swiper-images/home-signum-mobile-gradient.jpg",
            "subtitleText": null,
            "navigateTo": "artworks/T_4440",
            "type": "artwork",
            "text": "left",
            "id": "T_4440"
        }
    ]
}