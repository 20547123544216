<div class="flex flex-wrap relative btn-group-container">
    <div *ngIf="currentArtwork['sale_status'] && (currentArtwork['sale_status'].toLowerCase() === 'available' || currentArtwork['sale_status'].toLowerCase() === 'potential_buyer')"
        class="flex items-center justify-center border border-gray-outline px-4 h-12 mr-4 xssm:mt-5 sm:px-3 sm:w-44 lg:mt-0 xl:mt-4 xl:w-48">
        <span
            class="whitespace-nowrap text-gray-grayText text-sm capitalize sm:text-xs">{{generalTexts[currentLanguage].price}}:
            <span
                *ngIf="(currentArtwork['sale_status'].toLowerCase() === 'available' || currentArtwork['sale_status'].toLowerCase() === 'potential_buyer') && currentArtwork['price']['sale_price']['value']"
                class="whitespace-nowrap font-semibold text-black">{{
                currentArtwork['price']['sale_price']['value'] }} {{
                currencySymbol[currentArtwork['price']['sale_price']['currency']]}}</span>
            <span
                *ngIf="currentArtwork['sale_status'].toLowerCase() === 'available' && !currentArtwork['price']['sale_price']['value']"
                class="whitespace-nowrap font-semibold text-black"> {{
                generalTexts[currentLanguage].to_consult }}</span>
            <span *ngIf="currentArtwork['sale_status'].toLowerCase() === 'reserved'"
                class="whitespace-nowrap font-semibold text-black"> {{
                generalTexts[currentLanguage].reserved }}</span>
        </span>
    </div>
    <div class="relative form-btn-container">

        <button
            *ngIf="currentArtwork['sale_status'].toLowerCase() !== 'sold' && currentArtwork['sale_status'].toLowerCase() !== 'reserved'"
            (click)="interfaceService.displayForm = true"
            class="flex items-center justify-center bg-gray-outline px-8 h-12 xs:mr-0 xssm:mt-5 sm:px-3 sm:w-44 lg:mt-0 xl:mr-0 xl:mt-4 xl:w-48">
            <span class="text-white font-semibold text-sm uppercase sm:text-xs ">{{
                generalTexts[currentLanguage].consult }}</span>
        </button>

        <button
            *ngIf="currentArtwork['sale_status'].toLowerCase() === 'sold' || currentArtwork['sale_status'].toLowerCase() === 'reserved'"
            class="flex items-center justify-center bg-gray-outline px-8 h-12 xs:mr-0 xssm:mt-5 sm:px-3 sm:w-44 lg:mt-0 xl:mr-0 xl:mt-4 xl:w-48">
            <span *ngIf="currentArtwork['sale_status'].toLowerCase() === 'sold'"
                class="text-white font-semibold text-sm uppercase sm:text-xs ">{{
                generalTexts[currentLanguage].sold }}</span>
            <span *ngIf="currentArtwork['sale_status'].toLowerCase() === 'reserved'"
                class="text-white font-semibold text-sm uppercase sm:text-xs ">{{
                generalTexts[currentLanguage].reserved }}</span>
        </button>

    </div>


</div>