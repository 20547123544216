const APP_VERSION = require('../../package.json').version;

export const environment = {
    apiUrl: 'https://l78qcrizp9.execute-api.us-east-1.amazonaws.com',
    graphql_apiUrl: 'https://api.museumdiscovery.org',
    // apiUrl: 'https://bd83svoq72.execute-api.us-east-1.amazonaws.com',
    assetsUrl: 'https://public-assets.museumdiscovery.org',
    exhibitionUrl: 'https://exhibitions.museumdiscovery.org/eues20009exhibition3d',
    production: false,
    client_email: 'mark@mobilemediacontent.com',
    VERSION: APP_VERSION
};