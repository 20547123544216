import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MenuComponent } from './layout/menu/menu.component';
import { AboutComponent } from './pages/about/about.component';
import { ArtworkDetailsComponent } from './pages/artworks/artwork-details/artwork-details.component';
import { ArtworksComponent } from './pages/artworks/artworks.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ExhibitionsComponent } from './pages/exhibitions/exhibitions.component';
import { NotableSalesComponent } from './pages/notable-sales/notable-sales.component';
import { PublicationsComponent } from './pages/publications/publications.component';
import { YoungCollectorsComponent } from './pages/young-collectors/young-collectors.component';
import { ExhibitionDetailsComponent } from './pages/exhibitions/exhibition-details/exhibition-details.component';
import { AppraisersComponent } from './pages/gallery-info-terms/appraisers/appraisers.component';
import { AuthenticityComponent } from './pages/gallery-info-terms/authenticity/authenticity.component';
import { PrivacyCookiesLayoutComponent } from './pages/gallery-info-terms/privacy-cookies-layout/privacy-cookies-layout.component';
import { PublicationsMobileSwiperComponent } from './swipers/publications-mobile-swiper/publications-mobile-swiper.component';
import { ArtworkDetailsMobileSwiperComponent } from './swipers/artwork-details-mobile-swiper/artwork-details-mobile-swiper.component';
import { ContentSidebarComponent } from './layout/content-sidebar/content-sidebar.component';
import { FormsModule } from '@angular/forms';
import { LightboxComponent } from './shared/lightbox/lightbox.component';
import { LightboxSwiperComponent } from './swipers/lightbox-swiper/lightbox-swiper.component';
import { DataService } from './services/data.service';
import { HomeSwiperComponent } from './swipers/home-swiper/home-swiper.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { ConsultFormComponent } from './shared/consult-form/consult-form.component';
import { WorkWithUsComponent } from './work-with-us/work-with-us.component';
import { GraphQLModule } from './graphql.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ExhibitionsSwiperComponent } from './swipers/exhibitions-swiper/exhibitions-swiper.component';
import { MainInterfaceComponent } from './main-interface/main-interface.component';
import { PaintingsComponent } from './pages/paintings/paintings.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaintingDetailsComponent } from './pages/paintings/painting-details/painting-details.component';
import { ArtistComponent } from './pages/paintings/artist/artist.component';
import { SaleBtnGroupComponent } from './shared/sale-btn-group/sale-btn-group.component';
import { PaintingMobileSwiperComponent } from './swipers/painting-mobile-swiper/painting-mobile-swiper.component';
import { ImageListComponent } from './shared/image-list/image-list.component';
import { VideoPlayerComponent } from './video-player/video-player.component';




@NgModule({ declarations: [
        AppComponent,
        MenuComponent,
        FooterComponent,
        HomeComponent,
        ArtworksComponent,
        ArtworkDetailsComponent,
        NotableSalesComponent,
        ExhibitionsComponent,
        YoungCollectorsComponent,
        PublicationsComponent,
        AboutComponent,
        ContactComponent,
        ExhibitionDetailsComponent,
        AppraisersComponent,
        AuthenticityComponent,
        PrivacyCookiesLayoutComponent,
        PublicationsMobileSwiperComponent,
        ArtworkDetailsMobileSwiperComponent,
        ContentSidebarComponent,
        LightboxComponent,
        LightboxSwiperComponent,
        HomeSwiperComponent,
        ComingSoonComponent,
        ConsultFormComponent,
        WorkWithUsComponent,
        ExhibitionsSwiperComponent,
        MainInterfaceComponent,
        PaintingsComponent,
        PaintingDetailsComponent,
        ArtistComponent,
        SaleBtnGroupComponent,
        PaintingMobileSwiperComponent,
        ImageListComponent,
        VideoPlayerComponent
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        GraphQLModule,
        BrowserAnimationsModule,
        NgxMasonryModule], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    ] })
export class AppModule { }
