<div class="mdmin:hidden fixed top-0 right-0 bg-white w-4/5 h-full z-20"
    [ngClass]="{'hidden-sidebar ': !interfaceService.startSidebarDisplay, 'no-opacity': !interfaceService.isSidebarOpacityActivated, 'display-opacity': interfaceService.isSidebarOpacityActivated && interfaceService.isSidebarOpen}">

    <div class="flex justify-between px-6 py-5 border-b ">
        <div class="flex items-center mr-9" (click)="interfaceService.toggleSidebar()">
            <span *ngIf="pathName !== '/exhibitions'" class="text-sm font-bold mr-2 whitespace-nowrap">{{ generalTexts[currentLanguage].filter }}</span>
            <span *ngIf="pathName === '/exhibitions'" class="text-sm font-bold mr-2 whitespace-nowrap">{{ generalTexts[currentLanguage].order_by }}</span>
            <img src="assets/img/filter.svg" alt="">
        </div>
        <div class="flex items-center cursor-pointer" (click)="interfaceService.toggleSidebar()">
            <img class="invert w-5 h-5" src="assets/img/icon-close.svg" alt="">
        </div>
    </div>

    <ng-content ></ng-content>

</div>


<div (click)="interfaceService.toggleSidebar()" class="mdmin:hidden fixed w-full h-full top-0 z-10" [ngClass]="{ 'hidden-sidebar ': !interfaceService.startSidebarDisplay,
    'no-blur-bg': !interfaceService.isSidebarOpacityActivated, 
    ' display-blur-bg': interfaceService.isSidebarOpacityActivated && interfaceService.isSidebarOpen}">

</div>