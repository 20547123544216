import { ViewportScroller } from '@angular/common';
import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from 'src/app/services/connection.service';
import { DataService } from 'src/app/services/data.service';
import { InterfaceService } from 'src/app/services/interface.service';
import { MediaService } from 'src/app/services/media.service';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';

@Component({
  selector: 'app-exhibition-details',
  templateUrl: './exhibition-details.component.html',
  styleUrls: ['./exhibition-details.component.scss'],
})
export class ExhibitionDetailsComponent implements OnInit, AfterViewChecked {
  get exhibition() {
    return this.dataService.exhibitions[this.currentLanguage][
      this.dataService.exhibitionId
    ];
  }

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get artworksCache() {
    return this.dataService.artworksCache;
  }

  get artworkEntitiesCache() {
    return this.dataService.artworkEntitiesCache;
  }

  exhibitionArtworks = [];
  headerImg;
  firstLoad = false;
  currentImage;
  isCustom = false;

  constructor(
    public interfaceService: InterfaceService,
    private dataService: DataService,
    private router: Router,
    private smoothScroll: SmoothScrollService,
    private connectionService: ConnectionService,
    private viewportScroller: ViewportScroller,
    private mediaService: MediaService
  ) {}

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);

    if (!this.dataService.exhibitionId) {
      this.dataService.exhibitionId = Number(
        window.location.pathname.slice(13)
      );
    }

    this.headerImg = this.exhibition.imgPath;

    this.interfaceService.displayDark = false;
    this.setPageTheme();
    this.getExpoData(this.exhibition.artworks);

    if (this.exhibition.custom) {
      this.isCustom = true;
    }
    if (this.exhibition) {
      this.interfaceService.revealTimeout();

    }
    window.addEventListener('scroll', this.interfaceService.reveal);
  }

  ngAfterViewChecked(): void {
    if (!this.firstLoad && this.exhibition) {
      this.interfaceService.revealTimeout();

      this.firstLoad = true;
    }
  }

  navigateToArtWorkDetails(id: string) {
    console.log('ID', id);

    if (!this.isCustom) {
      this.router.navigate(['artworks', id]);
    }
  }

  scrollIntoView(id: string) {
    this.smoothScroll.smoothVerticalScrolling(id);
  }

  setPageTheme() {
    if (this.exhibition.artworks.length) {
      this.interfaceService.footerBackgroundChange = true;
    } else if (
      !this.exhibition.artworks.length &&
      !this.exhibition.exhibitionImgPaths.length
    ) {
      this.interfaceService.footerBackgroundChange = true;
    } else {
      this.interfaceService.footerBackgroundChange = false;
    }
  }

  async getExpoData(artworks) {
    for (const artwork of artworks) {
      if (this.artworksCache[artwork]) {
        this.exhibitionArtworks.push(this.artworksCache[artwork]);
      } else {
        try {
          const res = await this.connectionService
            .getAssetPreview(artwork)
            .toPromise();
          const artworkData = res;

          this.dataService.setArtworkData(artworkData);
          this.exhibitionArtworks.push(artworkData);
        } catch (error) {
          console.error('Error fetching data', error);
        }
      }
    }
  }

  getCarouselMainImg(artworkId, mediaType) {
    return this.dataService.getMainMedia(artworkId, mediaType);
  }

  getFirstEntityName(artwork, property) {
    if (this.dataService.getEntityList(artwork, property)) {
      return this.dataService.getEntityList(artwork, property)[0].name;
    } else {
      return null;
    }
  }

  openVideoPlayer() {
    this.interfaceService.isVideoPlayerOpen = true;
    console.log('VIDEI', this.exhibition.videoPath);
    
    this.mediaService.videoPathSubject.next(this.exhibition.videoPath);
  }
}
