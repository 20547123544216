<div class="fixed bg-white top-0 right-0 consult-form-container z-40 w-full h-full" *ngIf="interfaceService.displayForm"
    [ngClass]="{'flex items-end': interfaceService.displaySuccess}">
    <div class="absolute w-full flex justify-between items-start px-6 z-10 top-section">
        <img class="w-48 invert" src="assets/img/bagot-logo-full.svg" alt="">
        <div *ngIf="interfaceService.displayForm && !interfaceService.displaySuccess" class="flex cursor-pointer"
            (click)="closeForm()">
            <img class="invert " src="assets/img/icon-close.svg" alt="">
        </div>
    </div>

    <form *ngIf="!interfaceService.displaySuccess" action="" #form="ngForm" (ngSubmit)="handleSubmit(form, formDetails)"
        class="flex flex-col items-center justify-center h-full">
        <div class="flex flex-col items-center pt-9 px-6 mb-12 text-base">

            <div><span>{{ generalTexts[currentLanguage].complete_consult }}:</span></div>
            <div class="text-center px-4"><span class="font-semibold ">{{name}}</span></div>
        </div>
        <input type="text" class="mb-8 pl-4 h-11 focus:border-gray-dark focus:ring-0"
            [placeholder]="generalTexts[currentLanguage].name" [ngModel]="formData ? formData.name : null" name="name">
        <input type="email" class="w-full mb-8 pl-4 h-11 focus:border-gray-dark focus:ring-0"
            [placeholder]="generalTexts[currentLanguage].email" [ngModel]="formData ? formData.email : null"
            name="email">
        <input type="subject" class="w-full mb-8 pl-4 h-11 focus:border-gray-dark focus:ring-0"
            [placeholder]="generalTexts[currentLanguage].phone" [ngModel]="formData ? formData.phone : null"
            name="phone">
        <textarea name="" id="" cols="30" rows="5" class="w-full pl-4 mb-12 focus:border-gray-dark focus:ring-0"
            [placeholder]="generalTexts[currentLanguage].text" [ngModel]="formData ? formData.text : null"
            name="text"></textarea>
        <div class="submit-btn-container">

            <button type="submit" class="h-12 px-8 bg-gray-outline text-white uppercase font-semibold ">{{
                generalTexts[currentLanguage].send }}</button>
        </div>

    </form>
    <div *ngIf="interfaceService.displaySuccess" class="h-full w-full flex">
        <div class="flex flex-col items-center justify-center h-full w-full">
            <div class="success-container">

                <div class="flex justify-center mb-3">
                    <img src="assets/img/checkmark-outline.png" alt="">
                </div>
                <div class="flex flex-col">
                    <div class="text-center"><span class="font-semibold">{{
                            generalTexts[currentLanguage].consult_success }}</span></div>
                    <div class="text-center response-text"><span>{{ generalTexts[currentLanguage].response }}</span>
                    </div>
                </div>
            </div>
            <div class="mb-12 close-btn-container">

                <button (click)="closeForm()" class="h-12 px-8 bg-gray-outline text-white uppercase font-semibold ">{{
                    generalTexts[currentLanguage].close }}</button>
            </div>
        </div>
    </div>
</div>