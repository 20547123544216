import {
  Component,
  ViewEncapsulation,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import SwiperCore, { Navigation, Swiper, SwiperOptions } from 'swiper';

SwiperCore.use([Navigation]);

@Component({
  selector: 'app-lightbox-swiper',
  templateUrl: './lightbox-swiper.component.html',
  styleUrls: ['./lightbox-swiper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LightboxSwiperComponent implements OnInit, AfterViewInit {
  @Input() lightboxArr;
  @Input() currentImage;

  @ViewChild('lightboxSwiperRef', { static: true })
  protected _lightboxSwiperRef: ElementRef | undefined;

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  swiper?: Swiper;
  onLastSlide;

  constructor(
    private dataService: DataService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.initSwiper();
    this.cdr.detectChanges();
  }

  private initSwiper() {
    const config: SwiperOptions = {
      slidesPerView: 1,
      zoom: {
        maxRatio: 3,
        minRatio: 1,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    const swiperEl = this._lightboxSwiperRef.nativeElement;

    const prevBtnLightbox = document.querySelector('#prevBtnLightbox');
    const nextBtnightbox = document.querySelector('#nextBtnLightbox');

    Object.assign(swiperEl, config);

    swiperEl.initialize();
    this.swiper = this._lightboxSwiperRef.nativeElement.swiper;

    prevBtnLightbox.addEventListener('click', () => {
      swiperEl.swiper.slidePrev();
      this.onLastSlide = false;
    });
    nextBtnightbox.addEventListener('click', () => {
      swiperEl.swiper.slideNext();
      this.checkActiveSlide();
    });
  }

  toggleZoom() {
    this.swiper.zoom.toggle();
  }

  isZoomedIn() {
    if (this.swiper && this.swiper.zoom.scale === 1) {
      return false;
    } else {
      return true;
    }
  }

  checkActiveSlide() {
    if (this.swiper.activeIndex === this.lightboxArr.length - 1) {
      this.onLastSlide = true;
    }
  }
}
