// import { Component, OnInit } from '@angular/core';
import {
  Component,
  ViewEncapsulation,
  ViewChild,
  OnInit,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import SwiperCore, { Navigation, Swiper, SwiperOptions } from 'swiper';

SwiperCore.use([Navigation]);

@Component({
  selector: 'app-publications-mobile-swiper',
  templateUrl: './publications-mobile-swiper.component.html',
  styleUrls: ['./publications-mobile-swiper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PublicationsMobileSwiperComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('publicationSwiperRef', { static: true })
  protected _publicationSwiperRef: ElementRef | undefined;

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get publications() {
    return this.dataService.publications;
  }

  swiper?: Swiper;
  onLastSlide;

  constructor(private dataService: DataService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initSwiper();
  }

  private initSwiper() {
    const config: SwiperOptions = {
      slidesPerView: 1,
      breakpoints: {
        900: {
          slidesPerView: 2.5,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    const swiperEl = this._publicationSwiperRef.nativeElement;
    const prevBtn = document.querySelector('#prevBtnPublication');
    const nextBtn = document.querySelector('#nextBtnPublication');

    Object.assign(swiperEl, config);

    swiperEl.initialize();
    this.swiper = this._publicationSwiperRef.nativeElement.swiper;

    prevBtn.addEventListener('click', () => {
      swiperEl.swiper.slidePrev();
      this.onLastSlide = false;
    });
    nextBtn.addEventListener('click', () => {
      swiperEl.swiper.slideNext();
      this.checkActiveSlide();

      console.log('ACTIVE', this.swiper.activeIndex);
    });
  }

  navigateExternal(url) {
    window.open(url, '_blank');
  }

  checkActiveSlide() {
    if (this.swiper.activeIndex === this.publications.length - 1) {
      this.onLastSlide = true;
    }
  }
}
