<div class="text-gray-darkest mb-12 ">
    <div class="pt-36 sm:pt-28">
        <div class="flex justify-center mb-20 sm:mb-8 reveal">
            <h3 class="uppercase text-2xl text-center">{{ generalTexts[currentLanguage].exhibitions_title }}</h3>
        </div>

        <div class="smmin:hidden px-6 pb-3 border-b sm:border-gray-dropdown reveal">
            <div class="flex items-center mr-9 cursor-pointer" (click)="interfaceService.toggleSidebar()">
                <span class="text-sm font-bold mr-2 whitespace-nowrap">{{ generalTexts[currentLanguage].order_by
                    }}</span>
                <img src="assets/img/filter.svg" alt="">
            </div>
        </div>

        <div class="w-full flex flex-col px-8 pb-8 border-b sm:hidden 3xlmin:items-center reveal">
            <div class="flex justify-center flex-wrap 3xlmin:w-350">
                <div>
                    <button class="border border-gray-medium w-60 h-12 uppercase text-sm mx-4 mb-4"
                        (click)="viewUpcomingEvents()">{{generalTexts[currentLanguage].upcoming}}</button>
                </div>
                <!-- <div>
                    <button class="border border-gray-medium w-52 h-12 uppercase text-sm mx-4 mb-4">Ferias</button>
                </div>
                <div>
                    <button class="border border-gray-medium w-52 h-12 uppercase text-sm mx-4 mb-4">Exposiciones</button>
                </div> -->
                <div>
                    <button class="border border-gray-medium w-60 h-12 uppercase text-sm mx-4 mb-4"
                        (click)="viewAll()">{{generalTexts[currentLanguage].see_all}}</button>
                </div>

            </div>
        </div>
        <div class="flex justify-center px-8 pt-4 flex-wrap sm:hidden reveal">
            <span *ngFor="let year of yearsArr" class="text-xs mx-3 mb-2 cursor-pointer"
                (click)="filterByYear(year)">{{year}}</span>
        </div>
    </div>

    <div class="3xlmin:flex 3xlmin:justify-center 3xlmin:w-full">


        <div *ngIf="!filteredExhibitions.length && !navigationService.isFiltered"
            class="flex flex-wrap justify-center mt-16 px-36 sm:px-6 sm:flex-col sm:flex-nowrap sm:mt-6 lg:px-16 3xlmin:w-350 3xlmin:px-0">
            <div *ngFor="let expo of exhibitions[currentLanguage]; let i = index" [id]="i"
                class=" text-gray-darkest w-1/2 px-10 mb-14 sm:w-full sm:px-0 sm:mb-8 md:w-full reveal">

                <a *ngIf="expo" (click)="storeScrollTarget(i, i)" class="flex flex-col items-start">

                    <img class="" [src]="expo.imgPath" alt="" class="cursor-pointer w-full">

                    <hr class="border-t w-12 border-gray-medium my-5">

                    <div class="text-sm"><span>{{expo.date}}</span></div>
                    <div class="font-trajan"><span
                            class="uppercase text-gray-darkest text-xl whitespace-pre-line">{{expo.name}}</span>
                    </div>
                    <div class="text-sm"><span>{{expo.location}}</span></div>
                </a>
            </div>
        </div>

        <div *ngIf="filteredExhibitions.length && navigationService.isFiltered"
            class="flex flex-wrap justify-center mt-16 px-36 sm:px-6 sm:flex-col sm:flex-nowrap sm:mt-6 lg:px-16 3xlmin:w-350 3xlmin:px-0">
            <div *ngFor="let index of filteredExhibitions; let i = index" [id]="i"
                class=" text-gray-darkest w-1/2 px-10 mb-14 sm:w-full sm:px-0 sm:mb-8 md:w-full reveal">

                <a *ngIf="exhibitions[currentLanguage][index]"
                    (click)="storeScrollTarget(getIndexId(exhibitions[currentLanguage][index], exhibitions[currentLanguage][index].name), i)"
                    class="flex flex-col items-start">

                    <img class="" [src]="exhibitions[currentLanguage][index].imgPath" alt=""
                        class="cursor-pointer w-full">

                    <hr class="border-t w-12 border-gray-medium my-5">

                    <div class="text-sm"><span>{{exhibitions[currentLanguage][index].date}}</span></div>
                    <div class="font-trajan"><span
                            class="uppercase text-gray-darkest text-xl whitespace-pre-line">{{exhibitions[currentLanguage][index].name}}</span>
                    </div>
                    <div class="text-sm"><span>{{exhibitions[currentLanguage][index].location}}</span></div>
                </a>
            </div>
        </div>

    </div>

</div>

<!-- SIDEBAR -->
<app-content-sidebar>
    <div class="flex flex-col border-b w-full px-5 py-4 text-sm">
        <div class="">
            <button class="border border-gray-medium w-full h-12 uppercase mb-4"
                (click)="viewUpcomingEvents()">{{generalTexts[currentLanguage].upcoming}}</button>
        </div>
        <!-- <div class="">
            <button class="border border-gray-medium w-full h-12 uppercase mb-4">Ferias</button>
        </div>
        <div class="">
            <button class="border border-gray-medium w-full h-12 uppercase mb-4">Exposiciones</button>
        </div> -->
        <div class="">
            <button class="border border-gray-medium w-full h-12 uppercase  mb-4"
                (click)="viewAll()">{{generalTexts[currentLanguage].see_all}}</button>
        </div>
    </div>
    <div class="flex flex-col items-center px-5 py-6 text-sm">
        <div class="mb-6"><span
                class="font-bold whitespace-nowrap">{{generalTexts[currentLanguage].order_by_year}}</span></div>
        <div class="flex justify-center flex-wrap">
            <span *ngFor="let year of yearsArr" class="text-xs mx-3 mb-6 w-8 cursor-pointer"
                (click)="filterByYear(year)">{{year}}</span>
        </div>
    </div>
</app-content-sidebar>