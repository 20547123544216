import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { DataService } from "../services/data.service";
import { AuthService } from "../services/auth.service";
import { of } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class InitResolver {

    constructor(private dataService: DataService, private authService: AuthService){}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(!this.authService.isLogged){
            this.authService.autoLogin().subscribe({
                next: (response) => {
                    if(response){
                        this.authService.isLogged = true;
                        localStorage.setItem('user', JSON.stringify(response.user));
                        return this.dataService.init();
                    } else{
                        return null
                    }
                },
                error: (error) => {
                    return null
                }
            })
        }
    }
}