import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  GetAssetByCustomIdGQL,
  GetAssetContextsByIdGQL,
  GetAssetPreviewByCustomIdGQL,
  GetAssetPreviewDataGQL,
  GetCollectionByCustomIdGQL,
  GetEntityGQL,
  GetOrganizationGQL,
} from './graphql.services';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  mailchimpAPIUrl = environment.apiUrl;

  resourcesUrl = environment.assetsUrl;

  constructor(
    private http: HttpClient,
    private getAssetByCustomId: GetAssetByCustomIdGQL,
    private getEntity: GetEntityGQL,
    private getOrganization: GetOrganizationGQL,
    private getAssetContextsById: GetAssetContextsByIdGQL,
    private getAssetPreviewData: GetAssetPreviewDataGQL,
    private getAssetPreviewByCustomId: GetAssetPreviewByCustomIdGQL,
    private getCollectionByCustomId: GetCollectionByCustomIdGQL
  ) {}

  getGeneralTexts() {
    return this.http.get(`${this.resourcesUrl}/general/texts_web.json`);
  }

  addSubscriber(data: Object): Observable<Object> {
    return this.http.post(this.mailchimpAPIUrl + '/mailchimp/signup', { data });
  }

  removeWhitespace(str) {
    return str.replace(/\s/g, '');
  }

  //GRAPHQL

  getArtworkByCustomId(artworkId) {
    return this._getArtworkByCustomId(artworkId);
  }

  fetchOrganization(id) {
    return this._getOrganization(id);
  }

  getAssetContexts(id, property) {
    return this._getAssetContextsById(id, property);
  }

  getAssetPreviews(id, property, paginationKey, queryLimit) {
    return this._getAssetPreviewById(id, property, paginationKey, queryLimit);
  }

  getAssetPreview(customId) {
    return this._getAssetPreviewByCustomId(customId);
  }

  fetchCollectionByCustomId(customId) {
    return this._getCollectionByCustomId(customId);
  }

  fetchEntity(id) {
    return this._getEntity(id);
  }

  // PRIVATE ----------------------------------------------------

  private _getArtworkByCustomId(artworkId: string) {
    return this.getAssetByCustomId.fetch({ customId: artworkId }).pipe(
      map((res) => {
        const artworkData = res.data.assetByCustomId;
        return artworkData;
      })
    );
  }

  private _getOrganization(organizationId) {
    return this.getOrganization
      .fetch({ id: organizationId }, { fetchPolicy: 'no-cache' })
      .pipe(
        map((res) => {
          const organizationData = res.data.organization;
          return organizationData;
        })
      );
  }

  private _getAssetContextsById(id, property) {
    return this.getAssetContextsById
      .fetch({ id: id, property: property }, { fetchPolicy: 'no-cache' })
      .pipe(map((res) => res.data.assetContextsById.list));
  }

  private _getAssetPreviewById(id, property, paginationKey, queryLimit) {
    return this.getAssetPreviewData
      .fetch(
        {
          id: id,
          property: property,
          paginationKey: paginationKey,
          queryLimit: queryLimit,
        },
        { fetchPolicy: 'no-cache' }
      )
      .pipe(map((res) => res.data.assetContextsById));
  }

  private _getAssetPreviewByCustomId(customId) {
    return this.getAssetPreviewByCustomId
      .fetch({ customId: customId }, { fetchPolicy: 'no-cache' })
      .pipe(map((res) => res.data.assetByCustomId));
  }

  private _getCollectionByCustomId(customId) {
    return this.getCollectionByCustomId
      .fetch({ customId })
      .pipe(map((res) => res.data.collectionByCustomId));
  }

  private _getEntity(id) {
    return this.getEntity.fetch({ id }).pipe(map((res) => res.data.entity));
  }
}
