import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { InterfaceService } from 'src/app/services/interface.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  dataReadySub: Subscription;

  exhibitionUrl = environment.exhibitionUrl;

  currentPath = window.location.pathname.slice(1);
  availableLanguages;

  navigation = [
    {
      title: this.generalTexts[this.currentLanguage].ancient_art,
      path: 'artworks',
    },
    {
      title: this.generalTexts[this.currentLanguage].modern_art,
      path: 'paintings',
    },
    {
      title: this.generalTexts[this.currentLanguage].artworks_museums,
      path: 'artworks-in-museums',
    },
    {
      title: this.generalTexts[this.currentLanguage].young,
      path: 'young-collectors',
    },
    {
      title: this.generalTexts[this.currentLanguage].exhibitions,
      path: 'exhibitions',
    },
    {
      title: this.generalTexts[this.currentLanguage].publications,
      path: 'publications',
    },
    {
      title: this.generalTexts[this.currentLanguage].about,
      path: 'about',
    },
    {
      title: this.generalTexts[this.currentLanguage].contact,
      path: 'contact',
    },
  ];

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.currentPath = window.location.pathname.slice(1);
  }

  constructor(
    public interfaceService: InterfaceService,
    public navigationService: NavigationService,
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    this.availableLanguages = this.dataService.availableLanguages;
    this.currentPath = window.location.pathname.slice(1);
  }

  navigate(path) {
    this.currentPath = path;
    this.navigationService.navigateTo(path);
  }

  toggleMenu() {
    if (!this.interfaceService.startDisplay) {
      this.interfaceService.startDisplay = true;
      this.interfaceService.isOpacityActivated = true;
      this.interfaceService.isMenuOpen = true;
    } else {
      this.interfaceService.isMenuOpen = false;
      this.interfaceService.isOpacityActivated = false;
      this.closeMenu();
    }
  }

  ngOnDestroy(): void {
    if (this.dataReadySub) this.dataReadySub.unsubscribe();
  }

  closeMenu() {
    let that = this;

    setTimeout(function () {
      that.interfaceService.startDisplay = false;
    }, 500);
  }

  close() {
    this.interfaceService.startDisplay = false;
    this.interfaceService.isOpacityActivated = false;
    this.interfaceService.isMenuOpen = false;
  }

  navigateTo(path) {
    this.toggleMenu();
    this.currentPath = path;

    this.navigationService.navigateTo(path);
  }

  navigateExternal(url) {
    window.open(url, '_blank');
  }

  onLanguageChanged(value) {
    this.dataService.currentLanguage = value.target.value;

    this.navigation[0].title = this.generalTexts[this.currentLanguage].ancient_art;
    this.navigation[1].title = this.generalTexts[this.currentLanguage].modern_art;
    this.navigation[2].title =
      this.generalTexts[this.currentLanguage].artworks_museums;
    this.navigation[3].title = this.generalTexts[this.currentLanguage].young;
    this.navigation[4].title =
      this.generalTexts[this.currentLanguage].exhibitions;
    this.navigation[5].title =
      this.generalTexts[this.currentLanguage].publications;
    this.navigation[6].title = this.generalTexts[this.currentLanguage].about;
    this.navigation[7].title = this.generalTexts[this.currentLanguage].contact;

    this.interfaceService.languageChanged = true;
  }
}
