import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { InterfaceService } from 'src/app/services/interface.service';
import { DataService } from '../services/data.service';
import { NavigationService } from '../services/navigation.service';
import { SmoothScrollService } from '../services/smooth-scroll.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewChecked {
  get latestArtworks() {
    return this.dataService.homeArtworks;
  }

  get publications() {
    return this.dataService.publications;
  }

  get youngCollectorsImgPaths() {
    return this.dataService.mockYoungImgPaths;
  }

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get youngCollectorsText() {
    return this.dataService.data[this.currentLanguage].young_collectors;
  }

  exhibitionUrl = environment.exhibitionUrl;

  windowWidth;

  constructor(
    public interfaceService: InterfaceService,
    public dataService: DataService,
    public navigationService: NavigationService,
    private smoothScroll: SmoothScrollService,
    private changeDetector: ChangeDetectorRef
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkArtworksWidth();
    this.interfaceService.reveal();
  }

  ngOnInit(): void {
    this.interfaceService.displayDark = false;
    this.interfaceService.footerBackgroundChange = false;
    this.smoothScroll.scrollTarget = null;

    window.addEventListener('scroll', this.interfaceService.reveal);
    this.interfaceService.reveal();
    this.checkArtworksWidth();
  }

  ngAfterViewChecked() {}

  scrollIntoView(id: string) {
    this.smoothScroll.smoothVerticalScrolling(id);
  }

  navigateExternal(url) {
    window.open(url, '_blank');
  }

  checkArtworksWidth() {
    const containerHeight = document.getElementById('scrollTarget');
    const artworksHeight = document.getElementById(
      'latestArtworksContainer'
    ).offsetHeight;

    this.windowWidth = window.innerWidth;

    if (this.windowWidth <= 1700 && artworksHeight > 600) {
      containerHeight.style.maxHeight = 'none';
    }
    this.changeDetector.detectChanges();
  }

  getFirstEntityName(artwork, property) {
    if (this.dataService.getEntityList(artwork, property)) {
      return this.dataService.getEntityList(artwork, property)[0].name;
    } else {
      return null;
    }
  }

  getArtistFullName(artwork) {
    return this.dataService.getArtistFullName(artwork, 'artist');
  }

  getCarouselMainImg(artworkId, mediaType) {
    return this.dataService.getMainMedia(artworkId, mediaType);
  }

}
