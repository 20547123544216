import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { InterfaceService } from 'src/app/services/interface.service';
import { MediaService } from 'src/app/services/media.service';

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss'],
})
export class ImageListComponent implements OnInit, OnDestroy {
  @Input() mediaList;
  @Input() type;
  lightboxArr;

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  constructor(
    private dataService: DataService,
    private interfaceService: InterfaceService,
    private mediaService: MediaService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.mediaService.currentImage = 0;
    this.mediaService.lightboxArr = null;
  }

  openLightbox(current) {
    this.mediaService.lightboxArr = this.mediaList;
    this.mediaService.currentImage = current;
    this.interfaceService.isLightboxOpen = true;
  }
}
