import {
  ChangeDetectorRef,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { ConnectionService } from './connection.service';
import { DataService } from './data.service';
import { InterfaceService } from './interface.service';
import { SmoothScrollService } from './smooth-scroll.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  private renderer: Renderer2;

  artworkFilters = {
    culture: {},
    typology: {},
    material: {},
    artist: {},
  };

  filteredArtworks = [];
  filteredCopy = [];
  savedFilteredArtworks = [];
  storedFilters = [];

  displayFiltered = false;
  filterTriggered = false;

  searchValue;

  storedKeyword;

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get culturesArr() {
    return Object.keys(this.artworkFilters.culture);
  }

  get materialsArr() {
    return Object.keys(this.artworkFilters.material);
  }

  get typologiesArr() {
    return Object.keys(this.artworkFilters.typology);
  }

  get artistsArr() {
    return Object.keys(this.artworkFilters.artist);
  }

  get artworks() {
    return this.dataService.exhibitionArtworks;
  }

  get youngCollectorsArtworks() {
    if (
      this.dataService.youngCollectorsArtworks &&
      this.dataService.youngCollectorsArtworks.length
    ) {
      return this.dataService.youngCollectorsArtworks;
    } else {
      return [];
    }
  }

  get paintings() {
    if (
      this.dataService.paintingArtworks &&
      this.dataService.paintingArtworks.length
    ) {
      return this.dataService.paintingArtworks;
    } else {
      return [];
    }
  }

  constructor(
    private dataService: DataService,
    private smoothScroll: SmoothScrollService,
    private interfaceService: InterfaceService,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  applyFilters(filters) {
    this.filteredArtworks = [];
    let cultureTempArr = [];
    let materialTempArr = [];
    let typologyTempArr = [];
    let artistTempArr = [];
    let multiple = false;

    filters.forEach((filter) => {
      let category;

      if (this.culturesArr.includes(filter)) {
        category = 'culture';
        cultureTempArr = cultureTempArr.concat(
          this.artworkFilters[category][filter]
        );
      } else if (this.materialsArr.includes(filter)) {
        category = 'material';
        materialTempArr = materialTempArr.concat(
          this.artworkFilters[category][filter]
        );
      } else if (this.typologiesArr.includes(filter)) {
        category = 'typology';
        typologyTempArr = typologyTempArr.concat(
          this.artworkFilters[category][filter]
        );
      } else if (this.artistsArr.includes(filter)) {
        category = 'artist';
        artistTempArr = artistTempArr.concat(
          this.artworkFilters[category][filter]
        );
      }
    });

    //CULTURE
    if (cultureTempArr.length) {
      if (!multiple) {
        this.filteredArtworks = [
          ...new Set(this.filteredArtworks.concat(cultureTempArr)),
        ];
      } else {
        if (materialTempArr.length === 0 && typologyTempArr.length === 0) {
          this.filteredArtworks = [...new Set(cultureTempArr)];
        } else {
          this.filteredArtworks = [
            ...new Set(
              cultureTempArr.filter((artwork) => {
                return this.filteredArtworks.includes(artwork);
              })
            ),
          ];
        }
      }
      multiple = true;
    }

    //MATERIAL
    if (materialTempArr.length) {
      if (!multiple) {
        this.filteredArtworks = [
          ...new Set(this.filteredArtworks.concat(materialTempArr)),
        ];
      } else {
        if (cultureTempArr.length === 0 && typologyTempArr.length === 0) {
          this.filteredArtworks = [...new Set(materialTempArr)];
        } else {
          this.filteredArtworks = [
            ...new Set(
              materialTempArr.filter((artwork) => {
                return this.filteredArtworks.includes(artwork);
              })
            ),
          ];
        }
      }
      multiple = true;
    }

    //TOPOLOGIA
    if (typologyTempArr.length) {
      if (!multiple) {
        this.filteredArtworks = [
          ...new Set(this.filteredArtworks.concat(typologyTempArr)),
        ];
      } else {
        if (cultureTempArr.length === 0 && materialTempArr.length === 0) {
          this.filteredArtworks = [...new Set(typologyTempArr)];
        } else {
          this.filteredArtworks = [
            ...new Set(
              typologyTempArr.filter((artwork) => {
                return this.filteredArtworks.includes(artwork);
              })
            ),
          ];
        }
      }
      multiple = true;
    }

    //ARTIST TO CHANGE????
    if (artistTempArr.length) {
      if (!multiple) {
        this.filteredArtworks = [
          ...new Set(this.filteredArtworks.concat(artistTempArr)),
        ];
      } else {
        if (
          cultureTempArr.length === 0 &&
          materialTempArr.length === 0 &&
          typologyTempArr.length === 0
        ) {
          this.filteredArtworks = [...new Set(artistTempArr)];
        } else {
          this.filteredArtworks = [
            ...new Set(
              artistTempArr.filter((artwork) => {
                return this.filteredArtworks.includes(artwork);
              })
            ),
          ];
        }
      }
      multiple = true;
    }

    this.filteredCopy = this.filteredArtworks;
    this.searchValue = '';
    this.filterTriggered = true;
  }

  search(e, collection?) {
    const keyWord = this.searchValue
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    this.displayFiltered = true;
    this.filteredArtworks = [];

    if (this.filteredCopy.length > 0) {
      this.searchByKey(this.filteredCopy, keyWord);
    } else {
      if (collection === 'youngCollectors') {
        this.searchByKey(this.youngCollectorsArtworks, keyWord);
      } else if (collection === 'paintings') {
        this.searchByKey(this.paintings, keyWord, true);
      } else {
        this.searchByKey(this.artworks, keyWord);
      }
    }
    this.filterTriggered = true;
  }

  searchByKey(artworkArr, keyWord, artist?) {
    // const normalizedKeyword = keyWord
    //   .normalize('NFD')
    //   .replace(/[\u0300-\u036f]/g, '')
    //   .toLowerCase();

    artworkArr.forEach((artwork) => {
      const artworkName = artwork.name[this.currentLanguage];
      let fullName = '';

      if (
        artist &&
        this.dataService.artworkEntitiesCache[artwork.customId] &&
        this.dataService.artworkEntitiesCache[artwork.customId]['artist'] &&
        this.dataService.artworkEntitiesCache[artwork.customId]['artist'][0]
      ) {
        fullName = `${
          this.dataService.artworkEntitiesCache[artwork.customId]['artist'][0]
            .name[this.currentLanguage]
        } ${
          this.dataService.artworkEntitiesCache[artwork.customId]['artist'][0]
            .surname[this.currentLanguage]
        }`;
      }

      const normalizedArtworkName = artworkName
        ? artworkName
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
        : '';
      const normalizedFullName = fullName
        ? fullName
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
        : '';
      const normalizedReference = artwork.client_reference
        ? artwork.client_reference
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
        : '';

      if (
        normalizedArtworkName.includes(keyWord) ||
        normalizedFullName.includes(keyWord) ||
        normalizedReference.includes(keyWord)
      ) {
        this.filteredArtworks.push(artwork);
      }
    });

    this.interfaceService.revealTimeout();
  }

  // searchByKey(artworkArr, keyWord, artist?) {
  //   artworkArr.forEach((artwork) => {
  //     const artworkName = artwork.name[this.currentLanguage];
  //     const clientReference = artwork.client_reference
  //     ? artwork.reference.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
  //     : '';
  //     let fullName = '';

  //     if (
  //       artist &&
  //       this.dataService.artworkEntitiesCache[artwork.customId] &&
  //       this.dataService.artworkEntitiesCache[artwork.customId]['artist'] &&
  //       this.dataService.artworkEntitiesCache[artwork.customId]['artist'][0]
  //     ) {
  //       fullName = `${
  //         this.dataService.artworkEntitiesCache[artwork.customId]['artist'][0]
  //           .name[this.currentLanguage]
  //       } ${
  //         this.dataService.artworkEntitiesCache[artwork.customId]['artist'][0]
  //           .surname[this.currentLanguage]
  //       }`;
  //     }

  //     if (
  //       (artworkName &&
  //         artworkName
  //           .normalize('NFD')
  //           .replace(/[\u0300-\u036f]/g, '')
  //           .toLowerCase()
  //           .includes(keyWord)) ||
  //       (fullName &&
  //         fullName
  //           .normalize('NFD')
  //           .replace(/[\u0300-\u036f]/g, '')
  //           .toLowerCase()
  //           .includes(keyWord))
  //     ) {
  //       this.filteredArtworks.push(artwork);
  //     }
  //   });
  //   this.interfaceService.revealTimeout();
  // }

  buildFilters(entity) {
    const entityType = entity.entityType.toLowerCase();
    const entityId = entity.customId;
    if (this.artworkFilters[entityType] == null) {
      this.artworkFilters[entityType] = [];
    }

    if (this.artworkFilters[entityType][entityId] == null) {
      this.artworkFilters[entityType][entityId] = [];
    }
  }

  addArtworkToFilters(artwork, entity) {
    const entityType = entity.entityType.toLowerCase();
    const entityId = entity.customId;
    if (this.artworkFilters[entityType][entityId]) {
      this.artworkFilters[entityType][entityId].push(artwork);
    }
  }

  displayFilter(entity) {
    if (
      entity.entityType === 'Culture' ||
      entity.entityType === 'Material' ||
      entity.entityType === 'Typology'
    ) {
      return true;
    } else {
      return false;
    }
  }

  toggleCheck(item: string) {
    const checkboxSidebar = document.querySelector(
      `#sidebar-${item}`
    ) as HTMLInputElement;
    const checkbox = document.querySelector(`#${item}`) as HTMLInputElement;

    if (checkbox && checkboxSidebar) {
      checkbox.checked = !checkbox.checked;
      checkboxSidebar.checked = !checkboxSidebar.checked;
      return checkbox.checked;
    }
    return false;
  }

  triggerCheckboxFilters(item: string, e?: MouseEvent) {
    const isSidebar =
      e && e.target && (e.target as HTMLElement).id.includes('sidebar');

    if (isSidebar) {
      const checkbox = document.querySelector(`#${item}`) as HTMLInputElement;
      if (checkbox) {
        checkbox.checked = !checkbox.checked;
        this.triggerCheck(item, e);
        this.interfaceService.revealTimeout();
      }
    } else {
      const checkboxSidebar = document.querySelector(
        `#sidebar-${item}`
      ) as HTMLInputElement;
      if (checkboxSidebar) {
        checkboxSidebar.checked = !checkboxSidebar.checked;
        this.triggerCheck(item, e);
        this.interfaceService.revealTimeout();
      }
    }
  }

  triggerCheck(item, e?, outerClick?) {
    this.smoothScroll.scrollTarget = null;

    const isChecked = (<HTMLInputElement>e.target).checked;

    this.displayFiltered = true;
    if (isChecked) {
      this.storedFilters.push(item);
    } else {
      this.storedFilters.splice(this.storedFilters.indexOf(item), 1);
      if (!this.storedFilters.length) {
        this.displayFiltered = false;
      }
    }
    this.applyFilters(this.storedFilters);
  }

  displayFilteredResults(
    toggleCheckFunction: (item: any) => boolean,
    collection?
  ) {
    if (this.savedFilteredArtworks.length && this.filteredArtworks.length) {
      this.displayFiltered = true;
      setTimeout(() => {
        this.smoothScroll.scrollToId(this.smoothScroll.scrollTarget);
        if (this.storedFilters.length) {
          this.storedFilters = [...new Set(this.storedFilters)];

          this.storedFilters.forEach((filter) => {
            toggleCheckFunction(filter);
          });
          this.applyFilters(this.storedFilters);
          if (this.storedKeyword) {
            this.searchValue = this.storedKeyword;
            collection === 'youngCollectors'
              ? this.search(null, 'youngCollectors')
              : this.search(null);
          }
        }
      }, 0);

      this.interfaceService.resetArtworks = false;
      this.savedFilteredArtworks = [];
    }
  }

  resetArtworkFilters() {
    this.artworkFilters = {
      culture: {},
      typology: {},
      material: {},
      artist: {},
    };
  }

  resetAll() {
    this.resetArtworkFilters();
    this.displayFiltered = false;
    this.filteredArtworks = [];
    this.searchValue = '';
  }
}
