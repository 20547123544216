import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { InterfaceService } from 'src/app/services/interface.service';

@Component({
  selector: 'app-content-sidebar',
  templateUrl: './content-sidebar.component.html',
  styleUrls: ['./content-sidebar.component.scss'],
})
export class ContentSidebarComponent implements OnInit {
  pathName = window.location.pathname;

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  constructor(
    public interfaceService: InterfaceService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {}
}
