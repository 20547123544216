<div class="pt-36 pb-20 mx-10% flex justify-center text-sm sm:mx-8">
    <div class=" flex flex-col w-3/5 sm:w-full " *ngIf="currentData[currentLanguage][currentPage]">
        <h3 class="text-center font-trajan text-2xl mb-9 sm:text-xl reveal">
            {{currentData[currentLanguage][currentPage].title}}
        </h3>
        <p *ngIf="currentData[currentLanguage][currentPage].subtitle" class="mb-8 text-gray-grayText text-center reveal"
            [innerHTML]="currentData[currentLanguage][currentPage].subtitle">
        </p>
        <div *ngFor="let item of currentData[currentLanguage][currentPage].content" class="mb-6 reveal">

            <div class="sm:px-0">
                <p class=" mb-2 font-bold uppercase" id="test" [innerHtml]="item.question"></p>
                <p class="text-gray-grayText text-justify w-full" [innerHtml]="item.answer"></p>
            </div>
        </div>
    </div>
</div>