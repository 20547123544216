<div *ngIf="currentData[currentLanguage].appraisers"
    class="pt-36 pb-40 mx-10% text-sm lg:pt-24 lg:pb-20 flex justify-center">

    <div class="flex items-center  lg:flex-col 3xlmin:w-350">
        <div class="w-2/5 py-6 mr-12 lg:w-full lg:mr-0 reveal">

            <div class="h-full w-full lg:h-auto lg:overflow-hidden lg:flex lg:justify-center ">
                <img class="h-auto w-full lg:h-auto lg:w-56 img-apppraisers"
                    src="assets/img/mock-images/appraisers-img.png" alt="">

            </div>

        </div>
        <div class="w-3/5 py-6 lg:w-full lg:py-10 flex flex-col reveal" *ngIf="currentData[currentLanguage].appraisers">
            <h3 class="uppercase text-2xl mb-8 lg:text-center">{{ generalTexts[currentLanguage].appraisers_ancient }}
            </h3>
            <p class="text-gray-grayText mb-8 text-justify"
                [innerHTML]="currentData[currentLanguage].appraisers.content[0].answer"></p>
            <div class="lg:flex lg:justify-center">
                <button class="flex items-center justify-center border border-gray-medium px-6 py-3"
                    (click)="navigationService.navigateTo('contact')">
                    <span class="text-gray-darkest text-xs">{{ generalTexts[currentLanguage].contact_us}}</span>
                    <img class="h-3 w-2 ml-3" src="assets/img/simple-arrow-right-dark.svg" alt="">
                </button>
            </div>
        </div>
    </div>

</div>