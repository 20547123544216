import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError } from 'rxjs/operators';
import { TokenExpirationService } from '../services/token-expiration.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private tokenExpirationService: TokenExpirationService, private auth: AuthService, private router: Router, private route: ActivatedRoute) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));
      return next.handle(this.tokenExpirationService.addAuthToken(request, user['accessToken'])).pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status == 403 && error.error.message == 'Token expired') {
              return this.tokenExpirationService.handle403Error(request, next);
            }
            else if (error.status == 403 && error.error.message == 'User token missing') {
              localStorage.removeItem('user');
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate(['./'], { relativeTo: this.route });
            }
            else {
              throw error;
            }
          } else {
            throw new Error(error);
          }
          return null;
        })
      );
    }
    return next.handle(request);
  }
}
