import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConnectionService } from 'src/app/services/connection.service';
import { DataService } from 'src/app/services/data.service';
import { InterfaceService } from 'src/app/services/interface.service';

@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.scss'],
})
export class ArtistComponent implements OnInit {
  @Input() artistId;

  artist;

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  constructor(
    public dataService: DataService,
    private interfaceService: InterfaceService,
    private connectionService: ConnectionService
  ) {}

  ngOnInit(): void {
    this.connectionService.fetchEntity(this.artistId).subscribe((res) => {
      this.artist = res;
      // console.log('RES', res);
    });
  }

  getArtistImg() {
    if (
      this.artist &&
      this.artist.media &&
      this.artist.media.length &&
      this.artist.media.find((m) => m.type === 'imageCarousel')
    ) {
      return this.artist.media.find((m) => m.type === 'imageCarousel').path;
    } else {
      return null;
    }
  }
}
