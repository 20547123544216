import {
  Component,
  ViewEncapsulation,
  ViewChild,
  OnInit,
  Input,
  ElementRef,
} from '@angular/core';
import { ConnectionService } from 'src/app/services/connection.service';
import { DataService } from 'src/app/services/data.service';
import SwiperCore, {
  Pagination,
  Navigation,
  Swiper,
  SwiperOptions,
} from 'swiper';
SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'app-artwork-details-mobile-swiper',
  templateUrl: './artwork-details-mobile-swiper.component.html',
  styleUrls: ['./artwork-details-mobile-swiper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArtworkDetailsMobileSwiperComponent {
  @ViewChild('paintingSwiperRef', { static: true })
  protected _paintingSwiperRef: ElementRef | undefined;

  @Input() imageCarousel;

  constructor(public connectionService: ConnectionService) {}

  ngOnInit(): void {}
}
