import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class MailingService {
  // get enquiriesEmail() {
  //   return this.dataService.organizationData.enquiries_email;
  // }

  get clientId() {
    return this.dataService.organizationData.id;
  }

  get logoPath() {
    return this.dataService.organizationData.logo_black;
  }

  private mailingServiceUrl = environment.apiUrl;

  logo = `${environment.assetsUrl}/clients/${this.clientId}${this.logoPath}`;

  constructor(private http: HttpClient, private dataService: DataService) {}

  sendMessage(message) {
    const body = {
      client: environment.client_email,
      message: message,
    };

    body.message.logo = this.logo;

    console.log('BODY', body);

    return this.http.post(`${this.mailingServiceUrl}/mailing/mail`, body, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  }
}
