import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-main-interface',
  templateUrl: './main-interface.component.html',
  styleUrls: ['./main-interface.component.scss']
})
export class MainInterfaceComponent implements OnInit {

  dataReadySub: Subscription

  dataInitialized: boolean

  constructor(private dataService: DataService) {
    this.dataInitialized = false;
  }

  ngOnInit(): void {
    this.dataReadySub = this.dataService.getDataSubject().subscribe({
      next: (response) => {
        if(response){
          this.dataInitialized = true;
        }
      }
    })
  }

}
