import { Injectable } from '@angular/core';
import { AssetContextPagination } from './graphql.services';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  assetContextsLastKey: AssetContextPagination = null;

  constructor() {}

  setPagination(data) {
    let paginationKey = data.paginationKey ? data.paginationKey : {};

    Object.keys(paginationKey).forEach((key) => {
      if (paginationKey[key] === null) {
        delete paginationKey[key];
      }
    });
    if (paginationKey.__typename) {
      delete paginationKey.__typename;
    }
    this.assetContextsLastKey = paginationKey;
  }

  resetAssetContextsPagination() {
    this.assetContextsLastKey = null;
  }
}
