<div *ngIf="interfaceService.isVideoPlayerOpen" class="fixed top-0 left-0 w-full h-full bg-white z-10">
    <div class="flex justify-between w-full px-6 pt-6 top-container">
        <div>
            <img class="w-48 invert" src="assets/img/bagot-logo-full.svg" alt="">
        </div>

        <div>
            <div class="flex cursor-pointer" (click)="closeVideoPlayer()">
                <span class="uppercase font-trajanBold text-xxs mr-2">{{ generalTexts[currentLanguage].close }}</span>
                <img class="invert w-3 h-3" src="assets/img/icon-close.svg" alt="">
            </div>

        </div>
    </div>

    <div class="video-container flex justify-center "
        [ngClass]=" !showOpacity ? 'opacity-0' : 'opacity-100 transition-03 '">

        <video controls>
            <source [src]="videoPath" type="video/mp4">
        </video>
    </div>
</div>