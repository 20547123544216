import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { InterfaceService } from 'src/app/services/interface.service';
import SwiperCore, { Navigation, Swiper, SwiperOptions } from 'swiper';
import { ConnectionService } from 'src/app/services/connection.service';

SwiperCore.use([Navigation]);

@Component({
  selector: 'app-home-swiper',
  templateUrl: './home-swiper.component.html',
  styleUrls: ['./home-swiper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeSwiperComponent implements OnInit, AfterViewInit {
  @ViewChild('swiperRef', { static: true })
  protected _swiperRef: ElementRef | undefined;

  swiper?: Swiper;

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get homeSlides() {
    return this.dataService.homeSlides['slides'];
  }

  get artworksCache() {
    return this.dataService.artworksCache;
  }

  get artworkEntitiesCache() {
    return this.dataService.artworkEntitiesCache;
  }

  constructor(
    private dataService: DataService,
    public navigationService: NavigationService,
    private interfaceService: InterfaceService,
    private connectionService: ConnectionService
  ) {}

  ngOnInit(): void {
    this.homeSlides.forEach((slide) => {
      if (slide.id && slide.type === 'artwork') {
        if (this.artworksCache[slide.id]) {
          slide.name = this.artworksCache[slide.id].name;
        } else {
          this.connectionService
            .getArtworkByCustomId(slide.id)
            .subscribe((res) => {
              this.dataService.setArtworkData(res);
              slide.name = res.name;
            });
        }
      }
    });

    this.interfaceService.reveal();
    window.addEventListener('scroll', this.interfaceService.reveal);
  }

  ngAfterViewInit(): void {
    this.initSwiper();
  }

  private initSwiper() {
    const config: SwiperOptions = {
      slidesPerView: 1,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    const swiperEl = this._swiperRef.nativeElement;

    const prevBtnHome = document.querySelector('#prevBtnHome');
    const nextBtnHome = document.querySelector('#nextBtnHome');

    Object.assign(swiperEl, config);

    swiperEl.initialize();
    this.swiper = this._swiperRef.nativeElement.swiper;

    prevBtnHome.addEventListener('click', () => {
      swiperEl.swiper.slidePrev();
    });
    nextBtnHome.addEventListener('click', () => {
      swiperEl.swiper.slideNext();
    });
  }

  getFirstEntityName(artworkId, property) {
    if (this.dataService.getEntityList(artworkId, property)) {
      return this.dataService.getEntityList(artworkId, property)[0].name;
    } else {
      return null;
    }
  }

  navigateTo(type, path) {
    if (type === 'exhibiton3d') {
      const url = `${path}?lang=${this.currentLanguage}`;
      window.open(url, '_blank');
    } else {
      this.navigationService.navigateTo(path);
    }
  }

  displayArtist(itemId) {
    if (
      (!this.getFirstEntityName(itemId, 'culture') ||
        !this.getFirstEntityName(itemId, 'culture')[this.currentLanguage]) &&
      this.getFirstEntityName(itemId, 'artist') &&
      this.getFirstEntityName(itemId, 'artist')[this.currentLanguage]
    ) {
      return this.dataService.getArtistFullName(itemId, 'artist')[
        this.currentLanguage
      ];
    }
  }
}
