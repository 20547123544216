<div class="w-full h-full coming-soon-container">
    <div class="h-full flex flex-col justify-center items-center">
        <div>
            <img class="invert w-52" src="assets/img/bagot-logo-full.svg" alt="">
        </div>
        <hr>
        <div>
            <span class=" font-trajan text-lg">PRÓXIMAMENTE / COMING SOON</span>
        </div>
    </div>
</div>
