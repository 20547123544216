import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { NavigationService } from 'src/app/services/navigation.service';
import SwiperCore, { Navigation, Swiper, SwiperOptions } from 'swiper';

SwiperCore.use([Navigation]);

@Component({
  selector: 'app-exhibitions-swiper',
  templateUrl: './exhibitions-swiper.component.html',
  styleUrls: ['./exhibitions-swiper.component.scss'],
})
export class ExhibitionsSwiperComponent implements OnInit, AfterViewInit {
  @Input() expoImages;
  @ViewChild('expoSwiperRef', { static: true })
  protected _expoSwiperRef: ElementRef | undefined;

  @ViewChild('slideImg') slideImg: ElementRef;
  @ViewChild('swiperBtnContainer') swiperBtnContainer: ElementRef;

  get exhibitions() {
    return this.dataService.exhibitions;
  }

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  expoSwiper?: Swiper;
  expoSlides;
  onHomePage;
  onLastSlide;

  constructor(
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    public navigationService: NavigationService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.adjustTopPosition();
  }

  ngOnInit(): void {
    if (this.route.snapshot.routeConfig?.path.includes('exhibitions')) {
      this.expoSlides = [];
      this.expoImages.forEach((img, index) => {
        this.expoSlides[index] = { imgPath: img };
      });
    } else {
      this.onHomePage = true;
      this.expoSlides = this.exhibitions[this.currentLanguage].slice(0, 4);
    }
  }

  ngAfterViewInit(): void {
    this.initSwiper();
  }

  private initSwiper() {
    const config: SwiperOptions = {
      slidesPerView: 1,
      breakpoints: {
        900: {
          slidesPerView: 2.5,
        },
      },
      navigation: {
        nextEl: '#swiper1 .swiper-button-next',
        prevEl: '#swiper1 .swiper-button-prev',
      },
    };

    const swiperEl = this._expoSwiperRef.nativeElement;
    const prevBtn = document.querySelector('#prevBtn');
    const nextBtn = document.querySelector('#nextBtn');

    Object.assign(swiperEl, config);
    swiperEl.initialize();
    this.expoSwiper = this._expoSwiperRef.nativeElement.swiper;

    prevBtn.addEventListener('click', () => {
      swiperEl.swiper.slidePrev();
      this.onLastSlide = false;
    });
    nextBtn.addEventListener('click', () => {
      swiperEl.swiper.slideNext();
      this.checkActiveSlide();
    });
  }

  //CHECK??
  navigateToExhibition(exhibitionId) {
    this.router.navigate(['exhibitions', exhibitionId]);
  }

  adjustTopPosition() {
    const imgHeight = this.slideImg.nativeElement.offsetHeight;

    const newTopValue = imgHeight / 2 - 20 + 'px';
    this.swiperBtnContainer.nativeElement.style.top = newTopValue;
  }

  checkActiveSlide() {
    if (this.expoSwiper.activeIndex === 2 && window.innerWidth > 900) {
      this.onLastSlide = true;
    } else if (this.expoSwiper.activeIndex === 3 && window.innerWidth <= 900) {
      this.onLastSlide = true;
    } else {
      this.onLastSlide = false;
    }
  }
}
