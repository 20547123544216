import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InterfaceService } from 'src/app/services/interface.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';
import { NgxMasonryOptions } from 'ngx-masonry';

@Component({
  selector: 'app-paintings',
  templateUrl: './paintings.component.html',
  styleUrls: ['./paintings.component.scss'],
})
export class PaintingsComponent implements OnInit {
  @ViewChild('imageContainer') imageContainer: ElementRef;

  public masonryOptions: NgxMasonryOptions = {
    gutter: 64,
    // itemSelector: '.painting',
    // columnWidth: '.painting',
    // columnWidth: '.test',
    resize: true,
    initLayout: true,
    // fitWidth: true,
  };

  firstLoad = false;
  filterTriggered = false;

  get filterEntities() {
    return this.dataService.paintingsData.web_entities;
  }

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get artistsArr() {
    const arr = Object.keys(this.artworkFilters.artist);
    return this.interfaceService.sortItems(arr, 'surname');
  }

  get artworkFilters() {
    return this.filters.artworkFilters;
  }

  get entityCache() {
    return this.dataService.entityCache;
  }

  get paintings() {
    if (
      this.dataService.paintingArtworks &&
      this.dataService.paintingArtworks.length
    ) {
      return this.dataService.paintingArtworks;
    } else {
      return [];
    }
  }

  constructor(
    public interfaceService: InterfaceService,
    public dataService: DataService,
    public filters: FiltersService,
    private router: Router,
    private smoothScroll: SmoothScrollService,
    private elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private navigationService: NavigationService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    // this.interfaceService.reveal();
  }

  ngOnInit(): void {
    this.interfaceService.displayDark = true;
    this.interfaceService.footerBackgroundChange = true;

    if (!this.navigationService.backBtnClicked) {
      this.filters.resetArtworkFilters();

      const paintingsCollId = 'eues20009paintings';
      if (!this.paintings || !this.paintings.length) {
        this.dataService
          .fetchAllArtworks(paintingsCollId, 'collection')
          .then((res) => {
            this.setFilters();
            this.firstLoad = false;

            // this.interfaceService.reveal();
            // console.log('FILTERS', this.filters.artworkFilters);
          });
      } else {
        this.setFilters();
        this.firstLoad = false;
        // this.interfaceService.reveal();
      }
    }

    this.interfaceService.reveal();
    window.addEventListener('scroll', this.interfaceService.reveal);
  }

  ngAfterViewChecked(): void {
    if (this.smoothScroll.scrollTarget && !this.firstLoad) {
      if (this.filters.savedFilteredArtworks.length) {
        this.filters.displayFilteredResults(
          (item) => this.toggleCheck(item),
          'paintings'
        );
        this.changeDetector.detectChanges();
        this.navigationService.backBtnClicked = false;
      } else {
        this.smoothScroll.scrollToId(this.smoothScroll.scrollTarget);
      }

      this.firstLoad = true;
    } else if (!this.firstLoad && this.paintings) {
      this.interfaceService.reveal();
      this.firstLoad = true;
    }
    if (this.filters.filterTriggered) {
      this.interfaceService.reveal();
      this.filters.filterTriggered = false;
    }
  }

  setFilters() {
    this.filterEntities.forEach((entity) => {
      this.filters.buildFilters(entity);
    });

    this.paintings.forEach((artwork) => {
      this.filters.storedFilters = [];

      artwork.entities.forEach((entity) => {
        if (entity && entity.customId && entity.entityType === 'Artist') {
          this.filters.addArtworkToFilters(artwork, entity);
        }
      });
    });
    this.firstLoad = false;

    this.interfaceService.revealTimeout();
  }

  triggerCheck(item, e?, outerClick?) {
    this.filters.triggerCheckboxFilters(item, e);
  }

  toggleCheck(item) {
    return this.filters.toggleCheck(item);
  }

  storeScrollTarget(id: string) {
    this.smoothScroll.scrollTarget = id;
  }

  getCarouselMainImg(artworkId, mediaType) {
    return this.dataService.getMainMedia(artworkId, mediaType);
  }

  getFirstEntityName(artwork, property) {
    if (this.dataService.getEntityList(artwork, property)) {
      if (property === 'artist') {
        return this.getArtistFullName(artwork, property);
      } else {
        return this.dataService.getEntityList(artwork, property)[0].name;
      }
    } else {
      return null;
    }
  }

  getArtistFullName(artwork, property) {
    if (this.dataService.getEntityList(artwork, property)[0].surname) {
      const fullName = {};

      const firstName = this.dataService.getEntityList(artwork, property)[0]
        .name;
      const surnName = this.dataService.getEntityList(artwork, property)[0]
        .surname;
      for (const key of Object.keys(firstName)) {
        fullName[key] = firstName[key] + ' ' + surnName[key];
      }
      return fullName;
    } else {
      return this.dataService.getEntityList(artwork, property)[0].name;
    }
  }
}
