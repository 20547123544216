<div class="pt-36 sm:pt-24 text-sm  flex flex-col items-center">

    <div *ngIf="currentArtwork" class="flex w-full h-full pl-10 lg:flex-col lg:px-10 md:px-0 max-width image-container">

        <div class="w-full pb-8 mdmin:hidden reveal">
            <app-artwork-details-mobile-swiper
                [imageCarousel]="getMediaList('imageCarousel')"></app-artwork-details-mobile-swiper>
        </div>

        <div
            class="flex flex-shrink-0 lg:items-center 3xl:artwork-images md:hidden lg:w-full lg:mb-12 3xlmin:w-1/2 reveal">

            <div class="h-180 flex flex-col justify-around items-center pr-8  md:h-auto">
                <div class="md:mb-6" (click)="prevImg(currentImage)">
                    <img class="rotate-neg-90" src="assets/img/arrow-carousel.svg" alt=""
                        [ngClass]="currentImage === 0 ? 'arrow-filter' : 'arrow cursor-pointer' ">
                </div>

                <div class="h-full flex flex-col justify-center items-center">
                    <div *ngFor="let img of getMediaList('imageCarousel'); let i = index"
                        [ngClass]="i >= startRange && i <= endRange ? 'block' : 'hidden'"
                        class="img-container my-2 overflow-hidden flex justify-center items-center">
                        <img (click)="mediaService.currentImage = i" [src]="img.view_image" alt=""
                            class="cursor-pointer w-18 h-18 object-cover "
                            [ngClass]="{'selected-img': currentImage === i}">
                    </div>
                </div>

                <div class=" md:mt-6" (click)="nextImg(currentImage)">
                    <img class="rotate-90" src="assets/img/arrow-carousel.svg" alt=""
                        [ngClass]="getMediaList('imageCarousel') && currentImage === getMediaList('imageCarousel').length - 1 ? 'arrow-filter' : 'arrow cursor-pointer'">
                </div>
            </div>

            <div class="lg:w-full cursor-pointer" (click)="openLightbox(currentImage)">
                <img *ngIf="getMediaList('imageCarousel') && getMediaList('imageCarousel').length"
                    class="w-180 h-180 object-cover lg:w-full lg:h-auto "
                    [src]="getMediaList('imageCarousel')[currentImage].view_image" alt="">
            </div>


        </div>


        <div
            class="mx-12 flex-shrink sm:mx-0 md:px-9 sm:px-6 sm:w-full lg:w-full lg:mx-0 relative z-10 details-container reveal">
            <h3 *ngIf="currentArtwork.name" class="uppercase text-gray-darkest text-2xl">
                {{currentArtwork.name[currentLanguage]}}</h3>
            <div *ngIf="getFirstEntityName('culture') && getFirstEntityName('culture')[currentLanguage]"
                class="text-gray-darkest text-base"><span>{{getFirstEntityName('culture')[currentLanguage]}} ·
                </span><span
                    *ngIf="getFirstEntityName('period') && getFirstEntityName('period')[currentLanguage]">{{getFirstEntityName('period')[currentLanguage]}}</span>

            </div>
            <div
                class="flex border-b pb-6 w-full mt-8 justify-between flex-wrap xl:flex-col lg:flex-row sm:w-auto sm:mt-6 sm:justify-between sm:pb-7 xssm:flex-col xs:justify-between xxs:items-center">

                <div class="mr-8 flex items-center">
                    <div class="py-3"><span class="uppercase text-gray-grayText ">{{generalTexts[currentLanguage].ref}}:
                            {{currentArtwork.client_reference}}</span>
                    </div>

                </div>

                <div class="flex flex-wrap relative btn-group-container">


                    <div *ngIf="currentArtwork['sale_status'] && (currentArtwork['sale_status'].toLowerCase() === 'available' || currentArtwork['sale_status'].toLowerCase() === 'potential_buyer')"
                        class="flex items-center justify-center border border-gray-outline px-4 h-12 mr-4 xssm:mt-5 sm:px-3 sm:w-44 lg:mt-0 xl:mt-4 xl:w-48">
                        <span
                            class="whitespace-nowrap text-gray-grayText text-sm capitalize sm:text-xs">{{generalTexts[currentLanguage].price}}:
                            <span
                                *ngIf="(currentArtwork['sale_status'].toLowerCase() === 'available' || currentArtwork['sale_status'].toLowerCase() === 'potential_buyer') && currentArtwork['price']['sale_price']['value']"
                                class="whitespace-nowrap font-semibold text-black">{{
                                currentArtwork['price']['sale_price']['value'] }} {{
                                currencySymbol[currentArtwork['price']['sale_price']['currency']]}}</span>
                            <span
                                *ngIf="currentArtwork['sale_status'].toLowerCase() === 'available' && !currentArtwork['price']['sale_price']['value']"
                                class="whitespace-nowrap font-semibold text-black"> {{
                                generalTexts[currentLanguage].to_consult }}</span>
                            <span *ngIf="currentArtwork['sale_status'].toLowerCase() === 'reserved'"
                                class="whitespace-nowrap font-semibold text-black"> {{
                                generalTexts[currentLanguage].reserved }}</span>
                        </span>

                    </div>
                    <div class="relative form-btn-container">

                        <button
                            *ngIf="currentArtwork['sale_status'].toLowerCase() !== 'sold' && currentArtwork['sale_status'].toLowerCase() !== 'reserved'"
                            (click)="interfaceService.displayForm = true"
                            class="flex items-center justify-center bg-gray-outline px-8 h-12 xs:mr-0 xssm:mt-5 sm:px-3 sm:w-44 lg:mt-0 xl:mr-0 xl:mt-4 xl:w-48">
                            <span class="text-white font-semibold text-sm uppercase sm:text-xs ">{{
                                generalTexts[currentLanguage].consult }}</span>
                        </button>

                        <button
                            *ngIf="currentArtwork['sale_status'].toLowerCase() === 'sold' || currentArtwork['sale_status'].toLowerCase() === 'reserved'"
                            class="flex items-center justify-center bg-gray-outline px-8 h-12 xs:mr-0 xssm:mt-5 sm:px-3 sm:w-44 lg:mt-0 xl:mr-0 xl:mt-4 xl:w-48">
                            <span *ngIf="currentArtwork['sale_status'].toLowerCase() === 'sold'"
                                class="text-white font-semibold text-sm uppercase sm:text-xs ">{{
                                generalTexts[currentLanguage].sold }}</span>
                            <span *ngIf="currentArtwork['sale_status'].toLowerCase() === 'reserved'"
                                class="text-white font-semibold text-sm uppercase sm:text-xs ">{{
                                generalTexts[currentLanguage].reserved }}</span>
                        </button>



                    </div>


                </div>

            </div>

            <div class="font-montserrat">
                <div class="border-b w-full sm:w-auto sm:border-b-0">
                    <div *ngIf="getEntityList(artworkId, 'material') && getEntityList(artworkId, 'material').length"
                        class="mr-36 my-5 lg:mr-2 mdlg:mr-44 sm:mr-0">
                        <div><span class="font-bold capitalize">{{generalTexts[currentLanguage].material}}</span>
                        </div>
                        <div class="text-gray-grayText">
                            <span *ngFor="let item of getEntityList(artworkId, 'material'); let i = index">{{
                                item.name[currentLanguage] }}<span
                                    *ngIf="i < getEntityList(artworkId, 'material').length - 1">, </span></span>
                        </div>
                    </div>
                    <div *ngIf="currentArtwork.dimensions && currentArtwork.dimensions.height && currentArtwork.dimensions.width && currentArtwork.dimensions.depth" class="mb-5">
                        <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].dimensions }}</span>
                        </div>
                        <div class="text-gray-grayText">
                            <span>{{currentArtwork.dimensions.width}}</span>
                            <span> x {{currentArtwork.dimensions.height}}</span>
                            <span> x {{currentArtwork.dimensions.depth}}</span>
                            <span> {{currentArtwork.dimensions.units}}</span>
                        </div>
                    </div>
                    <div *ngIf="currentArtwork.custom_dimensions && currentArtwork.custom_dimensions[currentLanguage]" class="mb-5">
                        <div><span class="font-bold">Detalles de las dimensiones</span>
                        </div>
                        <div class="text-gray-grayText">
                            <span>{{currentArtwork.custom_dimensions[currentLanguage]}}</span>
                        </div>
                    </div>
                    <div *ngIf="getEntityList(artworkId, 'artist') && getEntityList(artworkId, 'artist').length"
                        class="w-full mr-36 mb-5 sm:mr-0">
                        <div><span class="font-bold">{{ generalTexts[currentLanguage].author }}</span></div>
                        <div class="w-full sm:w-auto text-gray-grayText">
                            <span
                                *ngFor="let item of getEntityList(artworkId, 'artist'); let i = index">{{item.name[currentLanguage]}}
                                <span *ngIf="i < getEntityList(artworkId, 'artist').length - 1">, </span></span>
                        </div>
                    </div>
                    <div *ngIf="currentArtwork.provenance && currentArtwork.provenance[currentLanguage]"
                        class="w-full mr-36 mb-5 sm:mr-0 sm:w-full">
                        <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].provenance }}</span>
                        </div>
                        <div class="w-full sm:w-auto">
                            <p class="w-full text-gray-grayText"
                                [innerHtml]="currentArtwork.provenance[currentLanguage]">
                            </p>
                        </div>
                    </div>
                    <div *ngIf="currentArtwork.condition && currentArtwork.condition[currentLanguage]"
                        class="w-full mr-36 mb-5 sm:mr-0">
                        <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].conservation }}</span>
                        </div>
                        <div class="w-full sm:w-auto">
                            <p class="w-full text-justify text-gray-grayText"
                                [innerHtml]="currentArtwork.condition[currentLanguage]"></p>
                        </div>
                    </div>
                    <div *ngIf="currentArtwork.scientific_tests && currentArtwork.scientific_tests[currentLanguage]"
                        class="mb-5">
                        <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].documents }}</span>
                        </div>
                        <div class="w-full sm:w-auto text-justify text-gray-grayText">
                            <span>{{currentArtwork.scientific_tests[currentLanguage]
                                }}</span>
                        </div>
                    </div>

                </div>

                <div *ngIf="currentArtwork.description && currentArtwork.description[currentLanguage]"
                    class="sm:hidden">
                    <div class=" my-5 sm:mr-0 sm:pb-0">
                        <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].description }}</span>
                        </div>
                        <div class="w-full sm:w-auto overflow-hidden">
                            <p class="text-justify w-full text-gray-grayText line-clamp-5"
                                [innerHtml]="currentArtwork.description[currentLanguage]"></p>
                        </div>
                    </div>
                    <button class="flex items-center justify-center border border-gray-medium px-6 py-3 "
                        (click)="scrollIntoView('scrollTarget')">
                        <span class="text-gray-darkest text-xs">{{ generalTexts[currentLanguage].read_more }}</span>
                        <img class="h-3 w-2 ml-3 rotate-90" src="assets/img/simple-arrow-right-dark.svg" alt="">
                    </button>
                </div>

            </div>
        </div>

    </div>

    <div *ngIf="currentArtwork && displaySection()"
        class=" bg-light-offWhiteDarker w-full px-28 py-24 mt-12 sm:px-6 sm:py-9 sm:mt-4 lg:px-12 table-container"
        id="scrollTarget">

        <table class="w-full max-width ">
            <tr *ngIf="currentArtwork.description && currentArtwork.description[currentLanguage]"
                class="border-b reveal">
                <td class="font-bold whitespace-nowrap capitalize flex w-72 smmd:w-56 label-desc">
                    <span>
                        {{ generalTexts[currentLanguage].complete_description }}
                    </span>
                </td>
                <td>
                    <div class="columns gap-20 pb-12 content-desc">
                        <p class="text-justify text-gray-grayText "
                            [innerHtml]="currentArtwork.description[currentLanguage]"></p>
                    </div>
                </td>
            </tr>

            <tr *ngIf="currentArtwork.publications && currentArtwork.publications[currentLanguage]" class="reveal">
                <td class="flex w-72 smmd:w-56 label">
                    <span class="font-bold capitalize pt-12 smmd:pt-6">{{ generalTexts[currentLanguage].published
                        }}</span>
                </td>
                <td class="py-12 smmd:py-6 content">
                    <p class="w-full text-justify text-gray-grayText"
                        [innerHtml]="currentArtwork.publications[currentLanguage]"></p>
                </td>
            </tr>

            <tr *ngIf="currentArtwork.bibliography_reference && currentArtwork.bibliography_reference[currentLanguage]"
                class="reveal">
                <td class="flex w-72 smmd:w-56 label">
                    <span class="font-bold capitalize pt-12 smmd:pt-6">{{
                        generalTexts[currentLanguage].bibliography_reference
                        }}</span>
                </td>
                <td class="py-12 smmd:py-6 sm:py-0 content">
                    <p class="text-justify text-gray-grayText"
                        [innerHtml]="currentArtwork.bibliography_reference[currentLanguage]"></p>
                </td>
            </tr>

            <tr *ngIf="currentArtwork.exhibited && currentArtwork.exhibited[currentLanguage]" class="reveal">
                <td class="flex w-72 smmd:w-56 label">
                    <span class="font-bold pt-12 smmd:pt-6">{{ generalTexts[currentLanguage].exhibited }}</span>
                </td>
                <td class="py-12 smmd:py-6 sm:py-0 content">
                    <p class="text-justify text-gray-grayText" [innerHtml]="currentArtwork.exhibited[currentLanguage]">
                    </p>
                </td>
            </tr>

            <tr *ngIf="getMediaList('imageParallel') && getMediaList('imageParallel').length">
                <td class="flex w-72 pt-12 smmd:w-56 label reveal">
                    <span class="font-bold capitalize">{{ generalTexts[currentLanguage].parallels }}</span>
                </td>
                <td>
                    <div class="flex flex-wrap pt-12 pb-3 content">
                        <app-image-list [mediaList]="getMediaList('imageParallel')"></app-image-list>
                    </div>

                </td>
            </tr>

            <tr *ngIf="getMediaList('imageRelated') && getMediaList('imageRelated').length">
                <td class="flex w-72 pt-12 smmd:w-56 label reveal">
                    <span class="font-bold capitalize">{{ generalTexts[currentLanguage].related }}</span>
                </td>
                <td>
                    <div class="flex flex-wrap pt-12 pb-3 content">
                        <app-image-list [mediaList]="getMediaList('imageRelated')"></app-image-list>
                    </div>
                </td>
            </tr>

            <tr *ngIf="tagList && tagList.length" class="reveal">
                <td class="flex w-72 smmd:w-56 label">
                    <span class="font-bold pt-12 smmd:pt-6">{{ generalTexts[currentLanguage].tags }}</span>
                </td>
                <td class="py-12 smmd:py-6 sm:py-0 content">
                    <div class="flex flex-wrap">
                        <div *ngFor="let tag of tagList"
                            class="text-gray-grayText py-2 px-6 rounded-full border border-dropdown mr-2 mb-2">
                            <span>{{tag.name[currentLanguage]}}</span>
                        </div>
                    </div>

                </td>
            </tr>

        </table>
    </div>
    <div *ngIf="currentArtwork">
        <app-consult-form [formDetails]="formDetails" [name]="currentArtwork.name[currentLanguage]"></app-consult-form>
    </div>
</div>

<app-lightbox [lightboxArr]="mediaService.lightboxArr" [currentImage]="mediaService.currentImage"
    (resetCurrentImg)="mediaService.resetCurrentImg($event)"></app-lightbox>