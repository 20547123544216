<swiper-container [navigation]="true" speed="1000" [autoplay]="{delay: 5000, disableOnInteraction: false}"
    class="h-full w-full home-swiper" slides-per-view="1" init="false" #swiperRef>
    <swiper-slide *ngFor="let item of homeSlides; let i = index">
        <div class=" w-full h-full text-sm">
            <div *ngIf="item"
                class="absolute   text-white  md:flex md:flex-col md:items-center md:bottom-20 md:w-full md:text-center center"
                [ngClass]="item.text === 'right' ? 'text-container-right' : 'text-container-left mdmin:text-left'">

                <h2 class="text-3xl font-trajan font-bold md:text-center uppercase desktop-display whitespace-pre-line"
                    [ngClass]="{'desktop-display': item.mobileName}" [innerHTML]="item.name[currentLanguage]">
                </h2>

                <h2 *ngIf="item.mobileName"
                    class="mobile-display text-3xl font-trajan font-bold md:text-center uppercase"
                    [innerHTML]="item.mobileName[currentLanguage]"></h2>

                <h4 class="text-base mt-2 culture">
                    <span *ngIf="displayArtist(item.id)">{{displayArtist(item.id)}}</span>
                    <span
                        *ngIf="!displayArtist(item.id) && getFirstEntityName(item.id, 'culture') && getFirstEntityName(item.id, 'culture')[currentLanguage]">{{
                        getFirstEntityName(item.id, 'culture')[currentLanguage] }}</span>
                    <span
                        *ngIf="(displayArtist(item.id) || (getFirstEntityName(item.id, 'culture') && getFirstEntityName(item.id, 'culture')[currentLanguage])) && getFirstEntityName(item.id, 'period') && getFirstEntityName(item.id, 'period')[currentLanguage]">
                        · </span>
                    <span
                        *ngIf="getFirstEntityName(item.id, 'period') && getFirstEntityName(item.id, 'period')[currentLanguage]">
                        {{ getFirstEntityName(item.id, 'period')[currentLanguage] }}
                    </span>
                </h4>

                <h4 *ngIf="item.subtitleText" class="text-base mt-2 whitespace-pre-line"
                    [innerHTML]="item.subtitleText[currentLanguage]">
                </h4>
                <div class="mt-6">
                    <button (click)="navigateTo(item.type, item.navigateTo)"
                        class="uppercase border px-8 h-12 hover:bg-white hover:text-black ">{{
                        generalTexts[currentLanguage].see_now }}</button>
                </div>
            </div>

            <img class="w-full h-full desktop-display object-cover" [src]="item.imgPath" alt="">
            <img class="w-full h-full mobile-display object-cover" [src]="item.mobileGradientImgPath" alt="">

        </div>
    </swiper-slide>
</swiper-container>

<div class="home-btn-container z-10 absolute flex justify-between">
    <button id="prevBtnHome" class="swiper-button-prev-home z-10" [disabled]="swiper && swiper.activeIndex === 0">
    </button>
    <button id="nextBtnHome" class="swiper-button-next-home z-10"
        [disabled]="swiper && swiper.activeIndex === homeSlides.length - 1">
    </button>
</div>