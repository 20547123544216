import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { InterfaceService } from 'src/app/services/interface.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get currentData() {
    return this.dataService.data;
  }

  constructor(
    public navigationService: NavigationService,
    public interfaceService: InterfaceService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.interfaceService.displayDark = true;
    this.interfaceService.footerBackgroundChange = true;

    this.interfaceService.reveal();
    window.addEventListener('scroll', this.interfaceService.reveal);
  }

}
