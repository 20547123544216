<div class="pt-36 pb-12 text-sm lg:pb-8 lg:pt-24 3xlmin:flex 3xlmin:flex-col 3xlmin:items-center ">
    <div class="flex justify-center mb-20 lg:mb-9 reveal">
        <h3 class="uppercase text-2xl text-center text-gray-dark">{{ generalTexts[currentLanguage].about_title }}</h3>
    </div>

    <div class="flex justify-center mx-28 mb-16 lg:mx-6 lg:flex-col-reverse lg:mb-12  3xlmin:w-350">
        <div class="w-180 mr-12 flex flex-col justify-center lg:w-full reveal">
            <p class=" mb-4 text-gray-dark text-justify"
                [innerHtml]="currentData[currentLanguage].about.content[0].intro"></p>
            <p class="mb-4 text-gray-grayText text-justify"
                [innerHtml]="currentData[currentLanguage].about.content[1].section_1"></p>


            <div class="lg:flex lg:justify-center lg:mt-6">
                <button class="flex items-center justify-center border border-gray-medium px-6 py-3"
                    (click)="navigationService.navigateTo('young-collectors')">
                    <span class="text-gray-darkest text-sm ">{{ generalTexts[currentLanguage].see_young }}</span>
                    <img class="h-3 w-2 ml-3" src="assets/img/simple-arrow-right-dark.svg" alt="">
                </button>
            </div>
        </div>
        <div class="lg:mb-6 reveal">
            <img class="object-cover h-112 lg:w-full" src="assets/img/mock-images/about-1.png" alt="">
        </div>
    </div>

    <div class="flex justify-center mx-28 mb-16 lg:mx-6 lg:flex-col lg:mb-12 3xlmin:w-350">
        <div class="mr-12 lg:mr-0 lg:mb-6 reveal">
            <img class="object-cover h-128 lg:w-full lg:h-auto" src="assets/img/mock-images/about-2.png" alt="">
        </div>
        <div class="w-180 flex flex-col justify-center lg:w-full reveal">
            <p class="mb-4 text-gray-grayText text-justify"
                [innerHtml]="currentData[currentLanguage].about.content[2].section_2"></p>

            <div class="lg:flex lg:justify-center lg:mt-6">
                <button class="flex items-center justify-center border border-gray-medium px-6 py-3"
                    (click)="navigationService.navigateTo('artworks-in-museums')">
                    <span class="text-gray-darkest text-sm ">{{ generalTexts[currentLanguage].see_artworks_museums
                        }}</span>
                    <img class="h-3 w-2 ml-3" src="assets/img/simple-arrow-right-dark.svg" alt="">
                </button>
            </div>
        </div>
    </div>

    <div class="flex justify-center mx-28 mb-16  lg:mx-6 lg:flex-col-reverse lg:mb-12 3xlmin:w-350 ">
        <div class="w-160 mr-12 flex flex-col justify-center lg:w-full xl:w-144 reveal">
            <p class="mb-4 text-gray-grayText text-justify"
                [innerHtml]="currentData[currentLanguage].about.content[3].section_3"></p>

            <div class="lg:flex lg:justify-center lg:mt-6">
                <button class="flex items-center justify-center border border-gray-medium px-6 py-3"
                    (click)="navigationService.navigateTo('publications')">
                    <span class="text-gray-darkest text-sm ">{{ generalTexts[currentLanguage].see_catalogs }}</span>
                    <img class="h-3 w-2 ml-3" src="assets/img/simple-arrow-right-dark.svg" alt="">
                </button>
            </div>
        </div>
        <div class="flex lg:w-full lg:justify-between lg:mb-6">
            <img class="object-cover w-64 h-112 mr-9 lg:w-45% lg:h-auto reveal" src="assets/img/mock-images/about-3.png"
                alt="">
            <img class="object-cover w-64 h-112 lg:w-45% lg:h-auto reveal" src="assets/img/mock-images/about-4.png"
                alt="">
        </div>
    </div>

    <div class="flex justify-center mx-28 mb-16 lg:mx-6 lg:flex-col lg:mb-12 3xlmin:w-350">
        <div class="mr-12 lg:mr-0 lg:mb-6 reveal">
            <img class="object-cover h-112 lg:w-full" src="assets/img/mock-images/about-5.png" alt="">
        </div>
        <div class="w-180 flex flex-col justify-center lg:w-full reveal">
            <p class="w-128 mb-8 text-gray-grayText text-justify lg:w-auto lg:mb-3"
                [innerHtml]="currentData[currentLanguage].about.content[4].section_4"></p>

            <div class="lg:flex lg:justify-center lg:mt-6">
                <button class="flex items-center justify-center border border-gray-medium px-6 py-3"
                    (click)="navigationService.navigateTo('exhibitions')">
                    <span class="text-gray-darkest text-sm ">{{ generalTexts[currentLanguage].see_fair }}</span>
                    <img class="h-3 w-2 ml-3" src="assets/img/simple-arrow-right-dark.svg" alt="">
                </button>
            </div>
        </div>
    </div>

</div>