<div class="flex absolute w-full justify-between p-4 box-border z-3">
    <div class="sm:w-full sm:flex sm:justify-center ">
        <img [routerLink]="['/']" class="cursor-pointer w-56 sm:w-48" (click)="currentPath = ''"
            [ngClass]="{'invert': interfaceService.displayDark}" src="assets/img/bagot-logo-full.svg" alt="">
    </div>
    <div class="lgmin:hidden absolute right-6 cursor-pointer" (click)="toggleMenu()">
        <span class="text-3xl" [ngClass]="{'invert': interfaceService.displayDark}"
            style="color: white; line-height: 1">☰</span>
    </div>

    <div class="flex w-250 justify-between items-baseline ml-12 lg:hidden 3xlmin:w-272">
        <div *ngFor="let element of navigation"
            class="uppercase font-trajan text-xs text-center cursor-pointer hover-bold under"
            [ngClass]="{'font-semibold': element.path === currentPath}"
            (click)="navigate(element.path)">
            <span
                [ngClass]="interfaceService.displayDark ? 'text-gray-dark' : 'text-white '">{{element['title']}}</span>
            <hr class=" mt-4 border-t-2 " [ngClass]=" element.path === currentPath ? 'mx-0' : 'grow'">

        </div>

        <div class="relative">
            <img class="absolute w-3 lang-arrow "
                [src]="interfaceService.displayDark ? 'assets/img/arrow-down-black.svg': 'assets/img/arrow-down-white.svg'"
                alt="">
            <select name="category" id="categorySelect" (change)="onLanguageChanged($event)"
                class="  cursor-pointer border-none focus:border-transparent focus:ring-0  font-semibold rounded-full h-8 w-18 pl-4 py-0 text-xs font-trajan "
                [ngClass]="interfaceService.displayDark ? 'dark-bg ' : 'light-bg '" name="languages" id="language">
                <option [ngClass]="interfaceService.displayDark ? 'option-dark ' : 'option-light '"
                    class=" cursor-pointer text-base" [selected]="lang.id == currentLanguage"
                    *ngFor="let lang of availableLanguages" [value]="lang.id">{{ lang.id }}</option>
            </select>
        </div>

    </div>
</div>



<!----------------------------------- MOBILE ----------------------------------------------->
<div class="lgmin:hidden fixed top-0 right-0 bg-white w-4/5 h-full z-20 font-trajan text-xs" [ngClass]="{
'no-opacity': !interfaceService.isOpacityActivated, 
'display-opacity': interfaceService.isOpacityActivated && interfaceService.isMenuOpen,
'hidden-menu ': !interfaceService.startDisplay}">

    <div class="flex justify-between px-6 py-5 border-b ">
        <div class="">
            <div class="relative">
                <img class="absolute w-3 lang-arrow" src="assets/img/arrow-down-black.svg" alt="">

                <select name="category" id="categorySelect" (change)="onLanguageChanged($event)"
                    class="lang-selector text-xs cursor-pointer font-semibold border border-gray-dropdown rounded-full h-8 w-18 pl-4 pb-0  focus:border-gray-dropdown ring-offset-0 focus:ring-0"
                    name="languages" id="language">
                    <option class="hover:bg-white cursor-pointer text-base" [selected]="lang.id == currentLanguage"
                        *ngFor="let lang of availableLanguages" [value]="lang.id">{{ lang.id }}</option>
                </select>
            </div>
        </div>
        <div class="flex items-center" (click)="toggleMenu()">
            <img class="invert w-5 h-5 cursor-pointer" src="assets/img/icon-close.svg" alt="">
        </div>
    </div>
    <div class="border-b px-6 mt-12 pb-6">
        <div *ngFor="let element of navigation" class="uppercase cursor-pointer  mb-6"
            (click)="navigateTo(element.path)">
            {{element['title']}}
        </div>
    </div>
    <div class="uppercase px-6 mt-12 ">
        <p class="mb-6 cursor-pointer uppercase" (click)="navigateExternal('https://blog.jbagot.com')">BLOG</p>
        <p class="mb-6 cursor-pointer uppercase" (click)="navigateExternal(exhibitionUrl)">{{
            generalTexts[currentLanguage].private_gallery }}</p>
        <p class="mb-6 cursor-pointer uppercase" (click)="navigateTo('appraisers')">{{
            generalTexts[currentLanguage].appraisers }}</p>
        <p class="mb-6 cursor-pointer uppercase" (click)="navigateTo('work-with-us')">
            {{generalTexts[currentLanguage].work_with_us}}</p>
    </div>

</div>

<div class="lgmin:hidden fixed w-full h-full top-0" (click)="toggleMenu()" [ngClass]="{ 'hidden-menu ': !interfaceService.startDisplay,
    'no-opacity-gradient': !interfaceService.isOpacityActivated, 
    'display-opacity-gradient': interfaceService.isOpacityActivated && interfaceService.isMenuOpen}">

</div>