import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { InterfaceService } from 'src/app/services/interface.service';

@Component({
  selector: 'app-authenticity',
  templateUrl: './authenticity.component.html',
  styleUrls: ['./authenticity.component.scss'],
})
export class AuthenticityComponent implements OnInit {
  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get currentData() {
    return this.dataService.data;
  }

  firstLoad = false;

  constructor(
    public interfaceService: InterfaceService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.interfaceService.displayDark = true;
    this.interfaceService.footerBackgroundChange = true;

    this.interfaceService.reveal();
    window.addEventListener('scroll', this.interfaceService.reveal);
  }

  ngAfterViewChecked(): void {
    if (!this.firstLoad || this.interfaceService.languageChanged) {

      this.interfaceService.reveal();
      this.firstLoad = true;
      
      this.interfaceService.languageChanged = false;
    }
  }
}
