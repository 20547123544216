<div class="pt-40 pb-24 flex flex-col items-center sm:pt-24">
    <div class="flex justify-center mb-24 sm:mb-12 reveal">
        <h3 class="uppercase text-2xl w-180 text-center sm:text-xl sm:px-9 md:w-auto md:px-9 ">{{
            generalTexts[currentLanguage].museums_title }}</h3>
    </div>

    <div *ngIf="museumArtworks.length"
        class="flex flex-wrap justify-center max-w-78 xs:px-0 sm:px-6 sm:justify-center lg:px-8">
        <div *ngFor="let artwork of museumArtworks" class=" text-gray-darkest mb-12 w-88 mx-8 reveal">
            <div class="flex flex-col items-center">

                <img class="w-88 h-88 object-cover" [src]="getMediaList(artwork.customId, 'imageCarousel')[0].path"
                    alt="">
                <img class="w-88 h-88 object-cover absolute opacity-0 hover:opacity-100 img-hover"
                    [src]="getMediaList(artwork.customId, 'imageCarousel')[1].path" alt="">
                <div class="mt-4 text-center"><span
                        class="uppercase font-trajan text-lg">{{artwork.name[currentLanguage]}}</span>
                </div>
                <span class="text-center text-xs">{{getFirstEntityName(artwork.customId,
                    'period')[currentLanguage]}}</span>
                <hr class="border-t w-12 border-gray-medium my-3">
                <span class="text-xs text-center text-gray-medium">{{getFirstEntityName(artwork.customId,
                    'artist')[currentLanguage]}}</span>
            </div>
        </div>
    </div>
</div>