<swiper-container [slidesPerView]="1" [spaceBetween]="8" [slidesPerGroup]="1" [loop]="false"
    [loopFillGroupWithBlank]="true" [pagination]="{
      el: '.swiper-pagination',
      clickable: true
    }" [navigation]="true" class="publication-swiper " init="false" #publicationSwiperRef>

    <swiper-slide class="bg-gray flex justify-center" *ngFor="let publication of publications">
        <div class=" text-gray-darkest mx-4 w-96 remove-margin flex flex-col items-center">
            <img class="" [src]="publication.imgPath" alt="" />

        </div>
    </swiper-slide>
</swiper-container>

<div class="publication-btn-container absolute z-10 flex justify-between w-full">
    <!-- <div class="swiper-button-next swiper-btn">
    </div>
    <div class="swiper-button-prev swiper-btn">
    </div> -->

    <button #swiperBtn id="prevBtnPublication" class="nav-btn flex justify-center items-center  rotate-180"
        [disabled]=" swiper && swiper.activeIndex === 0">
        <img src="assets/img/simple-arrow-right-dark.svg" alt=""></button>
    <button #swiperBtn id="nextBtnPublication" class="nav-btn flex justify-center items-center"
        [disabled]="onLastSlide"><img src="assets/img/simple-arrow-right-dark.svg" alt=""></button>
</div>