<div *ngIf="currentArtwork" class="pt-36 sm:pt-24 text-sm  flex flex-col items-center">
    <div
        class="flex justify-center flex-col items-center w-full h-full px-16  lg:px-10 md:px-0 3xlmin:w-350  max-width">

        <div class="w-full pb-8 mdmin:hidden reveal">
            <app-painting-mobile-swiper [imageCarousel]="getMediaList('imageCarousel')"></app-painting-mobile-swiper>
        </div>

        <div class="flex flex-col w-full justify-items-center items-center mb-6 3xl:artwork-images md:hidden reveal ">
            <div class="w-auto current-image flex items-center cursor-pointer mb-6"
                (click)="openLightbox(currentImage)">
                <img *ngIf="getMediaList('imageCarousel') && getMediaList('imageCarousel').length"
                    class="object-contain max-height-img" [src]="getMediaList('imageCarousel')[currentImage].path"
                    alt="">
            </div>

            <div class="flex w-180 justify-around items-center md:h-auto">
                <div class="md:mb-6" (click)="prevImg(currentImage)">
                    <img class="rotate-180 " src="assets/img/arrow-carousel.svg" alt=""
                        [ngClass]="currentImage === 0 ? 'arrow-filter' : 'arrow cursor-pointer' ">
                </div>

                <div class="h-full flex justify-center items-center">
                    <div *ngFor="let img of getMediaList('imageCarousel'); let i = index"
                        [ngClass]="i >= startRange && i <= endRange ? 'block' : 'hidden'"
                        class="img-container overflow-hidden flex justify-center items-center">
                        <img (click)="mediaService.currentImage = i" [src]="img.path" alt=""
                            class="cursor-pointer w-18 h-18 object-cover "
                            [ngClass]="{'selected-img': currentImage === i}">
                    </div>
                </div>

                <div class=" md:mt-6" (click)="nextImg(currentImage)">
                    <img class="" src="assets/img/arrow-carousel.svg" alt=""
                        [ngClass]="getMediaList('imageCarousel') && currentImage === getMediaList('imageCarousel').length - 1 ? 'arrow-filter' : 'arrow cursor-pointer'">
                </div>
            </div>

        </div>

        <div class="w-full flex justify-center reveal">
            <hr>
        </div>



        <div class="content-container mt-9 md:px-9 sm:px-6 sm:w-full relative z-10 details-container reveal">
            <h3 *ngIf="currentArtwork.name" class="uppercase text-gray-darkest text-2xl">
                {{currentArtwork.name[currentLanguage]}}</h3>
            <h4 *ngIf="getFirstEntityName('artist')" class="text-lg text-gray-darkest">
                {{getFirstEntityName('artist')[currentLanguage]}}
                <span *ngIf="getArtistSurname()">{{getArtistSurname()[currentLanguage]}}</span>
            </h4>


            <div
                class="flex pb-6 w-full mt-8 justify-between flex-wrap xl:flex-col lg:flex-row sm:w-auto sm:mt-6 sm:justify-between sm:pb-7 xssm:flex-col xs:justify-between xxs:items-center">

                <div class="mr-8 flex items-center">
                    <div class="py-3"><span class="uppercase text-gray-grayText ">{{generalTexts[currentLanguage].ref}}:
                            {{currentArtwork.client_reference}}</span>
                    </div>

                </div>
                <div>
                    <app-sale-btn-group [currentArtwork]="currentArtwork"></app-sale-btn-group>
                </div>

            </div>

        </div>


        <div class="w-full flex justify-center reveal">
            <hr>
        </div>



        <div
            class="font-montserrat content-container my-12 md:px-9 sm:px-6 sm:w-full relative z-10 details-container reveal">
            <div class="w-full sm:w-auto">

                <div *ngIf="getFirstEntityName('period') && getFirstEntityName('period')[currentLanguage]" class="mb-5">
                    <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].period }}</span>
                    </div>
                    <div class="text-gray-grayText"><span>{{getFirstEntityName('period')[currentLanguage]}}</span>
                    </div>
                </div>
                <div *ngIf="getFirstEntityName('technique') && getFirstEntityName('technique')[currentLanguage]"
                    class="mb-5">
                    <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].technique }}</span>
                    </div>
                    <div class="text-gray-grayText"><span>{{getFirstEntityName('technique')[currentLanguage]}}</span>
                    </div>
                </div>
                <div *ngIf="currentArtwork.dimensions && currentArtwork.dimensions.height && currentArtwork.dimensions.width && currentArtwork.dimensions.depth" class="mb-5">
                    <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].dimensions }}</span>
                    </div>
                    <div class="text-gray-grayText">
                        <span>{{currentArtwork.dimensions.width}}</span>
                        <span> x {{currentArtwork.dimensions.height}}</span>
                        <span> x {{currentArtwork.dimensions.depth}}</span>
                        <span> {{currentArtwork.dimensions.units}}</span>
                    </div>
                </div>
                <div *ngIf="currentArtwork.custom_dimensions && currentArtwork.custom_dimensions[currentLanguage]" class="mb-5">
                    <div><span class="font-bold">Detalles de las dimensiones</span>
                    </div>
                    <div class="text-gray-grayText"><span>{{currentArtwork.custom_dimensions[currentLanguage]}}</span>
                    </div>
                </div>
                <div *ngIf="currentArtwork.provenance && currentArtwork.provenance[currentLanguage]"
                    class="w-full mr-36 mb-5 sm:mr-0 sm:w-full">
                    <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].provenance }}</span>
                    </div>
                    <div class="w-full sm:w-auto">
                        <p class="w-full text-gray-grayText" [innerHtml]="currentArtwork.provenance[currentLanguage]">
                        </p>
                    </div>
                </div>
                <div *ngIf="currentArtwork.publications && currentArtwork.publications[currentLanguage]"
                    class="w-full mr-36 mb-5 sm:mr-0 sm:w-full">
                    <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].publications }}</span>
                    </div>
                    <div class="w-full sm:w-auto">
                        <p class="w-full text-gray-grayText" [innerHtml]="currentArtwork.publications[currentLanguage]">
                        </p>
                    </div>
                </div>
                <div *ngIf="currentArtwork.bibliography_reference && currentArtwork.bibliography_reference[currentLanguage]"
                    class="w-full mr-36 mb-5 sm:mr-0 sm:w-full">
                    <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].bibliography_reference
                            }}</span>
                    </div>
                    <div class="w-full sm:w-auto">
                        <p class="w-full text-gray-grayText"
                            [innerHtml]="currentArtwork.bibliography_reference[currentLanguage]">
                        </p>
                    </div>
                </div>
                <div *ngIf="currentArtwork.exhibited && currentArtwork.exhibited[currentLanguage]"
                    class="w-full mr-36 mb-5 sm:mr-0 sm:w-full">
                    <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].exhibited }}</span>
                    </div>
                    <div class="w-full sm:w-auto">
                        <p class="w-full text-gray-grayText" [innerHtml]="currentArtwork.exhibited[currentLanguage]">
                        </p>
                    </div>
                </div>
                <div *ngIf="currentArtwork.signed_dated_labeled && currentArtwork.signed_dated_labeled[currentLanguage]"
                    class="w-full mr-36 mb-5 sm:mr-0">
                    <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].signature }}</span>
                    </div>
                    <div class="w-full sm:w-auto">
                        <p class="w-full text-justify text-gray-grayText"
                            [innerHtml]="currentArtwork.signed_dated_labeled[currentLanguage]"></p>
                    </div>
                </div>
                <div *ngIf="currentArtwork.condition && currentArtwork.condition[currentLanguage]"
                    class="w-full mr-36 mb-5 sm:mr-0">
                    <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].conservation }}</span>
                    </div>
                    <div class="w-full sm:w-auto">
                        <p class="w-full text-justify text-gray-grayText"
                            [innerHtml]="currentArtwork.condition[currentLanguage]"></p>
                    </div>
                </div>
                <div *ngIf="currentArtwork.scientific_tests && currentArtwork.scientific_tests[currentLanguage]"
                    class="w-full mr-36 mb-5 sm:mr-0">
                    <div><span class="font-bold capitalize">{{ generalTexts[currentLanguage].documents }}</span>
                    </div>
                    <div class="w-full sm:w-auto">
                        <p class="w-full text-justify text-gray-grayText"
                            [innerHtml]="currentArtwork.scientific_tests[currentLanguage]"></p>
                    </div>
                </div>

            </div>

        </div>

        <div class="w-full flex justify-center reveal">
            <hr>
        </div>


        <div *ngIf="currentArtwork.description && currentArtwork.description[currentLanguage]"
            class="font-montserrat content-container my-12 md:px-9 sm:px-6 sm:w-full relative z-10 details-container reveal">
            <div class="mb-5">
                <div class="mb-4"><span class="font-bold capitalize">{{
                        generalTexts[currentLanguage].complete_description }}</span>
                </div>
                <div class="text-gray-grayText whitespace-pre-line">
                    <span>{{currentArtwork.description[currentLanguage]}}</span>
                </div>
            </div>


        </div>

        <div *ngIf="currentArtwork.description && currentArtwork.description[currentLanguage] && (getMediaList('imageParallel') && getMediaList('imageParallel').length || getMediaList('imageRelated') && getMediaList('imageRelated').length)"
            class="w-full flex justify-center reveal">
            <hr>
        </div>

        <div *ngIf="getMediaList('imageParallel') && getMediaList('imageParallel').length"
            class="font-montserrat content-container my-12 md:px-9 sm:px-6 sm:w-full relative z-10 details-container reveal">
            <div class="mb-4"><span class="font-bold capitalize">{{
                    generalTexts[currentLanguage].parallels }}</span>
            </div>
            <div class="pt-12 pb-3 content">
                <app-image-list [mediaList]="getMediaList('imageParallel')"></app-image-list>
            </div>
        </div>
        <div *ngIf="getMediaList('imageRelated') && getMediaList('imageRelated').length"
            class="font-montserrat content-container my-12 md:px-9 sm:px-6 sm:w-full relative z-10 details-container reveal">
            <div class="mb-4"><span class="font-bold capitalize">{{
                    generalTexts[currentLanguage].related }}</span>
            </div>
            <div class="pt-12 pb-3 content">
                <app-image-list [mediaList]="getMediaList('imageRelated')"></app-image-list>
            </div>
        </div>

        <div class="w-full flex justify-center reveal">
            <hr>
        </div>

        <div
            class="font-montserrat content-container my-12 md:px-9 sm:px-6 sm:w-full relative z-10 details-container reveal">
            <div class="mb-4"><span class="font-bold capitalize">{{
                    generalTexts[currentLanguage].tags }}</span>
            </div>
            <div class="flex flex-wrap">
                <div *ngFor="let tag of tagList"
                    class="text-gray-grayText py-2 px-6 rounded-full border border-dropdown mr-2 mb-2">
                    <span>{{tag.name[currentLanguage]}}</span>
                </div>
            </div>
        </div>


    </div>

    <div *ngIf="currentArtwork">
        <app-consult-form [formDetails]="formDetails" [name]="currentArtwork.name[currentLanguage]"></app-consult-form>
    </div>
</div>

<div *ngIf="currentArtwork">
    <app-artist [artistId]="getArtist().id"></app-artist>
</div>

<app-lightbox [lightboxArr]="mediaService.lightboxArr" [currentImage]="mediaService.currentImage"
    (resetCurrentImg)="mediaService.resetCurrentImg($event)"></app-lightbox>