{
  "es": [
    {
      "name": "FERIARTE 2024",
      "year": "2024",
      "date": "23 NOVIEMBRE – 1 DICIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2024/feriarte2024.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2024/feriarte2024-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente. \n \n En nuestro stand podrán verse dos mundos unidos, obras maestras de la pintura como Pablo Picasso, Celso Lagar, Mompó, Joan Miró y Joaquín Sorolla, dialogando con obras de las principales culturas de la antigüedad clásica, torsos de atletas griegos, venus romanas, capiteles del Califato de Córdoba, junto a sarcófagos egipcios. Sin olvidarnos de grandes pintoras como María Blanchard y María Moreno.",
      "artworks": [
        "T_5089",
        "T_5019",
        "T_1354",
        "T_1164",
        "T_4684",
        "T_5017",
        "T_4443",
        "T_5020",
        "T_5098"
      ]
    },
    {
      "name": "STANDARTE 2024 \n FERIA ARTE MODERNO",
      "year": "2024",
      "date": "5 - 10 MARZO",
      "location": "Palacio de Linares - Casa de América \nc/ Marqués del Duero 2, Madrid",
      "imgPath": "assets/img/exhibitions/standarte2024/standarte2024.jpg",
      "imgPathSquared": "assets/img/exhibitions/standarte2024/standarte2024-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://feriastandarte.com",
      "videoPath": "assets/img/exhibitions/standarte2024/standarte2024-video.mp4",
      "description": "Standarte es una feria de arte moderno y contemporáneo que abrirá sus puertas por segunda vez y en una localización nueva, en Casa de América, en el Palacio de Linares, en el centro de la capital. Un selecto grupo de galerías españolas se darán cita en esta nueva ubicación para mostrar al público piezas de arte moderno y contemporáneo de primer nivel. \n \n Más de 200 obras originales de célebres artistas como Miquel Barceló, Salvador Dalí, Joan Miró, Jaume Plensa, Pablo Picasso, Antonio Saura, Antoni Tàpies, Manolo Valdés entre otros, todas ellas documentadas y catalogadas. \n \n En nuestro stand podrán verse dos mundos unidos, obras maestras de la pintura como Joan Miró y Joaquín Sorolla, dialogando con obras de las principales culturas de la antigüedad clásica, torsos de atletas griegos, Venus romanas, capiteles del Califato de Córdoba, junto a sarcófagos egipcios. ",
      "artworks": [
        "T_1186",
        "T_1342",
        "T_1113"
      ]
    },
    {
      "name": "FERIARTE 2023",
      "year": "2023",
      "date": "11 - 19 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2023/feriarte2023.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2023/feriarte2023-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente. \n \n En nuestro stand podrán verse más de cuatrocientas obras arqueológicas, entre las que destacarán un torso femenino monumental de época romana; un conjunto de vasos cerámicos griegos, entre los cuales destaca un Pixis del período Geométrico procedente de la colección personal del Presidente de la República de Francia, Valéry Giscard d'Estaing; un excelente relieve de sarcófago con Venus Victrix; una escultura egipcia de la época de las pirámides en granodiorita de destacadas dimensiones, que sobrevivió a los bombardeos de Alemania durante la Segunda Guerra Mundial; y un nutrido grupo de arte ibérico, fíbulas celtíberas, exvotos, dos ídolos prehistóricos y una pareja de capiteles hispano-musulmanes. Un fragmento de hueso del paleolítico de la P. Ibérica, con un bisonte grabado procedente de la Cueva de La Peña de Candamo. Todas ellas bajo la atenta mirada del emperador Antonino Pío, un excelente busto romano que perteneció a la colección de Andy Warhol.",
      "artworks": [
        "T_1165",
        "T_1118",
        "T_1164",
        "T_1112",
        "T_774",
        "T_885",
        "T_1092",
        "T_1155"
      ]
    },
    {
      "name": "HERBERT LIST Y EL IDEAL CLÁSICO",
      "year": "2023",
      "date": "21 SEPTIEMBRE - 20 NOVIEMBRE 2023",
      "location": "Galería J.Bagot - C/ del Consell de Cent, 321",
      "imgPath": "assets/img/exhibitions/herbert2023/herbert2023.png",
      "imgPathSquared": "assets/img/exhibitions/herbert2023/herbert2023-squared.png",
      "exhibitionImgPaths": [],
      "url": "https://herbertlistyelidealclasico.com",
      "description": "Exposición en que se confrontan algunas de las más importantes obras del gran fotógrafo alemán Herbert List (1903 - 1975) con piezas arqueológicas originales griegas y romanas datadas entre los siglos VI a.C y IV d.C. \n \n La exposición “Herbert List y el ideal clásico” ha sido organizada en colaboración con The Herbert List Estate de Hamburgo. \n \n La muestra presenta 44 magníficas fotografías de Herbert List con imágenes de Grecia a finales de la década de 1930, de Munich tras los bombardeos de la Segunda Guerra Mundial, de Italia en la década de 1950 y de hombres jóvenes retratados entre 1930 a 1960 y, junto a ellas, otras tantas piezas arqueológicas que permiten evidenciar la influencia del clasicismo grecorromano en la obra del genial fotógrafo alemán.",
      "artworks": [
        "T_1141",
        "T_1142",
        "T_1143",
        "T_1144",
        "T_1145",
        "T_1146"
      ],
      "custom": true
    },
    {
      "name": "STANDARTE 2023",
      "year": "2023",
      "date": "21 - 26 FEBRERO",
      "location": "Fundación Diario Madrid c/ Larra 14, 28004 Madrid",
      "imgPath": "assets/img/exhibitions/standarte2023/standarte2023.jpg",
      "imgPathSquared": "assets/img/exhibitions/standarte2023/standarte2023-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://feriastandarte.com/",
      "description": "Standarte es una feria de arte moderno y contemporáneo que abrirá sus puertas por primera vez en febrero de 2023. Un selecto grupo de galerías españolas se darán cita en un edificio histórico y emblemático como es la Fundación Diario de Madrid, situado en la calle de Larra 14, para mostrar al público piezas de arte moderno y contemporáneo de primer nivel. \n \n Más de 200 obras originales de célebres artistas como Miquel Barceló, Salvador Dalí, Joan Miró, Jaume Plensa, Pablo Picasso, Antonio Saura, Antoni Tàpies, Manolo Valdés entre otros, todas ellas documentadas y catalogadas. \n \n En nuestro stand podrán verse un selecto pequeño grupo de piezas como un torso militar a tamaño natural del Imperio Romano en mármol, un relieve con una escena nilótica de una mastaba del Antiguo Egipto o un casco en bronce de un guerrero de la Antigua Grecia.",
      "artworks": [
        "T_837",
        "T_788",
        "T_865",
        "T_866",
        "T_863",
        "T_867"
      ]
    },
    {
      "name": "FERIARTE 2022",
      "year": "2022",
      "date": "12 - 20 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2022/feriarte2022.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2022/feriarte2022-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente. En nuestro stand podrán verse más de trescientas obras arqueológicas, desde pequeñas figuras votivas de deidades hasta monumentales esculturas de las principales culturas del Mediterráneo así como Prehispánicas. Destacará un grupo escultórico en mármol romano así como un retrato de El-Fayum y un estandarte Signa Equitum ibérico.",
      "artworks": [
        "T_770",
        "T_732",
        "T_736",
        "T_743",
        "T_750",
        "T_751",
        "T_754",
        "T_766",
        "T_769"
      ]
    },
    {
      "name": "SAM 2021",
      "year": "2021",
      "date": "6 - 11 JULIO",
      "location": "c/ Velázquez 12, 28001 Madrid",
      "imgPath": "assets/img/exhibitions/sam2021/sam2021.jpeg",
      "imgPathSquared": "assets/img/exhibitions/sam2021/sam2021-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/sam2021/sam2021-image-1.jpg",
        "assets/img/exhibitions/sam2021/sam2021-image-2.jpg",
        "assets/img/exhibitions/sam2021/sam2021-image-3.jpg",
        "assets/img/exhibitions/sam2021/sam2021-image-4.jpg",
        "assets/img/exhibitions/sam2021/sam2021-image-5.jpg"
      ],
      "url": "https://www.feriasam.com/",
      "description": "SALÓN DE ARTE MODERNO \n\n SAM es la única feria europea dedicada al arte moderno, cuenta con catorce galerías exponiendo obras de grandes maestros como Miró, Barceló, Plensa, Picasso, Dalí o Warhol junto al contrapunto del arte clásico antiguo, con representaciones de las culturas griega, romana, egipcia y mesopotámica. \n\n En nuestro stand podrán verse un selecto pequeño grupo de piezas como tres torsos romanos, entre ellos uno perteneciente a una figura completa de Antínoo, el amante del emperador Adriano, destacando un conjunto finamente esculpido y decorado de cuatro vasos canopos egipcios, utilizados para contener las vísceras del difunto.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2021",
      "year": "2021",
      "date": "13 - 21 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2021/feriarte2021.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2021/feriarte2021-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-1.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-2.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-3.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-4.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-5.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-6.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-7.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-8.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-9.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-10.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-11.jpg"
      ],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente. ",
      "artworks": []
    },
    {
      "name": "PARCOURS DES MONDES 2020",
      "year": "2020",
      "date": "8 - 13 SEPTIEMBRE",
      "location": "Saint-Germain-des-Prés - 41, rue de Seine - PARIS",
      "imgPath": "assets/img/exhibitions/parcours-des-mondes2020/parcours-des-mondes2020.jpg",
      "imgPathSquared": "assets/img/exhibitions/parcours-des-mondes2020/parcours-des-mondes2020-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/parcours-des-mondes2020/parcours-des-mondes2020-image-1.jpg",
        "assets/img/exhibitions/parcours-des-mondes2020/parcours-des-mondes2020-image-2.jpg",
        "assets/img/exhibitions/parcours-des-mondes2020/parcours-des-mondes2020-image-3.jpg"
      ],
      "url": "https://www.parcours-des-mondes.com/",
      "description": "International Fair TRIBAL ART | ASIAN ART | ARCHAEOLOGY \n\n En el distrito de Saint Germain de París se celebra la feria de arte extra europeo PARCOURS DES MONDES, posiblemente el evento más importante del mundo del sector, dado que acuden las más prestigiosas galerías. Se trata de una feria “en la calle”, en que las típicas galerías de arte del barrio retirar sus obras y se establecen por unos días las galerías de arte africano, asiático y de arte antiguo del sector. \n\n En nuestra exposición podrá verse un retrato de un ciudadano privado romano a tamaño natural en bronce, una pieza extraordinaria dada conservación y tamaño, junto a un dibujo de Picasso así como una escultura monumental de un habitante del Nilo en época de los faraones de las grandes pirámides.",
      "artworks": []
    },
    {
      "name": "PARCOURS DES MONDES 2019",
      "year": "2019",
      "date": "10 - 15 SEPTIEMBRE",
      "location": "Saint-Germain-des-Prés - 41, rue de Seine - PARIS",
      "imgPath": "assets/img/exhibitions/parcours-des-mondes2019/parcours-des-mondes2019.jpg",
      "imgPathSquared": "assets/img/exhibitions/parcours-des-mondes2019/parcours-des-mondes2019-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/parcours-des-mondes2019/parcours-des-mondes2019-image-1.jpg",
        "assets/img/exhibitions/parcours-des-mondes2019/parcours-des-mondes2019-image-2.jpg",
        "assets/img/exhibitions/parcours-des-mondes2019/parcours-des-mondes2019-image-3.jpg",
        "assets/img/exhibitions/parcours-des-mondes2019/parcours-des-mondes2019-image-4.jpg"
      ],
      "url": "https://www.parcours-des-mondes.com/",
      "description": "International Fair TRIBAL ART | ASIAN ART | ARCHAEOLOGY \n\n En el distrito de Saint Germain de París se celebra la feria de arte extra europeo PARCOURS DESMONDES, posiblemente el evento más importante del mundo del sector, dado que acuden las más prestigiosas galerías. Se trata de una feria “en la calle”, en que las típicas galerías de arte del barrio retirar sus obras y se establecen por unos días las galerías de arte africano, asiático y de arte antiguo del sector. \n\n Nuestra exposición se plantea como una galería propiamentede arte antiguo o arqueología, con más de sesenta obras de las más importantes culturas del Mediterráneo así como del Oriente Medio.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2019",
      "year": "2019",
      "date": "12 - 20 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2019/feriarte2019.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2019/feriarte2019-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/feriarte2019/feriarte2019-image-1.jpg",
        "assets/img/exhibitions/feriarte2019/feriarte2019-image-2.jpg",
        "assets/img/exhibitions/feriarte2019/feriarte2019-image-3.jpg",
        "assets/img/exhibitions/feriarte2019/feriarte2019-image-4.jpg",
        "assets/img/exhibitions/feriarte2019/feriarte2019-image-5.jpg",
        "assets/img/exhibitions/feriarte2019/feriarte2019-image-6.jpg"
      ],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio paraque coleccionistas tengan una perspectiva contemporánea única sobre milesde años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente.",
      "artworks": []
    },
    {
      "name": "CULTURES 2019",
      "year": "2019",
      "date": "12 - 16 JUNIO",
      "location": "Grand Sablon, Rue Watteu 12 - Bruselas",
      "imgPath": "assets/img/exhibitions/cultures2019/cultures2019.jpg",
      "imgPathSquared": "assets/img/exhibitions/cultures2019/cultures2019-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://bruneaf.com/en",
      "description": "El centro neurálgico del arte y las antigüedades en la ciudad de Bruselas está situado en la plaza del Grand Sablon y en sus calles adyacentes. Se trata de una feria “en la calle”, en que las típicas galerías de arte del barrio retiran sus obras y se establecen por unos días las galerías de arte africano, asiático y de arte antiguo del sector. \n\n Nuestra exposición se plantea como una galería propiamente de arte antiguo o arqueología, con más de cincuenta obras de las más importantes culturas del Mediterráneo así como del Oriente Medio.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2018",
      "year": "2018",
      "date": "16 - 25 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2018/feriarte2018.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2018/feriarte2018-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/feriarte2018/feriarte2018-image-2.jpg",
        "assets/img/exhibitions/feriarte2018/feriarte2018-image-3.jpg",
        "assets/img/exhibitions/feriarte2018/feriarte2018-image-4.jpg",
        "assets/img/exhibitions/feriarte2018/feriarte2018-image-5.jpg",
        "assets/img/exhibitions/feriarte2018/feriarte2018-image-6.jpg"
      ],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente.",
      "artworks": []
    },
    {
      "name": "BRAFA 2018",
      "year": "2018",
      "date": "2 - 20 NOVIEMBRE",
      "location": "Brussels Antiques & Fine Arts Fair",
      "imgPath": "assets/img/exhibitions/brafa2018/brafa2018.jpg",
      "imgPathSquared": "assets/img/exhibitions/brafa2018/brafa2018-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.brafa.art/en",
      "description": "Brafa Art Fair es la feria por excelencia de Bélgica, siendo una de las más prestigiosas de Europa. Desde sus orígenes, en 1956, esta feria se ha situado como una de las más representativas del arte, el diseño y la arqueología, haciéndose eco de los cambios dela sociedad que hemos vivido en estos últimos 60 años. Presenta a más de 130 expositores, incluyendo arte contemporáneo, tribal y el cómic, muy asentado en este país. Todas las obras expuestas a la venta tienen que ser refrendadas por un comité de serios expertos que certifica la calidad y la autenticidad de las piezas. \n\n Para esta ocasión presentamos la exposición AISTHETIKE, una declaración de intenciones, una selección muy curada de sesenta piezas escogidas por su belleza estética y su calidad de ejecución. Destacando un importante vaso cerámico de temática erótica, atribuido al Wedding Painter, con una de las pocas representaciones de una pornai.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2017",
      "year": "2017",
      "date": "18 - 26 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2017/feriarte2017.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2017/feriarte2017-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la únicaferia de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como losmejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente.",
      "artworks": []
    },
    {
      "name": "BRAFA 2017",
      "year": "2017",
      "date": "21 - 29 ENERO",
      "location": "Brussels Antiques & Fine Arts Fair",
      "imgPath": "assets/img/exhibitions/brafa2017/brafa2017.jpg",
      "imgPathSquared": "assets/img/exhibitions/brafa2017/brafa2017-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/brafa2017/brafa2017-image-1.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-2.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-3.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-4.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-5.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-6.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-7.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-8.jpg"
      ],
      "url": "https://www.brafa.art/en",
      "description": "Brafa Art Fair es la feria por excelencia de Bélgica, siendo una de las más prestigiosas de Europa. Desde sus orígenes, en 1956, esta feria se ha situado como una delas más representativas del arte, el diseño y la arqueología, haciéndose eco de los cambios dela sociedad que hemos vivido en estos últimos 60 años. Presenta a más de 130 expositores, incluyendo arte contemporáneo, tribal y el cómic, muy asentado en este país. Todas las obras expuestas a la venta tienen que ser refrendadas por un comité de serios expertos que certifica la calidad y la autenticidad de las piezas. \n\n Para esta ocasión presentamos la exposición LORD OF TRUTH, uno de los títulos del dios Egipto Ptah, del cual ofrecemos una extremadamente fina escultura en bronce con una de las más altas calidades vistas. Junto a un poético e enigmático busto de una dama romana en mármol, procedente, posiblemente, de un naufragio, con pérdidas y erosión de la superficie producida por el agua del mar.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2016",
      "year": "2016",
      "date": "19 - 27 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2016/feriarte2016.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2016/feriarte2016-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-1.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-2.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-3.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-4.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-5.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-6.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-7.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-8.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-9.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-10.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-11.jpg"
      ],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la únicaferia de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obrasdesde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente",
      "artworks": []
    },
    {
      "name": "BRAFA 2016",
      "year": "2016",
      "date": "23 - 31 ENERO",
      "location": "Brussels Antiques & Fine Arts Fair",
      "imgPath": "assets/img/exhibitions/brafa2016/brafa2016.jpg",
      "imgPathSquared": "assets/img/exhibitions/brafa2016/brafa2016-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/brafa2016/brafa2016-image-1.jpg",
        "assets/img/exhibitions/brafa2016/brafa2016-image-2.jpg",
        "assets/img/exhibitions/brafa2016/brafa2016-image-3.jpg",
        "assets/img/exhibitions/brafa2016/brafa2016-image-4.jpg",
        "assets/img/exhibitions/brafa2016/brafa2016-image-5.jpg"
      ],
      "url": "https://www.brafa.art/en",
      "description": "Brafa Art Fair es la feria por excelencia de Bélgica, siendo una de las más prestigiosas de Europa.  Desde sus orígenes, en 1956, esta feria se ha situado como una de las más representativas del arte, el diseño y la arqueología, haciéndose eco de los cambios de la sociedad que hemos vivido en estos últimos 60 años. Presenta a más de 130 expositores, incluyendo arte contemporáneo, tribal y el cómic, muy asentado en este país. Todas las obras expuestas a la venta tienen que ser refrendadas por un comité de serios expertos que certifica la calidad y la autenticidad de las piezas. \n\n Para esta ocasión presentamos la exposición IMAGENES PRAETERITI centrada en rostros de la antigüedad, ya sea en cabezas o retratos de esculturas de héroes, dioses y diosas o emperadores, junto con piezas en las que predomina la imagen de un rostro, como relieves de portadores de ofrendas, joyas con retratos, vasos cerámicos griegos o máscaras doradas de momia.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2015",
      "year": "2015",
      "date": "21 - 29 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2015/feriarte2015.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2015/feriarte2015-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-1.jpg",
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-2.jpg",
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-3.jpg",
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-4.jpg",
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-5.jpg",
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-6.jpg",
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-7.jpg"
      ],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente.",
      "artworks": []
    },
    {
      "name": "FAMA 2015",
      "year": "2015",
      "date": "4 - 8 MARZO",
      "location": "Reials Drassanes - Barcelona",
      "imgPath": "assets/img/exhibitions/fama2015/fama2015.jpg",
      "imgPathSquared": "assets/img/exhibitions/fama2015/fama2015-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/fama2015/fama2015-image-1.jpg",
        "assets/img/exhibitions/fama2015/fama2015-image-2.jpg",
        "assets/img/exhibitions/fama2015/fama2015-image-3.jpg",
        "assets/img/exhibitions/fama2015/fama2015-image-4.jpg",
        "assets/img/exhibitions/fama2015/fama2015-image-5.jpg",
        "assets/img/exhibitions/fama2015/fama2015-image-6.jpg"
      ],
      "url": "https://famabarcelona.com/es/",
      "description": "FAMA son las iniciales que dan nombre a la nueva Feria de Arte Moderno y Antiguo de Barcelona. Acoge más de 5.000 años de historia a través de las antigüedades que presentarán los cerca de treinta expositores de toda España, dinamizando el mercado del arte y haciendo una difusión del gran patrimonio cultural que se esconde en las galerías de todo el país. \n\n Se ha realizado un diseño del stand bastante peculiar, rindiendo homenaje al emblemático edificio que acoge el salón, entre el color azul del fondo y las paredes curvas, se ha querido plasmar la idea del mar y su elemento, el agua, vehículo comunicador entre culturas, que están representadas por una fina selección de obras escogidas especialmente por su belleza estética.",
      "artworks": []
    },
    {
      "name": "BRAFA 2015",
      "year": "2015",
      "date": "24 ENERO - 1 FEBRERO",
      "location": "Brussels Antiques & Fine Arts Fair",
      "imgPath": "assets/img/exhibitions/brafa2015/brafa2015.jpg",
      "imgPathSquared": "assets/img/exhibitions/brafa2015/brafa2015-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/brafa2015/brafa2015-image-1.jpg",
        "assets/img/exhibitions/brafa2015/brafa2015-image-2.jpg",
        "assets/img/exhibitions/brafa2015/brafa2015-image-3.jpg"
      ],
      "url": "https://www.brafa.art/en",
      "description": "Brafa Art Fair es la feria por excelencia de Bélgica, siendo una de las más prestigiosas de Europa. Desde sus orígenes, en 1956, esta feria se ha situado como una de las más representativas del arte, el diseño y la arqueología, haciéndose eco de los cambios de la sociedad que hemos vivido en estos últimos 60 años. Presenta a más de 130 expositores, incluyendo arte contemporáneo, tribal y el cómic, muy asentado en este país. Todas las obras expuestas a la venta tienen que ser refrendadas por un comité de serios expertos que certifica la calidad y la autenticidad de las piezas.",
      "artworks": []
    },
    {
      "name": "BAS 2015",
      "year": "2015",
      "date": "24 - 27 SEPTIEMBRE",
      "location": "Galerie Harmakhis, Grand Sablon - Bruselas",
      "imgPath": "assets/img/exhibitions/bas2015/bas2015.jpg",
      "imgPathSquared": "assets/img/exhibitions/bas2015/bas2015-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/bas2015/bas2015-image-1.jpg",
        "assets/img/exhibitions/bas2015/bas2015-image-2.jpg",
        "assets/img/exhibitions/bas2015/bas2015-image-3.jpg"
      ],
      "url": null,
      "description": "BRUSSELS ART SQUARE nace de la mano de unos anticuarios del Grand Sablon, con la idea de invitar para exponer conjuntamente en sus galerías a los más reputados anticuarios de un mismo país a participar cada año. En el 2015 el país invitado es España, y la galería Harmakhis, la más importante en arte egipcio, nos hace de anfitrión. \n\n Una pintura mural al fresco de una tumba egipcia del Imperio Medio, un retrato del emperador romano Calígula, un cartonaje dorado de momia o un trapezóforo decorado con el rostro de un león son las piezas más destacadas que presentamos.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2014",
      "year": "2014",
      "date": "15 - 23 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2014/feriarte2014.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2014/feriarte2014-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-1.jpg",
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-2.jpg",
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-3.jpg",
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-4.jpg",
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-5.jpg",
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-6.jpg",
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-7.jpg"
      ],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente.",
      "artworks": []
    },
    {
      "name": "BRAFA 2014",
      "year": "2014",
      "date": "25 ENERO - 2 FEBRERO",
      "location": "Brussels Antiques & Fine Arts Fair",
      "imgPath": "assets/img/exhibitions/brafa2014/brafa2014.jpg",
      "imgPathSquared": "assets/img/exhibitions/brafa2014/brafa2014-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/brafa2014/brafa2014-image-1.jpg",
        "assets/img/exhibitions/brafa2014/brafa2014-image-2.jpg",
        "assets/img/exhibitions/brafa2014/brafa2014-image-3.jpg",
        "assets/img/exhibitions/brafa2014/brafa2014-image-5.jpg",
        "assets/img/exhibitions/brafa2014/brafa2014-image-6.jpg",
        "assets/img/exhibitions/brafa2014/brafa2014-image-7.jpg"
      ],
      "url": "https://www.brafa.art/en",
      "description": "Brafa Art Fair es la feria por excelencia de Bélgica, siendo una de las más prestigiosas de Europa. Desde sus orígenes, en 1956, esta feria se ha situado como una de las más representativas del arte, el diseño y la arqueología, haciéndose eco de los cambios de la sociedad que hemos vivido en estos últimos 60 años. Presenta a más de 130 expositores, incluyendo arte contemporáneo, tribal y el cómic, muy asentado en este país. Todas las obras expuestas a la venta tienen que ser refrendadas por un comité de serios expertos que certifica la calidad y la autenticidad de las piezas.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2013",
      "year": "2013",
      "date": "16 - 24 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2013/feriarte2013.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2013/feriarte2013-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente.",
      "artworks": []
    },
    {
      "name": "BRUENAF 2013",
      "year": "2013",
      "date": "5 - 9 JUNIO",
      "location": "Grand Sablon, Galerie Costermans - Bruselas",
      "imgPath": "assets/img/exhibitions/bruneaf2013/bruneaf2013.jpg",
      "imgPathSquared": "assets/img/exhibitions/bruneaf2013/bruneaf2013-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://bruneaf.com/en",
      "description": "El centro neurálgico del arte y las antigüedades en la ciudad de Bruselas está situado en la plaza del Grand Sablon y en sus calles adyacentes. Se trata de una feria “en la calle”, en que las típicas galerías de arte del barrio retiran sus obras y se establecen por unos días las galerías de arte africano, asiático y de arte antiguo del sector. \n\n Nuestra exposición se plantea como una galería propiamente de arte antiguo o arqueología, con más de cincuenta obras de las más importantes culturas del Mediterráneo así como del Oriente Medio. Destaca un Ánfora del Grupo de Leagros, realizada hacia el 510 – 500 a.C., por un pintor ático, procedente del Comte Alberic del Chastel de la Howarderie de Bélgica entre 1842 y 1919.",
      "artworks": []
    },
    {
      "name": "BRAFA 2013",
      "year": "2013",
      "date": "19 - 27 ENERO",
      "location": "Brussels Antiques & Fine Arts Fair",
      "imgPath": "assets/img/exhibitions/brafa2013/brafa2013.jpg",
      "imgPathSquared": "assets/img/exhibitions/brafa2013/brafa2013-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/brafa2013/brafa2013-image-1.jpg",
        "assets/img/exhibitions/brafa2013/brafa2013-image-2.jpg",
        "assets/img/exhibitions/brafa2013/brafa2013-image-3.jpg",
        "assets/img/exhibitions/brafa2013/brafa2013-image-4.jpg",
        "assets/img/exhibitions/brafa2013/brafa2013-image-5.jpg"
      ],
      "url": "https://www.brafa.art/en",
      "description": "Brafa Art Fair es la feria por excelencia de Bélgica, siendo una de las más prestigiosas de Europa. Desde sus orígenes, en 1956, esta feria se ha situado como una de las más representativas del arte, el diseño y la arqueología, haciéndose eco de los cambios de la sociedad que hemos vivido en estos últimos 60 años. Presenta a más de 130 expositores, incluyendo arte contemporáneo, tribal y el cómic, muy asentado en este país. Todas las obras expuestas a la venta tienen que ser refrendadas por un comité de serios expertos que certifica la calidad y la autenticidad de las piezas. \n\n Son pocas las galerías españolas seleccionadas cada año para su participación. Para nuestra primera edición, y a modo de presentación al público de la feria, puesto que el coleccionista belga ya es conocido por nosotros a través de otras ferias que realizamos en verano en su capital, una selección de aquellas obras más particulares y de calidad que ofrecemos en la galería de Barcelona y otras reservadas cautelosamente para la exposición.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2012",
      "year": "2012",
      "date": "17 - 25 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2012/feriarte2012.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2012/feriarte2012-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente.",
      "artworks": []
    },
    {
      "name": "ARS ANTIQUA BRUSSELS 2012",
      "year": "2012",
      "date": "18 - 22 ENERO",
      "location": "Grand Sablon - Bruselas",
      "imgPath": "assets/img/exhibitions/ars2012/ars2012.jpg",
      "imgPathSquared": "assets/img/exhibitions/ars2012/ars2012-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "El centro neurálgico del arte y las antigüedades en la ciudad de Bruselas está situado en la plaza del Grand Sablon y en sus calles adyacentes. Se trata de una feria “en la calle”, en que las típicas galerías de arte del barrio retiran sus obras y se establecen por unos días las galerías de arte africano, asiático y de arte antiguo del sector. \n\n Nuestra exposición se plantea como una galería propiamente de arte antiguo o arqueología, con más de cincuenta obras de las más importantes culturas del Mediterráneo así como del Oriente Medio.",
      "artworks": []
    },
    {
      "name": "ANTIQUARIS 2012",
      "year": "2012",
      "date": "24 MARZO - 1 ABRIL",
      "location": "Recinte Firal Montjuïc - Barcelona",
      "imgPath": "assets/img/exhibitions/antiquaris2012/antiquaris2012.jpg",
      "imgPathSquared": "assets/img/exhibitions/antiquaris2012/antiquaris2012-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "ANTIQUARIS BARCELONA es una de las ferias decanas de Fira de Barcelona, donde galeristas, coleccionistas y amantes del arte y las antigüedades se dan cita anualmente. Las piezas de arte moderno, déco, nouveau o contemporáneo comparten protagonismo en este salón con la alta joyería, la porcelana y la cerámica europea o los marfiles, entre otros objetos de gran valor histórico y artístico. \n\n Formamos parte del comité organizador y nuestra obra principal es un importante relieve en esquisto de perfecta talla de una estupa de la cultura Gandhara.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2011",
      "year": "2011",
      "date": "12 - 20 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2011/feriarte2011.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2011/feriarte2011-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente. En nuestro stand podrán verse más de trescientas obras arqueológicas, desde pequeñas figuras votivas de deidades hasta monumentales esculturas de las principales culturas del Mediterráneo así como Prehispánicas. Destacará un grupo escultórico en mármol romano así como un retrato de El-Fayum y un estandarte Signa Equitum ibérico.",
      "artworks": []
    },
    {
      "name": "ARS ANTIQUA BRUSSELS 2011",
      "year": "2011",
      "date": "19 - 23 ENERO",
      "location": "Grand Sablon - Bruselas",
      "imgPath": "assets/img/exhibitions/ars2011/ars2011.jpg",
      "imgPathSquared": "assets/img/exhibitions/ars2011/ars2011-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "El centro neurálgico del arte y las antigüedades en la ciudad de Bruselas está situado en la plaza del Grand Sablon y en sus calles adyacentes. Se trata de una feria “en la calle”, en que las típicas galerías de arte del barrio retiran sus obras y se establecen por unos días las galerías de arte africano, asiático y de arte antiguo del sector. \n\n Nuestra exposición se plantea como una galería propiamente de arte antiguo o arqueología, con más de cincuenta obras de las más importantes culturas del Mediterráneo así como del Oriente Medio.",
      "artworks": []
    },
    {
      "name": "ANTIQUARIS 2011",
      "year": "2011",
      "date": "12 - 20 MARZO",
      "location": "Recinte Firal Montjuïc - Barcelona",
      "imgPath": "assets/img/exhibitions/antiquaris2011/antiquaris2011.jpg",
      "imgPathSquared": "assets/img/exhibitions/antiquaris2011/antiquaris2011-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "ANTIQUARIS BARCELONA es una de las ferias decanas de Fira de Barcelona, donde galeristas, coleccionistas y amantes del arte y las antigüedades se dan cita anualmente. Las piezas de arte moderno, déco, nouveau o contemporáneo comparten protagonismo en este salón con la alta joyería, la porcelana y la cerámica europea o los marfiles, entre otros objetos de gran valor histórico y artístico. \n\n La pieza más destacada será una figura de un ídolo de las Islas Cícladas representando a una mujer embarazada, tallada en mármol hacia el 2700 – 2400 a.C. La fama de este tipo de esculturas deriva de la simpleza de formas puras, son piezas icónicas admiradas por los máximos exponentes del arte contemporáneo, llegando a influenciar en sus obras, como en el cubismo de Picasso.",
      "artworks": []
    },
    {
      "name": "SIART 2010",
      "year": "2010",
      "date": "6 - 14 FEBRERO",
      "location": "Palau de Congressos de Catalunya - Barcelona",
      "imgPath": "assets/img/exhibitions/siart2010/siart2010.jpg",
      "imgPathSquared": "assets/img/exhibitions/siart2010/siart2010-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "El salón de Arte y Tendencias Artísticas SIART nace con el fin de fusionar el arte de vanguardia y el histórico y convertirse en agitador de la industria cultural. La primera edición de esta nueva feria arrancará con obras de figuras claves de las tendencias más actuales del mundo artístico, como Damien Hirst, Zhang Huan, Barceló, Plensa, Botero, Manolo Valdés y Pablo Palazuelo, entre otros, que compartirán espacio con autores de la talla de Picasso, Dalí, Miró, Nonell, Sorolla, Casas, Rusiñol o Chillida. Todas ellos rodeados de objetos antiguos e históricos. Con esta iniciativa se quiere también dar una respuesta a la industria cultural a través de conceptos estéticos. \n\n En nuestro espacio, siguiendo la línea de la galería, destacará una bella togada femenina con unos paños muy marcados, realizada en mármol durante el siglo II d.C., por un taller de la antigua Roma.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2010",
      "year": "2010",
      "date": "20 - 28 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2010/feriarte2010.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2010/feriarte2010-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente.",
      "artworks": []
    },
    {
      "name": "ARTEMANÍA 2010",
      "year": "2010",
      "date": "20 - 28 MARZO",
      "location": "Palacio de Congresos - Madrid",
      "imgPath": "assets/img/exhibitions/artemania2010/artemania2010.jpg",
      "imgPathSquared": "assets/img/exhibitions/artemania2010/artemania2010-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "En pleno Paseo de Castellana se celebra la feria de arte y coleccionismo de antigüedades más céntrica de la capital.",
      "artworks": []
    },
    {
      "name": "ANTIQUARIS 2010",
      "year": "2010",
      "date": "16 - 24 OCTUBRE",
      "location": "Recinte Firal Montjuïc - Barcelona",
      "imgPath": "assets/img/exhibitions/antiquaris2010/antiquaris2010.jpg",
      "imgPathSquared": "assets/img/exhibitions/antiquaris2010/antiquaris2010-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "ANTIQUARIS BARCELONA es una de las ferias decanas de Fira de Barcelona, donde galeristas, coleccionistas y amantes del arte y las antigüedades se dan cita anualmente. Las piezas de arte moderno, déco, nouveau o contemporáneo comparten protagonismo en este salón con la alta joyería, la porcelana y la cerámica europea o los marfiles, entre otros objetos de gran valor histórico y artístico. \n\n Formamos parte del comité organizador y nuestra obra principal es una importante escultura monumental en esquisto de perfecta talla de la cultura Gandhara. ",
      "artworks": []
    },
    {
      "name": "FERIARTE 2009",
      "year": "2009",
      "date": "14 - 22 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2009/feriarte2009.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2009/feriarte2009-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente. ",
      "artworks": []
    },
    {
      "name": "ATARAZANAS 2009",
      "year": "2009",
      "date": "17 - 25 OCTUBRE",
      "location": "Reials Drassanes - Barcelona",
      "imgPath": "assets/img/exhibitions/atarazanas2009/atarazanas2009.jpg",
      "imgPathSquared": "assets/img/exhibitions/atarazanas2009/atarazanas2009-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "Un fascinante recorrido por la historia, la cultura, el arte y las óptimas inversiones con 10.500 obras de todos los siglos, estilos y precios. Piezas de 6.000 años, la impactante colección de la tribu Kota africana, Pop Art o vanguardistas diseños de Pierre Cardin convivirán con creaciones de Picasso, Dalí, Warhol, Miró, Tàpies, Barceló, Plensa, Rusiñol, Casas, Chillida o Botero. \n\n Los 2.000 metros cuadrados habilitados en el marco de las naves góticas de la Sala Marqués de Comillas, joya del arte civil medieval catalán y sello inconfundible del Salón, vuelven a concentrar impactantes piezas y obras de todas las tendencias artísticas y diferentes segmentos de precios en un acontecimiento ineludible para profesionales y público apasionado por las obras de arte clásicas y contemporáneas o la arqueología griega, romana, egipcia y asiática.",
      "artworks": []
    },
    {
      "name": "ARTEMANÍA 2009",
      "year": "2009",
      "date": "28 FEBRERO - 8 MARZO",
      "location": "Palacio de Congresos - Madrid",
      "imgPath": "assets/img/exhibitions/artemania2009/artemania2009.jpg",
      "imgPathSquared": "assets/img/exhibitions/artemania2009/artemania2009-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "En pleno Paseo de Castellana se celebra la feria de arte y coleccionismo de antigüedades más céntrica de la capital.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2008",
      "year": "2008",
      "date": "15 - 23 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2008/feriarte2008.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2008/feriarte2008-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, con más de cuatro décadas de trayectoria, representa la única feria de arte y antigüedades en España y el espacio para que coleccionistas tengan una perspectiva contemporánea única sobre miles de años de historia del arte. Las mejores obras desde la antigüedad clásica y las culturas milenarias hasta el diseño del siglo XX, así como los mejores maestros antiguos hasta los más contemporáneos reconocidos internacionalmente. ",
      "artworks": []
    },
    {
      "name": "ATARAZANAS 2008",
      "year": "2008",
      "date": "18 - 26 OCTUBRE",
      "location": "Reials Drassanes - Barcelona",
      "imgPath": "assets/img/exhibitions/atarazanas2008/atarazanas2008.jpg",
      "imgPathSquared": "assets/img/exhibitions/atarazanas2008/atarazanas2008-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "El fascinante recorrido por la historia y el arte que propone esta cita de referencia incluye trabajos de Picasso, Dalí, Miró, Tàpies, Botero, Barceló, Plensa, Rusiñol, Chillida, Casas o el británico Damien Hirst, el “nuevo Rey Midas” del arte y uno de los artistas vivos más transgresores. Los 2.000 metros cuadrados habilitados en el marco de las naves góticas de la Sala Marqués de Comillas, una de las joyas del arte civil medieval catalán y sello inconfundible del Salón, volverán a concentrar piezas y creaciones de todos los siglos y precios para profesionales y un público apasionado por el mundo de las antigüedades, las obras de arte históricas y la arqueología. \n\n En un recorrido con ‘stands’ de incomparable calidad y espectacularidad, se encontrará desde un impresionante sarcófago egipcio de 2.600 años de antigüedad hasta una singular selección de joyas francesas Belle Époque y Art Déco; trabajos y litografías del 2007 del polémico Damien Hrist o valiosas esculturas realizadas en tiempos del Imperio Romano.",
      "artworks": []
    },
    {
      "name": "ANTIQUARIS 2008",
      "year": "2008",
      "date": "29 MARZO - 6 ABRIL",
      "location": "Recinte Firal Montjuïc - Barcelona",
      "imgPath": "assets/img/exhibitions/antiquaris2008/antiquaris2008.jpg",
      "imgPathSquared": "assets/img/exhibitions/antiquaris2008/antiquaris2008-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "ANTIQUARIS BARCELONA es una de las ferias decanas de Fira de Barcelona, donde galeristas, coleccionistas y amantes del arte y las antigüedades se dan cita anualmente. Las piezas de arte moderno, déco, nouveau o contemporáneo comparten protagonismo en este salón con la alta joyería, la porcelana y la cerámica europea o los marfiles, entre otros objetos de gran valor histórico y artístico.",
      "artworks": []
    }
  ],
  "en": [
    {
      "name": "STANDARTE 2024 \n FERIA ARTE MODERNO",
      "year": "2024",
      "date": "MARCH 5 - 10",
      "location": "Palacio de Linares - Casa de América \nc/ Marqués del Duero 2, Madrid",
      "imgPath": "assets/img/exhibitions/standarte2024/standarte2024.jpg",
      "imgPathSquared": "assets/img/exhibitions/standarte2024/standarte2024-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://feriastandarte.com",
      "videoPath": "assets/img/exhibitions/standarte2024/standarte2024-video.mp4",
      "description": "Standarte is a modern and contemporary art fair that will open its doors for the second time and in a new location, at Casa de América, in the Linares Palace, in the center of the capital. A select group of Spanish galleries will meet in this new location to show the public pieces of top-level modern and contemporary art. \n \n More than 200 original works by famous artists such as Miquel Barceló, Salvador Dalí, Joan Miró, Jaume Plensa, Pablo Picasso, Antonio Saura, Antoni Tàpies, Manolo Valdés among others, all of them documented and catalogued. \n \n At our stand you can see two worlds united, masterpieces of painting such as Joan Miró and Joaquín Sorolla, dialoguing with works from the main cultures of classical antiquity, torsos of Greek athletes, Roman Venuses, capitals of the Caliphate of Córdoba, along with Egyptian sarcophagi.",
      "artworks": [
        "T_1186",
        "T_1342",
        "T_1113"
      ]
    },
    {
      "name": "FERIARTE 2023",
      "year": "2023",
      "date": "NOVEMBER 11 - 19",
      "location": "Ifema, Fair of Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2023/feriarte2023.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2023/feriarte2023-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and ancient cultures to the design of the twentieth century, as well as the best old masters to the most contemporary internationally recognized. \n\n More than four hundred archaeological works will be on display at our stand, including a monumental female torso from the Roman period; a set of Greek ceramic vases, including a Pixis from the Geometric period from the personal collection of the President of the Republic of France, Valéry Giscard d'Estaing; a superb sarcophagus relief from a Roman sarcophagus; and a set of Greek ceramic vases, including a Pixis from the Geometric period from the personal collection of the President of the Republic of France, Valéry Giscard d'Estaing; an excellent sarcophagus relief with Venus Victrix; an Egyptian sculpture from the time of the pyramids in granodiorite of outstanding dimensions, which survived the bombing of Germany during World War II; and a large group of Iberian art, Celtiberian fibulae, votive offerings, two prehistoric idols and a pair of Hispano-Muslim capitals. A fragment of Paleolithic bone from the Iberian P., with an engraved bison from the Cueva de La Peña de Candamo. All of them under the watchful eye of the Emperor Antoninus Pius, an excellent Roman bust that belonged to Andy Warhol's collection.",
      "artworks": [
        "T_1165",
        "T_1118",
        "T_1164",
        "T_1112",
        "T_774",
        "T_885",
        "T_1092",
        "T_1155"
      ]
    },
    {
      "name": "HERBERT LIST Y EL IDEAL CLÁSICO",
      "year": "2023",
      "date": "SEPTEMBER 21 - NOVEMBER 20, 2023",
      "location": "Galería J.Bagot - C/ del Consell de Cent, 321",
      "imgPath": "assets/img/exhibitions/herbert2023/herbert2023.png",
      "imgPathSquared": "assets/img/exhibitions/herbert2023/herbert2023-squared.png",
      "exhibitionImgPaths": [],
      "url": "https://herbertlistyelidealclasico.com",
      "description": "Exhibition in which some of the most important works of the great German photographer Herbert List (1903 - 1975) are compared with original Greek and Roman archaeological pieces dating from the 6th century BC to the 4th century AD. \n \n The exhibition “Herbert List and the Classical Ideal” has been organised in collaboration with The Herbert List Estate in Hamburg. \n \n The exhibition presents 44 magnificent photographs by Herbert List with images of Greece in the late 1930s, of Munich after the bombings of the Second World War, of Italy in the 1950s and of young men portrayed between 1930 and 1960, as well as archaeological pieces that reveal the influence of Greco-Roman classicism in the work of the brilliant German photographer.",
      "artworks": [
        "T_1141",
        "T_1142",
        "T_1143",
        "T_1144",
        "T_1145",
        "T_1146"
      ],
      "custom": true
    },
    {
      "name": "STANDARTE 2023",
      "year": "2023",
      "date": "FEBRUARY 21 - 26",
      "location": "Fundación Diario Madrid c/ Larra 14, 28004 Madrid",
      "imgPath": "assets/img/exhibitions/standarte2023/standarte2023.jpg",
      "imgPathSquared": "assets/img/exhibitions/standarte2023/standarte2023-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://feriastandarte.com/",
      "description": "Standarte is a modern and contemporary art fair that will open its doors for the first time in February 2023. A select group of Spanish galleries will meet in a historic and emblematic building such as the Fundación Diario de Madrid, located on Calle de Larra 14, to show the public pieces of modern and contemporary art of the first level. \n \n More than 200 original works by famous artists such as Miquel Barceló, Salvador Dalí, Joan Miró, Jaume Plensa, Pablo Picasso, Antonio Saura, Antoni Tàpies, Manolo Valdés among others, all of them documented and catalogued. \n \n At our stand you will be able to see a select small group of pieces such as a life-size military torso from the Roman Empire in marble, a relief with a Nilotic scene from an Ancient Egyptian mastaba or a bronze helmet of a warrior from the Ancient Greece.",
      "artworks": [
        "T_837",
        "T_788",
        "T_865",
        "T_866",
        "T_863",
        "T_867"
      ]
    },
    {
      "name": "FERIARTE 2022",
      "year": "2022",
      "date": "NOVEMBER 12 - 20",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2022/feriarte2022.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2022/feriarte2022-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and ancient cultures to the design of the twentieth century, as well as the best ancient masters to the most contemporary internationally recognized. In our stand you will be able to see more than three hundred archaeological works, from small votive figures of deities to monumental sculptures of the main Mediterranean and Pre-Hispanic cultures. Highlights will include a sculptural group in Roman marble as well as a portrait of El-Fayum and an Iberian Signa Equitum banner",
      "artworks": [
        "T_770",
        "T_732",
        "T_736",
        "T_743",
        "T_750",
        "T_751",
        "T_754",
        "T_766",
        "T_769"
      ]
    },
    {
      "name": "SAM 2021",
      "year": "2021",
      "date": "JULY 6 - 11",
      "location": "c/ Velázquez 12, 28001 Madrid",
      "imgPath": "assets/img/exhibitions/sam2021/sam2021.jpeg",
      "imgPathSquared": "assets/img/exhibitions/sam2021/sam2021-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/sam2021/sam2021-image-1.jpg",
        "assets/img/exhibitions/sam2021/sam2021-image-2.jpg",
        "assets/img/exhibitions/sam2021/sam2021-image-3.jpg",
        "assets/img/exhibitions/sam2021/sam2021-image-4.jpg",
        "assets/img/exhibitions/sam2021/sam2021-image-5.jpg"
      ],
      "url": "https://www.feriasam.com/",
      "description": "SALÓN DE ARTE MODERNO \n\n SAM is the only European fair dedicated to modern art, it has fourteen galleries exhibiting works by great masters such as Miró, Barceló, Plensa, Picasso, Dalí or Warhol together with the counterpoint of ancient classical art, with representations of Greek, Roman, Egyptian and Mesopotamian cultures. \n\n At our stand you can see a select small group of pieces such as three Roman torsos, including one belonging to a complete figure of Antinous, the lover of Emperor Hadrian, highlighting a finely sculpted and decorated set of four Egyptian canopic vessels, used to contain the viscera of the deceased.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2021",
      "year": "2021",
      "date": "NOVEMBER 13 - 21",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2021/feriarte2021.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2021/feriarte2021-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-1.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-2.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-3.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-4.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-5.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-6.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-7.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-8.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-9.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-10.jpg",
        "assets/img/exhibitions/feriarte2021/feriarte2021-image-11.jpg"
      ],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and ancient cultures to 20th century design, as well as the best old masters to the most internationally recognized contemporary ones. ",
      "artworks": []
    },
    {
      "name": "PARCOURS DES MONDES 2020",
      "year": "2020",
      "date": "SEPTEMBER 8 - 13",
      "location": "Saint-Germain-des-Prés - 41, rue de Seine - PARIS",
      "imgPath": "assets/img/exhibitions/parcours-des-mondes2020/parcours-des-mondes2020.jpg",
      "imgPathSquared": "assets/img/exhibitions/parcours-des-mondes2020/parcours-des-mondes2020-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/parcours-des-mondes2020/parcours-des-mondes2020-image-1.jpg",
        "assets/img/exhibitions/parcours-des-mondes2020/parcours-des-mondes2020-image-2.jpg",
        "assets/img/exhibitions/parcours-des-mondes2020/parcours-des-mondes2020-image-3.jpg"
      ],
      "url": "https://www.parcours-des-mondes.com/",
      "description": "International Fair TRIBAL ART | ASIAN ART | ARCHAEOLOGY \n\n The extra-European art fair PARCOURS DES MONDES is held in the Saint Germain district of Paris, possibly the most important event in the world in the sector, given that the most prestigious galleries attend. It is a fair \"in the street\", in which the typical art galleries of the neighborhood collect their works and the galleries of African, Asian and ancient art of the sector are established for a few days. \n\n In our exhibition you can see a life-size bronze portrait of a private Roman citizen, an extraordinary piece given its conservation and size, together with a drawing by Picasso as well as a monumental sculpture of an inhabitant of the Nile at the time of the pharaohs of the great pyramids.",
      "artworks": []
    },
    {
      "name": "PARCOURS DES MONDES 2019",
      "year": "2019",
      "date": "SEPTEMBER 10 - 15",
      "location": "Saint-Germain-des-Prés - 41, rue de Seine - PARIS",
      "imgPath": "assets/img/exhibitions/parcours-des-mondes2019/parcours-des-mondes2019.jpg",
      "imgPathSquared": "assets/img/exhibitions/parcours-des-mondes2019/parcours-des-mondes2019-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/parcours-des-mondes2019/parcours-des-mondes2019-image-1.jpg",
        "assets/img/exhibitions/parcours-des-mondes2019/parcours-des-mondes2019-image-2.jpg",
        "assets/img/exhibitions/parcours-des-mondes2019/parcours-des-mondes2019-image-3.jpg",
        "assets/img/exhibitions/parcours-des-mondes2019/parcours-des-mondes2019-image-4.jpg"
      ],
      "url": "https://www.parcours-des-mondes.com/",
      "description": "International Fair TRIBAL ART | ASIAN ART | ARCHAEOLOGY \n\n The extra-European art fair PARCOURS DESMONDES is held in the Saint Germain district of Paris, possibly the most important event in the world in the sector, given that the most prestigious galleries attend. It is a fair \"in the street\", in which the typical art galleries of the neighborhood collect their works and the galleries of African, Asian and ancient art of the sector are established for a few days. \n\n Our exhibition is conceived as a proper ancient art or archeology gallery, with more than sixty works from the most important cultures of the Mediterranean as well as the Middle East.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2019",
      "year": "2019",
      "date": "NOVEMBER 12 - 20",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2019/feriarte2019.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2019/feriarte2019-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/feriarte2019/feriarte2019-image-1.jpg",
        "assets/img/exhibitions/feriarte2019/feriarte2019-image-2.jpg",
        "assets/img/exhibitions/feriarte2019/feriarte2019-image-3.jpg",
        "assets/img/exhibitions/feriarte2019/feriarte2019-image-4.jpg",
        "assets/img/exhibitions/feriarte2019/feriarte2019-image-5.jpg",
        "assets/img/exhibitions/feriarte2019/feriarte2019-image-6.jpg"
      ],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and ancient cultures to 20th century design, as well as the best old masters to the most internationally recognized contemporary ones.",
      "artworks": []
    },
    {
      "name": "CULTURES 2019",
      "year": "2019",
      "date": "JUNE 12 - 16",
      "location": "Grand Sablon, Rue Watteu 12 - Bruselas",
      "imgPath": "assets/img/exhibitions/cultures2019/cultures2019.jpg",
      "imgPathSquared": "assets/img/exhibitions/cultures2019/cultures2019-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://bruneaf.com/en",
      "description": "The nerve center of art and antiques in the city of Brussels is located in the Grand Sablon square and its adjacent streets. It is a fair \"in the street\", in which the typical art galleries of the neighborhood withdraw their works and the galleries of African, Asian and ancient art of the sector are established for a few days. \n\n Our exhibition is conceived as a proper ancient art or archeology gallery, with more than fifty works from the most important cultures of the Mediterranean as well as the Middle East.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2018",
      "year": "2018",
      "date": "NOVEMBER 16 - 25",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2018/feriarte2018.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2018/feriarte2018-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/feriarte2018/feriarte2018-image-2.jpg",
        "assets/img/exhibitions/feriarte2018/feriarte2018-image-3.jpg",
        "assets/img/exhibitions/feriarte2018/feriarte2018-image-4.jpg",
        "assets/img/exhibitions/feriarte2018/feriarte2018-image-5.jpg",
        "assets/img/exhibitions/feriarte2018/feriarte2018-image-6.jpg"
      ],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and ancient cultures to 20th century design, as well as the best old masters to the most internationally recognized contemporary ones.",
      "artworks": []
    },
    {
      "name": "BRAFA 2018",
      "year": "2018",
      "date": "NOVEMBER 2 - 20",
      "location": "Brussels Antiques & Fine Arts Fair",
      "imgPath": "assets/img/exhibitions/brafa2018/brafa2018.jpg",
      "imgPathSquared": "assets/img/exhibitions/brafa2018/brafa2018-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.brafa.art/en",
      "description": "Brafa Art Fair is the quintessential fair in Belgium, being one of the most prestigious in Europe. Since its origins, in 1956, this fair has positioned itself as one of the most representative of art, design and archaeology, echoing the changes in society that we have experienced in the last 60 years. It features more than 130 exhibitors, including contemporary, tribal and comic art, well established in this country. All the works exhibited for sale must be endorsed by a committee of serious experts that certifies the quality and authenticity of the pieces. \n\n For this occasion we present the AISTHETIKE exhibition, a statement of intent, a carefully curated selection of sixty pieces chosen for their aesthetic beauty and quality of execution. Highlighting an important ceramic vessel with an erotic theme, attributed to the Wedding Painter, with one of the few representations of a pornai.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2017",
      "year": "2017",
      "date": "NOVEMBER 18 - 26",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2017/feriarte2017.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2017/feriarte2017-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and millennial cultures to 20th century design, as well as the best old masters to the most internationally recognized contemporary ones.",
      "artworks": []
    },
    {
      "name": "BRAFA 2017",
      "year": "2017",
      "date": "JANUARY 21 - 29",
      "location": "Brussels Antiques & Fine Arts Fair",
      "imgPath": "assets/img/exhibitions/brafa2017/brafa2017.jpg",
      "imgPathSquared": "assets/img/exhibitions/brafa2017/brafa2017-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/brafa2017/brafa2017-image-1.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-2.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-3.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-4.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-5.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-6.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-7.jpg",
        "assets/img/exhibitions/brafa2017/brafa2017-image-8.jpg"
      ],
      "url": "https://www.brafa.art/en",
      "description": "Brafa Art Fair is the quintessential fair in Belgium, being one of the most prestigious in Europe. Since its origins, in 1956, this fair has positioned itself as one of the most representative of art, design and archaeology, echoing the changes in society that we have experienced in the last 60 years. It features more than 130 exhibitors, including contemporary, tribal and comic art, well established in this country. All the works exhibited for sale must be endorsed by a committee of serious experts that certifies the quality and authenticity of the pieces. \n\n For this occasion we present the exhibition LORD OF TRUTH, one of the titles of the Egyptian god Ptah, of which we offer an extremely fine bronze sculpture with one of the highest qualities seen. Next to a poetic and enigmatic marble bust of a Roman lady, possibly from a shipwreck, with losses and surface erosion caused by seawater.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2016",
      "year": "2016",
      "date": "NOVEMBER 19 - 27",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2016/feriarte2016.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2016/feriarte2016-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-1.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-2.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-3.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-4.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-5.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-6.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-7.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-8.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-9.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-10.jpg",
        "assets/img/exhibitions/feriarte2016/feriarte2016-image-11.jpg"
      ],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and millennial cultures to 20th century design, as well as the best old masters to the most internationally recognized contemporary ones.",
      "artworks": []
    },
    {
      "name": "BRAFA 2016",
      "year": "2016",
      "date": "JANUARY 23 - 31",
      "location": "Brussels Antiques & Fine Arts Fair",
      "imgPath": "assets/img/exhibitions/brafa2016/brafa2016.jpg",
      "imgPathSquared": "assets/img/exhibitions/brafa2016/brafa2016-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/brafa2016/brafa2016-image-1.jpg",
        "assets/img/exhibitions/brafa2016/brafa2016-image-2.jpg",
        "assets/img/exhibitions/brafa2016/brafa2016-image-3.jpg",
        "assets/img/exhibitions/brafa2016/brafa2016-image-4.jpg",
        "assets/img/exhibitions/brafa2016/brafa2016-image-5.jpg"
      ],
      "url": "https://www.brafa.art/en",
      "description": "Brafa Art Fair is the quintessential fair in Belgium, being one of the most prestigious in Europe. Since its origins, in 1956, this fair has positioned itself as one of the most representative of art, design and archaeology, echoing the changes in society that we have experienced in the last 60 years. It features more than 130 exhibitors, including contemporary, tribal and comic art, well established in this country. All the works exhibited for sale must be endorsed by a committee of serious experts that certifies the quality and authenticity of the pieces. \n\n For this occasion we present the exhibition IMAGENES PRAETERITI focused on ancient faces, whether in heads or portraits of sculptures of heroes, gods and goddesses or emperors, together with pieces in which the image of a face predominates, such as reliefs of bearers of offerings, jewelry with portraits, Greek ceramic vessels or golden mummy masks.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2015",
      "year": "2015",
      "date": "NOVEMBER 21 - 29",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2015/feriarte2015.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2015/feriarte2015-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-1.jpg",
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-2.jpg",
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-3.jpg",
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-4.jpg",
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-5.jpg",
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-6.jpg",
        "assets/img/exhibitions/feriarte2015/feriarte2015-image-7.jpg"
      ],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and ancient cultures to 20th century design, as well as the best old masters to the most internationally recognized contemporary ones.",
      "artworks": []
    },
    {
      "name": "FAMA 2015",
      "year": "2015",
      "date": "MARCH 4 - 8",
      "location": "Reials Drassanes - Barcelona",
      "imgPath": "assets/img/exhibitions/fama2015/fama2015.jpg",
      "imgPathSquared": "assets/img/exhibitions/fama2015/fama2015-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/fama2015/fama2015-image-1.jpg",
        "assets/img/exhibitions/fama2015/fama2015-image-2.jpg",
        "assets/img/exhibitions/fama2015/fama2015-image-3.jpg",
        "assets/img/exhibitions/fama2015/fama2015-image-4.jpg",
        "assets/img/exhibitions/fama2015/fama2015-image-5.jpg",
        "assets/img/exhibitions/fama2015/fama2015-image-6.jpg"
      ],
      "url": "https://famabarcelona.com/es/",
      "description": "FAMA are the initials that give name to the new Modern and Ancient Art Fair of Barcelona. It hosts more than 5,000 years of history through the antiques that will be presented by nearly thirty exhibitors from all over Spain, boosting the art market and disseminating the great cultural heritage that is hidden in galleries throughout the country. \n\n A quite peculiar design of the stand has been made, paying homage to the emblematic building that houses the show, between the blue color of the background and the curved walls, the idea of the sea and its element, water, has been captured. communication vehicle between cultures, which are represented by a fine selection of works chosen especially for their aesthetic beauty.",
      "artworks": []
    },
    {
      "name": "BRAFA 2015",
      "year": "2015",
      "date": "JANUARY 24 - FEBRUARY 1",
      "location": "Brussels Antiques & Fine Arts Fair",
      "imgPath": "assets/img/exhibitions/brafa2015/brafa2015.jpg",
      "imgPathSquared": "assets/img/exhibitions/brafa2015/brafa2015-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/brafa2015/brafa2015-image-1.jpg",
        "assets/img/exhibitions/brafa2015/brafa2015-image-2.jpg",
        "assets/img/exhibitions/brafa2015/brafa2015-image-3.jpg"
      ],
      "url": "https://www.brafa.art/en",
      "description": "Brafa Art Fair is the quintessential fair in Belgium, being one of the most prestigious in Europe. Since its origins, in 1956, this fair has positioned itself as one of the most representative of art, design and archaeology, echoing the changes in society that we have experienced in the last 60 years. It features more than 130 exhibitors, including contemporary, tribal and comic art, well established in this country. All the works exhibited for sale must be endorsed by a committee of serious experts that certifies the quality and authenticity of the pieces.",
      "artworks": []
    },
    {
      "name": "BAS 2015",
      "year": "2015",
      "date": "SEPTEMBER 24 - 27",
      "location": "Galerie Harmakhis, Grand Sablon - Bruselas",
      "imgPath": "assets/img/exhibitions/bas2015/bas2015.jpg",
      "imgPathSquared": "assets/img/exhibitions/bas2015/bas2015-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/bas2015/bas2015-image-1.jpg",
        "assets/img/exhibitions/bas2015/bas2015-image-2.jpg",
        "assets/img/exhibitions/bas2015/bas2015-image-3.jpg"
      ],
      "url": null,
      "description": "BRUSSELS ART SQUARE was born from the hand of some antique dealers from the Grand Sablon, with the idea of inviting the most renowned antique dealers from the same country to jointly exhibit in their galleries to participate each year. In 2015 the guest country is Spain, and the Harmakhis gallery, the most important in Egyptian art, is hosting us. \n\n A fresco mural painting of an Egyptian tomb from the Middle Kingdom, a portrait of the Roman Emperor Caligula, a gilt cartonnage of a mummy or a trapezophore decorated with the face of a lion are the most outstanding pieces that we present.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2014",
      "year": "2014",
      "date": "NOVEMBER 15 - 23",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2014/feriarte2014.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2014/feriarte2014-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-1.jpg",
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-2.jpg",
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-3.jpg",
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-4.jpg",
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-5.jpg",
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-6.jpg",
        "assets/img/exhibitions/feriarte2014/feriarte2014-image-7.jpg"
      ],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and ancient cultures to 20th century design, as well as the best old masters to the most internationally recognized contemporary ones.",
      "artworks": []
    },
    {
      "name": "BRAFA 2014",
      "year": "2014",
      "date": "JANUARY 25 - FEBRUARY 2",
      "location": "Brussels Antiques & Fine Arts Fair",
      "imgPath": "assets/img/exhibitions/brafa2014/brafa2014.jpg",
      "imgPathSquared": "assets/img/exhibitions/brafa2014/brafa2014-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/brafa2014/brafa2014-image-1.jpg",
        "assets/img/exhibitions/brafa2014/brafa2014-image-2.jpg",
        "assets/img/exhibitions/brafa2014/brafa2014-image-3.jpg",
        "assets/img/exhibitions/brafa2014/brafa2014-image-5.jpg",
        "assets/img/exhibitions/brafa2014/brafa2014-image-6.jpg",
        "assets/img/exhibitions/brafa2014/brafa2014-image-7.jpg"
      ],
      "url": "https://www.brafa.art/en",
      "description": "Brafa Art Fair is the quintessential fair in Belgium, being one of the most prestigious in Europe. Since its origins, in 1956, this fair has positioned itself as one of the most representative of art, design and archaeology, echoing the changes in society that we have experienced in the last 60 years. It features more than 130 exhibitors, including contemporary, tribal and comic art, well established in this country. All the works exhibited for sale must be endorsed by a committee of serious experts that certifies the quality and authenticity of the pieces.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2013",
      "year": "2013",
      "date": "NOVEMBER 16 - 24",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2013/feriarte2013.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2013/feriarte2013-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and ancient cultures to 20th century design, as well as the best old masters to the most internationally recognized contemporary ones.",
      "artworks": []
    },
    {
      "name": "BRUENAF 2013",
      "year": "2013",
      "date": "JUNE 5 - 9",
      "location": "Grand Sablon, Galerie Costermans - Bruselas",
      "imgPath": "assets/img/exhibitions/bruneaf2013/bruneaf2013.jpg",
      "imgPathSquared": "assets/img/exhibitions/bruneaf2013/bruneaf2013-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://bruneaf.com/en",
      "description": "The nerve center of art and antiques in the city of Brussels is located in the Grand Sablon square and its adjacent streets. It is a fair \"in the street\", in which the typical art galleries of the neighborhood withdraw their works and the galleries of African, Asian and ancient art of the sector are established for a few days. \n\n Our exhibition is conceived as a proper ancient art or archeology gallery, with more than fifty works from the most important cultures of the Mediterranean as well as the Middle East. An Amphora from the Leagros Group stands out, made around 510-500 BC, by an Attic painter, from Comte Alberic del Chastel of the Belgian Howarderie between 1842 and 1919.",
      "artworks": []
    },
    {
      "name": "BRAFA 2013",
      "year": "2013",
      "date": "JANUARY 19 - 27",
      "location": "Brussels Antiques & Fine Arts Fair",
      "imgPath": "assets/img/exhibitions/brafa2013/brafa2013.jpg",
      "imgPathSquared": "assets/img/exhibitions/brafa2013/brafa2013-squared.jpg",
      "exhibitionImgPaths": [
        "assets/img/exhibitions/brafa2013/brafa2013-image-1.jpg",
        "assets/img/exhibitions/brafa2013/brafa2013-image-2.jpg",
        "assets/img/exhibitions/brafa2013/brafa2013-image-3.jpg",
        "assets/img/exhibitions/brafa2013/brafa2013-image-4.jpg",
        "assets/img/exhibitions/brafa2013/brafa2013-image-5.jpg"
      ],
      "url": "https://www.brafa.art/en",
      "description": "Brafa Art Fair is the quintessential fair in Belgium, being one of the most prestigious in Europe. Since its origins, in 1956, this fair has positioned itself as one of the most representative of art, design and archaeology, echoing the changes in society that we have experienced in the last 60 years. It features more than 130 exhibitors, including contemporary, tribal and comic art, well established in this country. All the works exhibited for sale must be endorsed by a committee of serious experts that certifies the quality and authenticity of the pieces. \n\n Few Spanish galleries are selected each year for their participation. For our first edition, and as a way of presenting the fair to the public, since the Belgian collector is already known to us through other fairs that we hold in summer in his capital, a selection of the most particular and quality works that we offer in the Barcelona gallery and others cautiously reserved for the exhibition.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2012",
      "year": "2012",
      "date": "NOVEMBER 17 - 25",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2012/feriarte2012.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2012/feriarte2012-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and ancient cultures to 20th century design, as well as the best old masters to the most internationally recognized contemporary ones.",
      "artworks": []
    },
    {
      "name": "ARS ANTIQUA BRUSSELS 2012",
      "year": "2012",
      "date": "JANUARY 18 - 22",
      "location": "Grand Sablon - Bruselas",
      "imgPath": "assets/img/exhibitions/ars2012/ars2012.jpg",
      "imgPathSquared": "assets/img/exhibitions/ars2012/ars2012-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "The nerve center of art and antiques in the city of Brussels is located in the Grand Sablon square and its adjacent streets. It is a fair \"in the street\", in which the typical art galleries of the neighborhood withdraw their works and the galleries of African, Asian and ancient art of the sector are established for a few days. \n\n Our exhibition is conceived as a gallery of ancient art or archeology, with more than fifty works from the most important cultures of the Mediterranean as well as the Middle East.",
      "artworks": []
    },
    {
      "name": "ANTIQUARIS 2012",
      "year": "2012",
      "date": "MARCH 24 - APRIL 1",
      "location": "Recinte Firal Montjuïc - Barcelona",
      "imgPath": "assets/img/exhibitions/antiquaris2012/antiquaris2012.jpg",
      "imgPathSquared": "assets/img/exhibitions/antiquaris2012/antiquaris2012-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "ANTIQUARIS BARCELONA is one of the oldest fairs of Fira de Barcelona, where gallery owners, collectors and lovers of art and antiques meet annually. The pieces of modern, déco, nouveau or contemporary art share the spotlight in this room with fine jewellery, porcelain and European ceramics or ivories, among other objects of great historical and artistic value. \n\n We are part of the organizing committee and our main work is an important schist relief of perfect carving of a stupa of the Gandhara culture.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2011",
      "year": "2011",
      "date": "NOVEMBER 12 - 20",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2011/feriarte2011.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2011/feriarte2011-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and ancient cultures to 20th century design, as well as the best old masters to the most internationally recognized contemporary ones. In our stand you can see more than three hundred archaeological works, from small votive figures of deities to monumental sculptures of the main cultures of the Mediterranean as well as Pre-Hispanic. It will highlight a sculptural group in Roman marble as well as a portrait of El-Fayum and an Iberian Signa Equitum banner.",
      "artworks": []
    },
    {
      "name": "ARS ANTIQUA BRUSSELS 2011",
      "year": "2011",
      "date": "JANUARY 19 - 23",
      "location": "Grand Sablon - Bruselas",
      "imgPath": "assets/img/exhibitions/ars2011/ars2011.jpg",
      "imgPathSquared": "assets/img/exhibitions/ars2011/ars2011-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "The nerve center of art and antiques in the city of Brussels is located in the Grand Sablon square and its adjacent streets. It is a fair \"in the street\", in which the typical art galleries of the neighborhood withdraw their works and the galleries of African, Asian and ancient art of the sector are established for a few days. \n\n Our exhibition is conceived as a proper ancient art or archeology gallery, with more than fifty works from the most important cultures of the Mediterranean as well as the Middle East.",
      "artworks": []
    },
    {
      "name": "ANTIQUARIS 2011",
      "year": "2011",
      "date": "MARCH 12 - 20",
      "location": "Recinte Firal Montjuïc - Barcelona",
      "imgPath": "assets/img/exhibitions/antiquaris2011/antiquaris2011.jpg",
      "imgPathSquared": "assets/img/exhibitions/antiquaris2011/antiquaris2011-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "ANTIQUARIS BARCELONA is one of the oldest fairs of Fira de Barcelona, where gallery owners, collectors and lovers of art and antiques meet annually. The pieces of modern, déco, nouveau or contemporary art share the spotlight in this room with fine jewellery, porcelain and European ceramics or ivories, among other objects of great historical and artistic value. \n\n The most outstanding piece will be a figure of an idol from the Cyclades Islands representing a pregnant woman, carved in marble around 2700 – 2400 BC. The fame of this type of sculpture derives from the simplicity of pure forms, they are iconic pieces admired by the greatest exponents of contemporary art, influencing their works, such as Picasso's cubism.",
      "artworks": []
    },
    {
      "name": "SIART 2010",
      "year": "2010",
      "date": "FEBRUARY 6 - 14",
      "location": "Palau de Congressos de Catalunya - Barcelona",
      "imgPath": "assets/img/exhibitions/siart2010/siart2010.jpg",
      "imgPathSquared": "assets/img/exhibitions/siart2010/siart2010-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "The SIART Art and Artistic Trends Hall was born with the aim of merging avant-garde and historical art and becoming a agitator of the cultural industry. The first edition of this new fair will kick off with works by key figures from the latest trends in the art world, such as Damien Hirst, Zhang Huan, Barceló, Plensa, Botero, Manolo Valdés and Pablo Palazuelo, among others, who will share space with authors from the stature of Picasso, Dalí, Miró, Nonell, Sorolla, Casas, Rusiñol or Chillida. All of them surrounded by ancient and historical objects. With this initiative we also want to give a response to the cultural industry through aesthetic concepts. \n\n In our space, following the line of the gallery, a beautiful female togada with very marked cloths, made in marble during the 2nd century AD, by a workshop in ancient Rome, will stand out.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2010",
      "year": "2010",
      "date": "NOVEMBER 20 - 28",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2010/feriarte2010.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2010/feriarte2010-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and ancient cultures to 20th century design, as well as the best old masters to the most internationally recognized contemporary ones.",
      "artworks": []
    },
    {
      "name": "ARTEMANÍA 2010",
      "year": "2010",
      "date": "MARCH 20 - 28",
      "location": "Palacio de Congresos - Madrid",
      "imgPath": "assets/img/exhibitions/artemania2010/artemania2010.jpg",
      "imgPathSquared": "assets/img/exhibitions/artemania2010/artemania2010-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "In the heart of Paseo de Castellana, the most central art and antique collecting fair in the capital is held.",
      "artworks": []
    },
    {
      "name": "ANTIQUARIS 2010",
      "year": "2010",
      "date": "OCTOBER 16 - 24",
      "location": "Recinte Firal Montjuïc - Barcelona",
      "imgPath": "assets/img/exhibitions/antiquaris2010/antiquaris2010.jpg",
      "imgPathSquared": "assets/img/exhibitions/antiquaris2010/antiquaris2010-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "ANTIQUARIS BARCELONA es una de las ferias decanas de Fira de Barcelona, donde galeristas, coleccionistas y amantes del arte y las antigüedades se dan cita anualmente. Las piezas de arte moderno, déco, nouveau o contemporáneo comparten protagonismo en este salón con la alta joyería, la porcelana y la cerámica europea o los marfiles, entre otros objetos de gran valor histórico y artístico. \n\n Formamos parte del comité organizador y nuestra obra principal es una importante escultura monumental en esquisto de perfecta talla de la cultura Gandhara. ",
      "artworks": []
    },
    {
      "name": "FERIARTE 2009",
      "year": "2009",
      "date": "14 - 22 NOVIEMBRE",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2009/feriarte2009.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2009/feriarte2009-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and ancient cultures to 20th century design, as well as the best old masters to the most internationally recognized contemporary ones.",
      "artworks": []
    },
    {
      "name": "ATARAZANAS 2009",
      "year": "2009",
      "date": "OCTOBER 17 - 25",
      "location": "Reials Drassanes - Barcelona",
      "imgPath": "assets/img/exhibitions/atarazanas2009/atarazanas2009.jpg",
      "imgPathSquared": "assets/img/exhibitions/atarazanas2009/atarazanas2009-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "A fascinating journey through history, culture, art and the best investments with 10,500 works from all centuries, styles and prices. 6,000-year-old pieces, the impressive collection of the African Kota tribe, Pop Art or avant-garde designs by Pierre Cardin will coexist with creations by Picasso, Dalí, Warhol, Miró, Tàpies, Barceló, Plensa, Rusiñol, Casas, Chillida or Botero. \n\n The 2,000 square meters set up within the framework of the Gothic naves of the Marqués de Comillas Hall, a jewel of medieval Catalan civil art and the unmistakable hallmark of the Hall, once again concentrate impressive pieces and works of all artistic tendencies and different segments prices in an unmissable event for professionals and public passionate about classical and contemporary works of art or Greek, Roman, Egyptian and Asian archaeology.",
      "artworks": []
    },
    {
      "name": "ARTEMANÍA 2009",
      "year": "2009",
      "date": "FEBRUARY 28 - MARCH 8",
      "location": "Palacio de Congresos - Madrid",
      "imgPath": "assets/img/exhibitions/artemania2009/artemania2009.jpg",
      "imgPathSquared": "assets/img/exhibitions/artemania2009/artemania2009-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "In the heart of Paseo de Castellana, the most central art and antique collecting fair in the capital is held.",
      "artworks": []
    },
    {
      "name": "FERIARTE 2008",
      "year": "2008",
      "date": "NOVEMBER 15 - 23",
      "location": "Ifema, Feria de Madrid",
      "imgPath": "assets/img/exhibitions/feriarte2008/feriarte2008.jpg",
      "imgPathSquared": "assets/img/exhibitions/feriarte2008/feriarte2008-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "FERIARTE, with more than four decades of experience, represents the only art and antiques fair in Spain and the space for collectors to have a unique contemporary perspective on thousands of years of art history. The best works from classical antiquity and ancient cultures to 20th century design, as well as the best old masters to the most internationally recognized contemporary ones.",
      "artworks": []
    },
    {
      "name": "ATARAZANAS 2008",
      "year": "2008",
      "date": "OCTOBER 18 - 26",
      "location": "Reials Drassanes - Barcelona",
      "imgPath": "assets/img/exhibitions/atarazanas2008/atarazanas2008.jpg",
      "imgPathSquared": "assets/img/exhibitions/atarazanas2008/atarazanas2008-squared.jpg",
      "exhibitionImgPaths": [],
      "url": "https://www.ifema.es/feriarte",
      "description": "The fascinating journey through history and art proposed by this reference appointment includes works by Picasso, Dalí, Miró, Tàpies, Botero, Barceló, Plensa, Rusiñol, Chillida, Casas or the British Damien Hirst, the \"new King Midas\" of the art and one of the most transgressive living artists. The 2,000 square meters fitted out within the framework of the Gothic naves of the Marqués de Comillas Hall, one of the jewels of Catalan medieval civil art and the Hall's unmistakable hallmark, will again concentrate pieces and creations from all centuries and prices for professionals and a public passionate about the world of antiquities, historical works of art and archaeology. \n\n In a tour with stands of incomparable quality and spectacularity, you will find everything from an impressive 2,600-year-old Egyptian sarcophagus to a unique selection of French Belle Époque and Art Déco jewels; works and lithographs from 2007 by the controversial Damien Hrist or valuable sculptures made in times of the Roman Empire.",
      "artworks": []
    },
    {
      "name": "ANTIQUARIS 2008",
      "year": "2008",
      "date": "MARCH 29 - APRIL 6",
      "location": "Recinte Firal Montjuïc - Barcelona",
      "imgPath": "assets/img/exhibitions/antiquaris2008/antiquaris2008.jpg",
      "imgPathSquared": "assets/img/exhibitions/antiquaris2008/antiquaris2008-squared.jpg",
      "exhibitionImgPaths": [],
      "url": null,
      "description": "ANTIQUARIS BARCELONA is one of the oldest fairs of Fira de Barcelona, where gallery owners, collectors and lovers of art and antiques meet annually. The pieces of modern, déco, nouveau or contemporary art share the spotlight in this room with fine jewellery, porcelain and European ceramics or ivories, among other objects of great historical and artistic value.",
      "artworks": []
    }
  ]
}