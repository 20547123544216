import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  lightboxArr;
  currentImage = 0;
  videoPathSubject = new Subject();

  constructor() {}

  resetCurrentImg(e) {
    this.currentImage = e;
  }
}
