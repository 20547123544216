import { Component, OnInit } from '@angular/core';
import { InterfaceService } from '../services/interface.service';
import { DataService } from '../services/data.service';
import { MediaService } from '../services/media.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  videoPath;
  showOpacity;

  constructor(
    public interfaceService: InterfaceService,
    private dataService: DataService,
    private mediaService: MediaService
  ) {}

  ngOnInit(): void {
    this.mediaService.videoPathSubject.subscribe((res) => {
      this.videoPath = res;

      setTimeout(() => {
        this.showOpacity = true;
      }, 300);
    });
  }

  closeVideoPlayer() {
    this.interfaceService.isVideoPlayerOpen = false;
    this.videoPath = null;
    this.showOpacity = false;
  }
}
