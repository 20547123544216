import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { InterfaceService } from 'src/app/services/interface.service';

@Component({
  selector: 'app-notable-sales',
  templateUrl: './notable-sales.component.html',
  styleUrls: ['./notable-sales.component.scss'],
})
export class NotableSalesComponent implements OnInit, AfterViewChecked {
  firstLoad = false;

  get museumArtworks() {
    return this.dataService.museumArtworks;
  }

  get entityCache() {
    return this.dataService.entityCache;
  }

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  constructor(
    public interfaceService: InterfaceService,
    private dataService: DataService
  ) {}

  ngAfterViewChecked(): void {
    if (!this.firstLoad) {
      this.interfaceService.reveal();
      this.firstLoad = true;
    }
  }

  ngOnInit(): void {
    this.interfaceService.displayDark = true;
    this.interfaceService.footerBackgroundChange = true;
    

    this.interfaceService.revealTimeout();
    window.addEventListener('scroll', this.interfaceService.reveal);
  }

  getFirstEntityName(artwork, property) {
    if (this.dataService.getEntityList(artwork, property)) {
      return this.dataService.getEntityList(artwork, property)[0].name;
    } else {
      return null;
    }
  }

  getMediaList(artworkId, mediaType) {
    return this.dataService.getMediaList(artworkId, mediaType);
  }
}
