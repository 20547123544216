import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { InterfaceService } from '../services/interface.service';
import { SmoothScrollService } from '../services/smooth-scroll.service';

@Component({
  selector: 'app-work-with-us',
  templateUrl: './work-with-us.component.html',
  styleUrls: ['./work-with-us.component.scss'],
})
export class WorkWithUsComponent implements OnInit {
  get availablePositions() {
    return this.dataService.availablePositions;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  contact = {
    es: 'Si estás interesado/a en unirte a nuestro equipo, envíanos tu CV a',
    en: 'If you are interested in joining our team, send your CV to'
  }

  constructor(
    private dataService: DataService,
    private smoothScroll: SmoothScrollService,
    public interfaceService: InterfaceService
  ) {}

  ngOnInit(): void {
    this.interfaceService.displayDark = true;
    this.interfaceService.footerBackgroundChange = true;
    this.smoothScroll.scrollTarget = null;
  }
}
