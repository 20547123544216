import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  catchError,
  forkJoin,
  of,
  switchMap,
} from 'rxjs';
import { ConnectionService } from './connection.service';
import data from './../../assets/json/data.json';
import exhibitions from './../../assets/json/exhibitions.json';
import homeSlides from './../../assets/json/home-swiper-slides.json';
import { PaginationService } from './pagination.service';

interface Data {
  title: String;
  content: Array<{ question: String; answer: String }>;
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private dataReadySubject: BehaviorSubject<any>;

  get paginationKey() {
    return this.paginationService.assetContextsLastKey;
  }

  currentLanguage = 'es';
  availableLanguages;
  defaultLanguage = '';

  generalTexts = null;

  data: Data[] = data;
  exhibitions: Data[] = exhibitions;
  homeSlides: Data[] = homeSlides;

  organizationData;

  worksOfArtData;
  youngCollectorsData;
  paintingsData;

  homeArtworks = [];
  exhibitionArtworks = [];
  exhibitionArtworksList = [];
  youngCollectorsArtworks = [];
  paintingArtworks = [];
  museumArtworks = [];
  artworksCache = {};
  artworkEntitiesCache = {};
  mediaCache = {};
  entityCache = {};

  exhibitionId;

  thumbnailPlaceholderPath = '../../assets/img/thumbnail-placeholder.jpg';

  availablePositions = {
    es: [
      {
        role: 'Asistente de Galería',
        education:
          'Estudios en Historia del Arte (especialización en historia antigua)',
        requirements:
          'Se valorará experiencia en Museos o Instituciones de carácter arqueológico',
      },
    ],
    en: [
      {
        role: 'Gallery Assistant',
        education: 'Studies in Art History (specialization in Ancient History)',
        requirements:
          'Experience in museums or archaeological institutions is an asset.',
      },
    ],
  };

  mockYoungCollectorsText =
    'Con la democratización del arte, ahora todo el mundo puede comprar y vivir con bellas obras de arte. En un mercado en constante cambio, nuestro objetivo es dar una respuesta adecuada y personalizada a cualquier persona que desee experimentar el mundo del arte. Independientemente de si es un coleccionista primerizo o un experto desde hace mucho tiempo. Entre las obras de arte habrá piezas clásicas, pero también hallazgos inesperados, siempre con el compromiso subyacente de la excelencia. Compartir nuestra pasión por el arte y por estas exquisitas obras tan ricas en historia es nuestra principal preocupación, porque sabemos lo maravilloso que es estar rodeado de tan bellos tesoros. Para tal fin, ofrecemos una gran variedad de piezas de bronce, mármol y terracota, obras maestras cotidianas de la más alta calidad, a precios ajustados hasta los 10.000 euros. Todos estos objetos han sido seleccionados por su belleza estética y significado histórico. Ofrecemos la oportunidad de presentar a una nueva audiencia las culturas del mundo antiguo y brindarles a nuestros clientes existentes un medio fácil para expandir sus colecciones.';

  mockYoungImgPaths = [
    'assets/img/mock-images/young-1.png',
    'assets/img/mock-images/young-2.png',
    'assets/img/mock-images/young-3.png',
    'assets/img/mock-images/young-4.png',
    'assets/img/mock-images/young-5.png',
    'assets/img/mock-images/young-6.png',
    'assets/img/mock-images/young-7.png',
    'assets/img/mock-images/young-8.png',
  ];

  publications = [
    {
      name: 'Ignacio Zuloaga',
      subtitle: 'UNA COLECCIÓN DE ARQUEOLOGÍA CLÁSICA',
      pages: '27.5 x 21.3 cm - 58 ',
      price: '25€',
      imgPath: 'assets/img/mock-images/catalogue-1.png',
      contact: 'info@jbagot.com',
      link: 'https://issuu.com/jbagot/docs/final_zuloaga_buena_resolucion_ult/2',
    },
    {
      name: 'El Legado de Hefesto',
      subtitle:
        'A MEMORIAL TO A PRIVATE COLLECTIONOF ANCIENT RINGS AND GLYPTICS',
      pages: '33.77 x 24.7 cm - 192 ',
      price: '100€',
      imgPath: 'assets/img/mock-images/catalogue-2.png',
      contact: 'info@jbagot.com',
      link: 'https://issuu.com/jbagot/docs/libro_el_legado_de_hefesto/2',
    },
  ];

  constructor(
    private connectionService: ConnectionService,
    private paginationService: PaginationService
  ) {
    this.dataReadySubject = new BehaviorSubject(undefined);
  }

  public init() {
    const id = 'jbagot';
    const exh = 'eues20009worksofart';
    const youngCollectorsCollId = 'eues20009youngcollector';
    const museumsCollId = 'eues20009museums';
    const paintingsId = 'eues20009paintings';

    return new Promise((success) => {
      forkJoin([
        this.connectionService.fetchOrganization(id),
        this.connectionService.getAssetPreviews(exh, 'collection', null, 3),
        // this.connectionService.getAssetPreviews(exh, 'collection', null, 4),
        this.connectionService.getAssetPreviews(
          paintingsId,
          'collection',
          null,
          1
        ),

        this.connectionService
          .getAssetPreviews(museumsCollId, 'collection', null, null)
          .pipe(
            catchError((error) => {
              console.error('Error fetching museum artworks:', error);
              return of({ list: [] }); // return empty list to continue execution
            })
          ),
        this.connectionService.fetchCollectionByCustomId(exh).pipe(
          catchError((error) => {
            console.error('Error fetching works of art data:', error);
            return of(null); // return null to continue execution or handle differently as needed
          })
        ),
        this.connectionService
          .fetchCollectionByCustomId(youngCollectorsCollId)
          .pipe(
            catchError((error) => {
              console.error('Error fetching young collectors data:', error);
              return of(null); // return null to continue execution or handle differently as needed
            })
          ),
        this.connectionService.fetchCollectionByCustomId(paintingsId).pipe(
          catchError((error) => {
            console.error('Error fetching paintings data:', error);
            return of(null); // return null to continue execution or handle differently as needed
          })
        ),
        this.connectionService.getGeneralTexts(),
      ]).subscribe(
        ([
          organizationData,
          exhibitionArtworks,
          paintingArtworks,
          museumArtworks,
          worksOfArtData,
          youngCollectorsData,
          paintingsData,
          generalTexts,
        ]) => {
          this.worksOfArtData = worksOfArtData;
          this.youngCollectorsData = youngCollectorsData;

          //ORGANIZATION DATA
          this.organizationData = organizationData;
          this.currentLanguage = this.organizationData.default_language;
          this.availableLanguages = this.organizationData.available_languages;

          //LATEST ACQUISITIONS
          if (exhibitionArtworks.list.length) {
            this.homeArtworks = exhibitionArtworks.list.map(
              (artwork) => artwork.asset
            );
            this.homeArtworks.forEach((artwork) => {
              artwork.type = 'ancient';
            });
            if (paintingArtworks.list.length) {
              const paintingArtwork = paintingArtworks.list[0].asset;
              paintingArtwork['type'] = 'modern';

              this.homeArtworks.unshift(paintingArtwork);
            }

            this.homeArtworks.forEach((assetContext) => {
              this.setArtworkData(assetContext);
            });
          }

          //MUSEUM ARTWORKS
          if (museumArtworks.list.length) {
            this.museumArtworks = museumArtworks.list.map(
              (artwork) => artwork.asset
            );
            museumArtworks.list.forEach((assetContext) => {
              this.setArtworkData(assetContext.asset);
            });
          }

          this.paintingsData = paintingsData;
          // console.log('PAINTING DATA', paintingsData);

          this.generalTexts = generalTexts;

          this.dataReadySubject.next(true);
          success(exhibitionArtworks);
        }
      );
    });
  }

  fetchAllArtworks(id, property) {
    const path = window.location.pathname;
    if (path === '/artworks') {
      this.exhibitionArtworks = [];
    } else if (path === '/paintings') {
      this.paintingArtworks = [];
    } else {
      this.youngCollectorsArtworks = [];
    }

    this.paginationService.resetAssetContextsPagination();

    return new Promise((resolve, reject) => {
      const handleArtworkCalls = (paginationKey) => {
        return this.connectionService
          .getAssetPreviews(id, property, paginationKey, null)
          .pipe(
            catchError((error) => {
              console.error('Error fetching asset previews:', error);
              return of({ list: [], paginationKey: null }); // return empty list to continue execution
            }),
            switchMap((artworkData) => {
              // console.log('async assets call', artworkData);

              if (path === '/artworks') {
                this.exhibitionArtworks = this.exhibitionArtworks.concat(
                  artworkData.list
                );
              } else if (path === '/paintings') {
                this.paintingArtworks = this.paintingArtworks.concat(
                  artworkData.list
                );
              } else {
                this.youngCollectorsArtworks =
                  this.youngCollectorsArtworks.concat(artworkData.list);
              }

              if (artworkData.paginationKey) {
                this.paginationService.setPagination(artworkData);

                return handleArtworkCalls(this.paginationKey);
              } else {
                return of(null);
              }
            })
          );
      };

      handleArtworkCalls(this.paginationKey).subscribe({
        complete: () => {
          // All asynchronous calls completed
          if (path === '/artworks') {
            // console.log('All async calls completed.', this.exhibitionArtworks);
            this.exhibitionArtworks = this.exhibitionArtworks
              .map((assetContext) => assetContext.asset)
              .filter((asset) => asset.visibility === 'public');

            this.exhibitionArtworks.forEach((asset) => {
              this.setArtworkData(asset);
            });
          } else if (path === '/paintings') {
            this.paintingArtworks = this.paintingArtworks
              .map((assetContext) => assetContext.asset)
              .filter((asset) => asset.visibility === 'public');
            this.paintingArtworks.forEach((asset) => {
              this.setArtworkData(asset);
            });
          } else {
            this.youngCollectorsArtworks = this.youngCollectorsArtworks
              .map((assetContext) => assetContext.asset)
              .filter((asset) => asset.visibility === 'public');
            this.youngCollectorsArtworks.forEach((asset) => {
              this.setArtworkData(asset);
            });
          }

          // console.log('END.', this.assets);
          if (path === '/artworks') {
            resolve(this.exhibitionArtworks);
          } else if (path === '/paintings') {
            resolve(this.paintingArtworks);
          } else {
            resolve(this.youngCollectorsArtworks);
          }
        },
        error: (err) => {
          console.error('Error fetching assets:', err);
          reject(err);
        },
      });
    });
  }

  setArtworkData(asset) {
    // console.log('ASSET', asset);

    const customId = asset.customId;
    if (!this.artworksCache[customId]) {
      this.artworksCache[customId] = asset;
      if (asset.custom_entities) {
        this.distributeEntities(asset.custom_entities, customId);
      }
      if (asset.entities) {
        this.distributeEntities(asset.entities, customId);
      }
      if (asset.media) {
        this.distributeMedia(asset.media, customId);
      }
    }
  }

  distributeEntities(entities, customId) {
    // console.log('ENTTIRES list', entities);

    if (!this.artworkEntitiesCache[customId]) {
      this.artworkEntitiesCache[customId] = {};
    }
    let entityType;
    entities.forEach((e) => {
      // console.log('ENTITIES', e, customId);

      if (e) {
        if (e.entityType) {
          entityType = e.entityType.toLowerCase();
        } else {
          entityType = e.type.toLowerCase();
        }
        if (!this.artworkEntitiesCache[customId][entityType]) {
          this.artworkEntitiesCache[customId][entityType] = [];
        }
        this.artworkEntitiesCache[customId][entityType].push(e);

        //OPTION?????
        if (e.customId && !this.entityCache[e.customId]) {
          this.entityCache[e.customId] = e;
        }
      }
    });

    // console.log('ENTITIES CAHE', this.artworkEntitiesCache);
  }

  distributeMedia(mediaList, customId) {
    if (!this.mediaCache[customId]) {
      this.mediaCache[customId] = {};
    }
    mediaList.forEach((media) => {
      if (!this.mediaCache[customId][media.type]) {
        this.mediaCache[customId][media.type] = [];
      }
      this.mediaCache[customId][media.type].push(media);
    });
  }

  getEntityList(artwork, entityType) {
    if (
      this.artworkEntitiesCache[artwork] &&
      this.artworkEntitiesCache[artwork][entityType]
    ) {
      const entityList = this.artworkEntitiesCache[artwork][entityType];
      return entityList;
    } else {
      return null;
    }
  }

  getArtistFullName(artwork, property) {
    if (this.getEntityList(artwork, property)) {
      if (this.getEntityList(artwork, property)[0].surname) {
        const fullName = {};

        const firstName = this.getEntityList(artwork, property)[0].name;
        const surnName = this.getEntityList(artwork, property)[0].surname;
        for (const key of Object.keys(firstName)) {
          fullName[key] = firstName[key] + ' ' + surnName[key];
        }
        return fullName;
      } else {
        return this.getEntityList(artwork, property)[0].name;
      }
    } else {
      return null;
    }
  }

  getMainMedia(artworkId, mediaType) {
    if (
      this.mediaCache[artworkId] &&
      this.mediaCache[artworkId][mediaType] &&
      this.mediaCache[artworkId][mediaType][0].view_image
    ) {
      return this.mediaCache[artworkId][mediaType][0].view_image;
    } else {
      return '';
    }
  }

  getMediaList(artworkId, mediaType) {
    if (this.mediaCache[artworkId] && this.mediaCache[artworkId][mediaType]) {
      return this.mediaCache[artworkId][mediaType];
    } else {
      return null;
    }
  }

  getDataSubject() {
    return this.dataReadySubject.asObservable();
  }
  setDataSubject() {
    this.dataReadySubject.next(true);
  }
}
