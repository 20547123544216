<div class="relative" id="swiper1">
    <swiper-container [slidesPerView]="'auto'" [spaceBetween]="30" [loop]="false" navigation="true" init="false"
        #expoSwiperRef>
        <swiper-slide class="expo-swiper-slide" *ngFor="let expo of expoSlides; let i = index">
            <div *ngIf="i < 5" class="mr-12 lg:mr-6 flex flex-col items-start cursor-pointer content-container"
                (click)="navigationService.navigateTo('exhibitions')">
                <img #slideImg *ngIf="expo" class="" [src]="expo.imgPath" alt="" />
                <div *ngIf="onHomePage" class="flex flex-col md:justify-center text-container">
                    <hr class="border-t w-12 border-gray-medium mt-5 mb-6 divider" />
                    <p class="text-sm">{{expo.name }}</p>
                    <p class="font-trajan uppercase text-lg">{{expo.name}}</p>
                    <p class="text-sm">{{expo.location}}</p>
                </div>

            </div>
        </swiper-slide>
    </swiper-container>

    <div class="expo-btn-container absolute z-10 flex justify-between w-full" #swiperBtnContainer>
        <button id="prevBtn" class="nav-btn flex justify-center items-center  rotate-180"
            [disabled]=" expoSwiper && expoSwiper.activeIndex === 0">
            <img src="assets/img/simple-arrow-right-dark.svg" alt=""></button>
        <button id="nextBtn" class="nav-btn flex justify-center items-center" [disabled]="onLastSlide"><img
                src="assets/img/simple-arrow-right-dark.svg" alt=""></button>
    </div>
</div>