import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ConnectionService } from 'src/app/services/connection.service';

@Component({
  selector: 'app-painting-mobile-swiper',
  templateUrl: './painting-mobile-swiper.component.html',
  styleUrls: ['./painting-mobile-swiper.component.scss']
})
export class PaintingMobileSwiperComponent implements OnInit {
  @ViewChild('artworkSwiperRef', { static: true })
  protected _artworkSwiperRef: ElementRef | undefined;

  @Input() imageCarousel;

  constructor(public connectionService: ConnectionService) {}

  ngOnInit(): void {}
}
