import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SmoothScrollService {
  scrollTarget = null;

  constructor() {}

  smoothVerticalScrolling(e: any, callback?: Function) {
    const element = document.getElementById(e);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    if (callback) {
      callback();
    }
  }

  verticalScrolling(e: any, callback?: Function) {
    const element = document.getElementById(e);
    if (element) {
      const newTarget = element.getBoundingClientRect().top - 150;
      document.documentElement.scrollTop = document.body.scrollTop = newTarget;
      // element.scrollIntoView({ behavior: 'auto' });
    }
    if (callback) {
      callback();
    }
  }

  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  scrollToId(target) {
    console.log('TARGET', target);
    
    if (target) {
      this.verticalScrolling(target);
    }
  }
}
