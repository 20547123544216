import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { BehaviorSubject, Observable, of } from 'rxjs';
  import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
  import { environment } from 'src/environments/environment';
  import { AuthService } from './auth.service';
  
  @Injectable({
    providedIn: 'root',
  })
  export class TokenExpirationService {
    url = `${environment.apiUrl}/auth`;
    isRefreshingtoken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    urlsToDiscard: Array<string>;
  
    constructor(private http: HttpClient, private auth: AuthService) {
      this.urlsToDiscard = [
        'public-assets'
      ];
    }
  
    public sendTokenRequest(): Observable<any> {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        const refreshToken = user['refreshToken'];
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': `application/json`,
            refreshAuthorization: `Bearer ${refreshToken}`,
          }),
        };
        return this.http.get(`${this.url}/refresh-token`, httpOptions);
      }
      return of(null);
    }
  
    addAuthToken(request: HttpRequest<unknown>, token: string) {
      if(this.checkUrlWithAuth(request.url)){
        return request.clone({ 
          setHeaders: { 
          Authorization: 'Bearer ' + token
          }
        });
      }
      return request;
    }

    private checkUrlWithAuth(url): boolean{
      let access = true;
      this.urlsToDiscard.forEach(u => {
        if(url.includes(u)){
          access = false;
        }
      })
      return access;
    }
  
    handle403Error(
      request: HttpRequest<unknown>,
      next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
      if (!this.isRefreshingtoken) {
        this.isRefreshingtoken = true;
        this.tokenSubject.next(null);
  
        return this.sendTokenRequest().pipe(
          switchMap((response) => {
            if (response) {
              this.tokenSubject.next(response['token']);
              const user = JSON.parse(localStorage.getItem('user'));
              user['accessToken'] = response['token'];
              localStorage.setItem('user', JSON.stringify(user));
              return next.handle(this.addAuthToken(request, response['token']));
            }
            return next.handle(null);
          }),
          catchError((error) => {
            return of(error);
          }),
          finalize(() => (this.isRefreshingtoken = false))
        );
      } else {
        return this.tokenSubject.pipe(
          filter((token) => token != null),
          take(1),
          switchMap((token) => {
            const user = JSON.parse(localStorage.getItem('user'));
            return next.handle(this.addAuthToken(request, token));
          })
        );
      }
    }
}
  