<div *ngIf="currentData[currentLanguage].authenticity"
    class="pt-36 pb-20 mx-10% flex flex-col items-center text-sm sm:mx-6 sm:pb-12">
    <div class="flex flex-col items-center w-1/2 text-center sm:w-auto">
        <h3 class="uppercase text-2xl mb-9 reveal">{{ generalTexts[currentLanguage].authenticity }}</h3>
        <p class="text-gray-grayText text-base mb-6 reveal"
            [innerHTML]="currentData[currentLanguage].authenticity.subtitle"></p>
        <p class="text-gray-darkest reveal" [innerHTML]="currentData[currentLanguage].authenticity.content[0].answer">
        </p>
    </div>

    <div class="flex flex-col items-center mb-6"
        *ngFor="let content of currentData[currentLanguage].authenticity.content | slice:1:currentData[currentLanguage].authenticity.content.length; let i = index">

        <div class="flex justify-center w-img my-12 overflow-hidden sm:w-full sm:my-8 reveal">
            <img [src]="currentData[currentLanguage].authenticity.image_paths[i]" alt=""
                class="filter grayscale filter-hover">
        </div>

        <div class="flex flex-col items-center w-1/2 text-center sm:w-auto">
            <p class="text-gray-darkest mb-6 font-bold reveal" [innerHTML]="content['question']"></p>
            <p class="reveal" [innerHTML]="content['answer']"></p>
        </div>

    </div>
</div>