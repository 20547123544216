{
  "name": "j-bagot-website",
  "version": "2.1.12",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host local.museumdiscovery.org --open --port 4204 --configuration local",
    "build": "ng build",
    "build-dev": "ng build  --configuration dev",
    "build-pre": "ng build  --configuration pre",
    "build-prod": "ng build  --configuration production",
    "build-test": "ng build --base-href=/jbagot/ --deploy-url=/jbagot/",
    "test": "ng test",
    "codegen": "gql-gen"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.2",
    "@angular/common": "^18.2.2",
    "@angular/compiler": "^18.2.2",
    "@angular/core": "^18.2.2",
    "@angular/forms": "^18.2.2",
    "@angular/platform-browser": "^18.2.2",
    "@angular/platform-browser-dynamic": "^18.2.2",
    "@angular/router": "^18.2.2",
    "@apollo/client": "^3.0.0",
    "@mailchimp/mailchimp_marketing": "^3.0.78",
    "@tailwindcss/line-clamp": "^0.4.0",
    "@types/ws": "^8.5.4",
    "apollo-angular": "^7.0.2",
    "graphql": "^16.6.0",
    "masonry-layout": "^4.2.2",
    "ngx-masonry": "^14.0.1",
    "ngx-swiper-wrapper": "^10.0.0",
    "rxjs": "~7.4.0",
    "swiper": "^9.4.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.1",
    "@angular/cli": "^18.2.1",
    "@angular/compiler-cli": "^18.2.2",
    "@graphql-codegen/cli": "^2.16.5",
    "@graphql-codegen/client-preset": "^1.2.6",
    "@graphql-codegen/typescript": "^2.8.1",
    "@graphql-codegen/typescript-apollo-angular": "^3.5.6",
    "@graphql-codegen/typescript-operations": "^2.5.6",
    "@tailwindcss/forms": "^0.3.4",
    "@types/jasmine": "~3.10.0",
    "@types/node": "^12.20.55",
    "autoprefixer": "^10.4.7",
    "jasmine-core": "~3.10.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.0.3",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "postcss": "^8.4.14",
    "tailwindcss": "2.1.2",
    "tslib": "^2.4.0",
    "typescript": "5.4"
  }
}
