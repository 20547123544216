<div class="w-full lg:h-auto resizedSm:h-auto">
        <div class="w-full h-2/5 px-10% flex items-center py-28 lg:px-9"
            [ngClass]="{'bg-light-offWhite': interfaceService.footerBackgroundChange}">
            <div class="w-full">
                <h3 class="text-3xl w-1/2 3xlmin:w-2/5 lg:pr-24 md:pr-12  md:w-3/5 smmd:w-4/5 sm:w-full">{{ generalTexts[currentLanguage].subscribe }}</h3>
                <div class="border-b-2 w-1/2 3xlmin:w-2/5 md:w-3/5 smmd:w-4/5 sm:w-full ">
                    <form action="/signup" method="POST" #form="ngForm" (ngSubmit)="handleSubmit(form)" class="flex">

                        <input
                        class="w-full mt-10 px-0 pb-4 text-s border-transparent bg-transparent focus:border-transparent focus:ring-0"
                        type="text" [placeholder]="generalTexts[currentLanguage].add_email"
                        [ngModel]="formData ? formData.email : null" name="email">
                        <button type="submit" class="subscribe-btn h-10 relative ">
                            <img class="h-3" src="assets/img/simple-arrow-right-grey.svg" alt="">
                        </button>
                    </form>
                </div>
            </div>
        </div>
    
        <div class="w-full bg-gray-dark h-3/5 relative pt-20 flex flex-col">
            <div class="flex px-20 h-full resizedXlh-auto font-montserratThin lg:flex-col lg:px-9 justify-between">
                <div class="text-white flex flex-col text-s items-start lg:items-center lg:w-auto w-1/3">
                    <div (click)="navigateExternal('https://blog.jbagot.com')" class="mb-4 cursor-pointer text-left sm:text-center hover-text"><span>Blog</span></div>
                    <div (click)="navigateExternal(exhibitionUrl)" class="mb-4 cursor-pointer text-left sm:text-center hover-text"><span class="capitalize">{{generalTexts[currentLanguage].private_gallery}}</span></div>
                    <div class="mb-4 cursor-pointer text-left sm:text-center hover-text"
                        (click)="navigationService.navigateTo('artworks-in-museums')">
                        <span class="whitespace-nowrap">{{generalTexts[currentLanguage].sales}}</span>
                    </div>
                    <div class="mb-4 cursor-pointer text-left sm:text-center hover-text"
                        (click)="navigationService.navigateTo('appraisers')"><span>{{generalTexts[currentLanguage].appraisers}}</span></div>
                    <div class="mb-4 cursor-pointer text-left sm:text-center hover-text"
                        (click)="navigationService.navigateTo('authenticity')">
                        <span>{{generalTexts[currentLanguage].authenticity}}</span>
                    </div>
                    <div class="mb-4 cursor-pointer text-left sm:text-center hover-text"
                        (click)="navigationService.navigateTo('conditions')"><span class="whitespace-nowrap">{{generalTexts[currentLanguage].conditions}}</span></div>
                    <div class="cursor-pointer text-left sm:text-center hover-text"
                        (click)="navigationService.navigateTo('work-with-us')"><span class="whitespace-nowrap">{{generalTexts[currentLanguage].work_with_us}}</span></div>
                </div>
    
                <div class="mt-12 mb-14 lgmin:hidden flex justify-center">
                    <img class="w-32" src="assets/img/footer-images/footer-logo-light.svg" alt="">
                </div>

                <div class="w-1/3 flex justify-center">
                    <div class="w-32 h-full lg:hidden flex flex-col justify-between items-center">
                        <img class="h-full" src="assets/img/footer-images/footer-logo-light.svg" alt="">
                    </div>
                </div>
    
                <div class="text-white text-right flex flex-col items-end lg:items-center text-s lg:w-auto w-1/3">
                    <div class="mb-8 flex flex-col items-end lg:items-center">
                        <div><span>Consell de Cent, 321</span></div>
                        <div><span>08007 Barcelona SPAIN</span></div>
                        <div><span>(+34) 93 140 53 26</span></div>
                        <div><span>info&#64;jbagot.com</span></div>
                    </div>
    
                    <div class="mb-8 flex flex-col lg:items-center">
                        <div><span>{{ generalTexts[currentLanguage].monday_friday }}</span></div>
                        <div><span>10:00h {{ generalTexts[currentLanguage].to }} 14:00h</span></div>
                        <div><span>16:30h {{ generalTexts[currentLanguage].to }} 20:00h</span></div>
                    </div>
    
                    <button (click)="navigateExternal(instagramLink)" class="flex justify-end items-center hover-text">
                        <span class="mr-2">Instagram</span>
                        <img class="w-4" src="assets/img/instagram-icon.svg" alt="">
                    </button>
                </div>
            </div>


            <div class="w-full flex justify-center p-14 lg:hidden">
                <div class="w-32 h-full flex flex-col justify-between items-center">
                    <div class="flex justify-center items-center w-full resizedSm:h-36">
                        <img class="mr-4 w-auto h-14" src="assets/img/footer-images/footer-1.svg" alt="">
                        <img class="mr-4 w-auto h-14" src="assets/img/footer-images/footer-2.svg" alt="">
                        <img class="mr-4 w-auto h-14" src="assets/img/footer-images/footer-3.png" alt="">
                        <img class="mr-4 w-auto h-14" src="assets/img/footer-images/footer-4.svg" alt="">
                        <img class="mr-4 w-auto h-14" src="assets/img/footer-images/footer-5.svg" alt="">
                        <img class="w-24 brafa" src="assets/img/footer-images/footer-6.svg" alt="">
                    </div>
                </div>
            </div>

            <div class="text-gray-darkest font-trajan flex justify-between
            px-8 py-4 w-full xs:px-4 xxs:px-2 lg:flex-col lg:items-center lg:mt-24 md:relative">
                <div class="flex items-start lg:hidden text-xs "><span class="text-xxs pr-1">©</span><span *ngIf="organizationData" class="uppercase"> {{generalTexts[currentLanguage].year}} {{organizationData.name[currentLanguage]
                }}. {{ generalTexts[currentLanguage].rights }} | {{generalTexts[currentLanguage].version}} {{ version }}</span>
                </div>
                <div class="flex link-container lgmin:justify-between lg:flex-col lg:items-center text-xs">
                    <span class="cursor-pointer hover-text-trajan lg:mb-6"
                        (click)="navigationService.navigateTo('legal')">{{generalTexts[currentLanguage].legal}}</span>
                    <span class="cursor-pointer hover-text-trajan lg:mb-6"
                        (click)="navigationService.navigateTo('privacy')">{{generalTexts[currentLanguage].privacy}}</span>
                    <span class="cursor-pointer hover-text-trajan lg:mb-6"
                        (click)="navigationService.navigateTo('cookies')">{{generalTexts[currentLanguage].cookies}}</span>
                </div>
        
                <div class="flex fit-content items-center lgmin:hidden mt-14">
                    <img class="mr-3 w-auto h-10 xssm:h-8" src="assets/img/footer-images/footer-1.svg" alt="">
                    <img class="mr-3 w-auto h-10 xssm:h-8" src="assets/img/footer-images/footer-2.svg" alt="">
                    <img class="mr-3 w-auto h-10 xssm:h-8" src="assets/img/footer-images/footer-3.png" alt="">
                    <img class="mr-3 w-auto h-10 xssm:h-8" src="assets/img/footer-images/footer-4.svg" alt="">
                    <img class="mr-3 w-auto h-10 xssm:h-8" src="assets/img/footer-images/footer-5.svg" alt="">
                    <img class="h-7 xssm:h-5" src="assets/img/footer-images/footer-6.svg" alt="">
                </div>
                <div class="lgmin:hidden mt-24 pb-28 flex flex-col items-center text-xs uppercase"><span *ngIf="organizationData" class="">© {{generalTexts[currentLanguage].year}} {{organizationData.name[currentLanguage]}}.</span>
                    <span class="text-center uppercase"> {{ generalTexts[currentLanguage].rights }}</span>
                    <span class="uppercase">{{generalTexts[currentLanguage].version}} {{ version }}</span>
                </div>
            </div>
        </div>


</div>