import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InterfaceService } from './interface.service';
import { SmoothScrollService } from './smooth-scroll.service';
import { FiltersService } from './filters.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  isFiltered = false;
  storedFilters = [];
  backBtnClicked = false;

  constructor(
    private router: Router,
    private interfaceService: InterfaceService,
    private smoothScroll: SmoothScrollService,
    private filters: FiltersService
  ) {}

  navigateTo(path: string) {
    this.interfaceService.isMenuOpen = false;
    this.interfaceService.isSidebarOpen = false;
    this.resetFilters();
    
    if(window.location.pathname.substring(1) === path) {
      this.reloadCurrentRoute();
    } else {
      this.router.navigate([path] );
    }
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

  resetFilters(){
    this.smoothScroll.scrollTarget = null;
    this.isFiltered = false;
    this.storedFilters = [];
    this.interfaceService.displayForm = false;
    this.interfaceService.displaySuccess = false;
    this.interfaceService.currentForm = null;

    this.filters.resetAll();

  }
}
