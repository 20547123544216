import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { InterfaceService } from 'src/app/services/interface.service';
import { MailingService } from 'src/app/services/mailing.service';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  formData: { name: string; subject: any; email: string; text: string };

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  constructor(
    public interfaceService: InterfaceService,
    private dataService: DataService,
    private mailingService: MailingService,
    private smoothScroll: SmoothScrollService
  ) {}

  ngOnInit(): void {
    this.interfaceService.displayDark = true;
    this.interfaceService.footerBackgroundChange = true;
    this.smoothScroll.scrollTarget = null;
  }

  handleSubmit(form) {
    const { value: values } = form;
    console.log('form values', values);

    this.mailingService.sendMessage(values).subscribe((response) => {
      console.log('RES', response);
    });
    form.reset();
  }
}
