<div class="pt-36 pb-20 pl-28  sm:pt-28 sm:pb-16 center md:pl-0">
    <!-- <canvas id="map"></canvas> -->
    <video id="map" src="./../../../assets/video/bagot-map.mp4" muted autoplay loop></video>
    <div class="w-140 h-180 py-12 px-20 box-shadow sm:px-10 sm:w-auto sm:h-auto sm:py-10 md:w-128 bg-white">
        <h3 class="uppercase text-gray-dark text-2xl mb-5 sm:text-center">{{ generalTexts[currentLanguage].contact }}
        </h3>

        <div class="mb-6 text-sm">
            <div class="mb-1"><span class="font-bold ">{{ generalTexts[currentLanguage].address }}</span></div>
            <div><span>Consell de Cent, 321</span></div>
            <div><span>08007 Barcelona SPAIN</span></div>
            <div><span>(+34) 93 140 53 26</span></div>
            <div><span>info&#64;jbagot.com</span></div>
        </div>

        <div class="mb-8 text-sm">
            <div class="mb-1"><span class="font-bold capitalize">{{ generalTexts[currentLanguage].schedule }}</span>
            </div>
            <div><span>{{ generalTexts[currentLanguage].monday_friday }}</span></div>
            <div><span>10:00h {{ generalTexts[currentLanguage].to }} 14:00h - 16:30h {{ generalTexts[currentLanguage].to }} 20:00h</span></div>
        </div>

        <form action="" #form="ngForm" (ngSubmit)="handleSubmit(form)">
            <input type="text" class="w-full mb-4 pl-4 h-11 focus:border-gray-dark focus:ring-0"
                [placeholder]="generalTexts[currentLanguage].name" [ngModel]="formData ? formData.name : null"
                name="name">
            <input type="email" class="w-full mb-4 pl-4 h-11 focus:border-gray-dark focus:ring-0"
                [placeholder]="generalTexts[currentLanguage].email" [ngModel]="formData ? formData.email : null"
                name="email">
            <input type="subject" class="w-full mb-4 pl-4 h-11 focus:border-gray-dark focus:ring-0"
                [placeholder]="generalTexts[currentLanguage].subject" [ngModel]="formData ? formData.subject : null"
                name="subject">

            <!-- <select
                class="form-select w-full text-sm mb-4 h-11 pl-4 cursor-pointer focus:border-gray-dark focus:ring-0"
                [(ngModel)]="defaultOption" name="subject" class="dropdown">
                <option  [value]="" class="h-11" [disabled]="true">{{ defaultOption }}</option>

                <option  *ngFor="let option of selectOptions1[currentLanguage].options; let i = index" [value]="option" class="h-11" [disabled]="i === 0">{{option}}</option>
    
            </select> -->
            <!-- <input type="text" class="w-full mb-4 pl-4 h-11 focus:border-gray-dark focus:ring-0"
                [placeholder]="generalTexts[currentLanguage].text" [ngModel]="formData ? formData.text : null"
                name="text"> -->
            <textarea name="" id="" cols="30" rows="3" class="w-full pl-4 focus:border-gray-dark focus:ring-0"
                [placeholder]="generalTexts[currentLanguage].text" [ngModel]="formData ? formData.text : null"
                name="text"></textarea>
            <!-- <input type="text" class="w-full mb-4 pl-4 h-11 focus:border-gray-dark focus:ring-0" 
            [placeholder]="generalTexts[currentLanguage].text"
            [ngModel]="formData ? formData.text : null" name="text" > -->

            <div class="flex justify-end mt-6 sm:justify-center">
                <button class="flex items-center justify-center bg-gray-dark px-6 py-3" type="submit">
                    <span class="text-white text-sm ">{{ generalTexts[currentLanguage].send }}</span>
                    <img class="h-3 w-2 ml-3 invert" src="assets/img/simple-arrow-right-dark.svg" alt="">
                </button>
            </div>
        </form>


    </div>
</div>