<div class="h-full">
    <swiper-container [navigation]="true" [initialSlide]="currentImage" class="lightbox-swiper" init="false"
        #lightboxSwiperRef>
        <swiper-slide *ngFor="let img of lightboxArr;">
            <div class=" w-full h-full flex justify-center items-center">
                <div class="test swiper-zoom-container">
                    <img [src]="img.path" alt="">
                </div>

            </div>
        </swiper-slide>
    </swiper-container>

    <div class="lightbox-btn-container absolute z-10">
        <button id="prevBtnLightbox" class="nav-btn flex justify-center items-center rotate-90"
            [disabled]=" swiper && swiper.activeIndex === 0"></button>
    </div>
    <div class="lightbox-btn-container absolute z-10  right-0">
        <button id="nextBtnLightbox" class="nav-btn flex justify-center items-center rotate-neg-90"
            [disabled]="onLastSlide"></button>
    </div>

</div>

<div class="fixed bottom-4 right-16  flex items-center z-10 text-gray-grayText" (click)="toggleZoom()">
    <!-- <div class="fixed bottom-4 right-16  flex items-center z-10" (click)="toggleZoom()"
    [ngClass]="isZoomedIn() ? 'zoom-in-grey' : 'text-gray-grayText' "> -->
    <span class="uppercase font-trajanBold text-xxs">{{ generalTexts[currentLanguage].zoom }}</span>
    <div class="ml-3 h-8 w-8 flex justify-center items-center rounded-full cursor-pointer  zoom-in-bg">
        <img *ngIf="!isZoomedIn()" src="assets/img/icon-zoom-in.svg" alt="" class="dark-filter">
        <img *ngIf="isZoomedIn()" src="assets/img/icon-zoom-out.svg" alt="" class="dark-filter">
    </div>
</div>