import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class InterfaceService {
  displayDark = true;
  footerBackgroundChange = true;

  isMenuOpen = false;
  startDisplay = false;
  startSidebarDisplay = false;
  isOpacityActivated = false;
  isSidebarOpacityActivated = false;
  isBlurActivated = false;
  isSidebarOpen = false;
  isLightboxOpen = false;

  firstLoad = false;
  languageChanged = false;
  resetArtworks = false;

  displayForm = false;
  displaySuccess = false;
  currentForm = null;

  isVideoPlayerOpen = false;

  constructor(private dataService: DataService) {}

  toggleSidebar() {
    if (!this.startSidebarDisplay) {
      this.startSidebarDisplay = true;
      this.isSidebarOpacityActivated = true;
      this.isSidebarOpen = true;
    } else {
      this.isSidebarOpen = false;
      this.isSidebarOpacityActivated = false;
      this.closeSidebar();
    }
  }

  closeSidebar() {
    let that = this;

    setTimeout(function () {
      that.startSidebarDisplay = false;
    }, 500);
  }

  revealTimeout() {
    setTimeout(() => {
      this.reveal();
    }, 0);
  }

  reveal() {
    const reveals = document.querySelectorAll('.reveal');
    const windowHeight = window.innerHeight + 100;

    reveals.forEach((reveal, index) => {
      const elementTop = reveal.getBoundingClientRect().top;

      if (elementTop < windowHeight) {
        const delay = index % 3 === 0 ? 150 : index % 2 === 0 ? 300 : 0;
        setTimeout(() => {
          reveal.classList.add('active');
          }, delay);
          } else {
        reveal.classList.remove('active');

      }
    });
  }

  sortItems(arr, property) {
    const sortedArr = arr.sort((a, b) => {
      if (this.dataService.entityCache[a] && this.dataService.entityCache[b]) {
        return this.dataService.entityCache[a][property][
          this.dataService.currentLanguage
        ].localeCompare(
          this.dataService.entityCache[b][property][
            this.dataService.currentLanguage
          ]
        );
      }
    });

    return sortedArr;
  }
}
