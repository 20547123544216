import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ArtworkDetailsComponent } from './pages/artworks/artwork-details/artwork-details.component';
import { ArtworksComponent } from './pages/artworks/artworks.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ExhibitionDetailsComponent } from './pages/exhibitions/exhibition-details/exhibition-details.component';
import { ExhibitionsComponent } from './pages/exhibitions/exhibitions.component';
import { AppraisersComponent } from './pages/gallery-info-terms/appraisers/appraisers.component';
import { NotableSalesComponent } from './pages/notable-sales/notable-sales.component';
import { PublicationsComponent } from './pages/publications/publications.component';
import { YoungCollectorsComponent } from './pages/young-collectors/young-collectors.component';
import { AuthenticityComponent } from './pages/gallery-info-terms/authenticity/authenticity.component';
import { PrivacyCookiesLayoutComponent } from './pages/gallery-info-terms/privacy-cookies-layout/privacy-cookies-layout.component';
import { AccessGuard } from './guards/access.guard';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { WorkWithUsComponent } from './work-with-us/work-with-us.component';
import { InitResolver } from './resolvers/init.resolver';
import { AppComponent } from './app.component';
import { MainInterfaceComponent } from './main-interface/main-interface.component';
import { PaintingsComponent } from './pages/paintings/paintings.component';
import { PaintingDetailsComponent } from './pages/paintings/painting-details/painting-details.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AccessGuard],
    component: MainInterfaceComponent,
    resolve: {
      auth: InitResolver,
    },
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: 'artworks',
        component: ArtworksComponent,
      },
      {
        path: 'artworks/:id',
        component: ArtworkDetailsComponent,
      },
      {
        path: 'paintings',
        component: PaintingsComponent,
      },
      {
        path: 'paintings/:id',
        component: PaintingDetailsComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'exhibitions',
        component: ExhibitionsComponent,
      },
      {
        path: 'exhibitions/:id',
        component: ExhibitionDetailsComponent,
      },
      {
        path: 'artworks-in-museums',
        component: NotableSalesComponent,
      },
      {
        path: 'publications',
        component: PublicationsComponent,
      },
      {
        path: 'young-collectors',
        component: YoungCollectorsComponent,
      },
      {
        path: 'appraisers',
        component: AppraisersComponent,
      },
      {
        path: 'authenticity',
        component: AuthenticityComponent,
      },
      {
        path: 'privacy',
        component: PrivacyCookiesLayoutComponent,
      },
      {
        path: 'cookies',
        component: PrivacyCookiesLayoutComponent,
      },
      {
        path: 'legal',
        component: PrivacyCookiesLayoutComponent,
      },
      {
        path: 'conditions',
        component: PrivacyCookiesLayoutComponent,
      },
      {
        path: 'work-with-us',
        component: WorkWithUsComponent,
      },
      {
        path: 'coming-soon',
        component: ComingSoonComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
