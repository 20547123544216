<div class="pt-40 pb-12 text-sm sm:pt-24">
    <div class="mb-12 pb-14 sm:border-0 sm:pb-0 sm:mb-6 reveal">

        <div class="flex justify-center mb-3">
            <h3 class="uppercase text-center text-gray-darkest text-2xl sm:px-12">{{
                generalTexts[currentLanguage].young_title }}</h3>
        </div>
        <div class="flex justify-between px-6 pb-3 border-b sm:border-gray-dropdown  reveal">
            <div class="smmin:hidden flex items-center mr-9 cursor-pointer xxs:mr-1 layout-1"
                (click)="interfaceService.toggleSidebar()">
                <span class="font-bold mr-2 whitespace-nowrap">{{ generalTexts[currentLanguage].filter }}</span>
                <img src="assets/img/filter.svg" alt="">
            </div>
            <div class="search-container">
                <input type="text" (keyup)="filters.search($event, 'youngCollectors')" [(ngModel)]="filters.searchValue"
                    class="form-input rounded-full border focus:border-gray-medium focus:ring-0 h-10 pl-5 search-bg sm:w-40 border-gray-dropdown"
                    [placeholder]="generalTexts[currentLanguage].search">
            </div>
        </div>
    </div>

    <div class="flex items-center cursor-pointer px-6 pt-4 layout-2 reveal" (click)="interfaceService.toggleSidebar()">
        <span class=" font-bold mr-2 whitespace-nowrap">{{ generalTexts[currentLanguage].filter }}</span>
        <img src="assets/img/filter.svg" alt="">
    </div>
    <div class="px-20 flex sm:px-4 xs:px-4">

        <div class="block sm:hidden reveal">
            <div class="w-56 mb-14 flex flex-col">
                <h4 class="text-gray-outline text-xl border-b pb-4 mb-4 capitalize">{{
                    generalTexts[currentLanguage].cultures }}</h4>

                <label *ngFor="let item of culturesArr; let i = index" class="custom-checkbox capitalize">
                    <input [id]="item" type="checkbox" (change)="triggerCheck(item, $event)">
                    <p class="checkmark"></p>
                    <span *ngIf="entityCache[item]">{{entityCache[item].name[currentLanguage]}}</span>
                </label>

            </div>
            <div class="w-56 mb-14 flex flex-col">
                <h4 class="text-gray-outline text-xl border-b pb-4 mb-4">{{ generalTexts[currentLanguage].typologies }}
                </h4>

                <label *ngFor="let item of typologiesArr; let i = index" class="custom-checkbox capitalize">
                    <input [id]="item" type="checkbox" (change)="triggerCheck(item, $event)">
                    <p class="checkmark"></p>
                    <span *ngIf="entityCache[item]">{{entityCache[item].name[currentLanguage]}}</span>
                </label>

            </div>
            <div class="w-56 mb-14 flex flex-col">
                <h4 class="text-gray-outline text-xl border-b pb-4 mb-4">{{ generalTexts[currentLanguage].materials }}
                </h4>

                <label *ngFor="let item of materialsArr; let i = index" class="custom-checkbox capitalize">
                    <input [id]="item" type="checkbox" (change)="triggerCheck(item, $event)">
                    <p class="checkmark"></p>
                    <span *ngIf="entityCache[item]">{{entityCache[item].name[currentLanguage]}}</span>
                </label>

            </div>
        </div>

        <div class="flex justify-center ml-3 sm:ml-0 w-full">
            <div *ngIf="!filters.displayFiltered && artworks"
                class="flex flex-wrap justify-start fit-content  sm:justify-center w-auto sm:px-0  sm:my-6 lg:px-12">
                <div *ngFor="let artwork of artworks" class="reveal">
                    <a *ngIf="artwork" [routerLink]="['/artworks/' + artwork.customId]">

                        <div [id]="artwork.customId" (click)="storeScrollTarget(artwork.customId)"
                            class="flex flex-col items-center text-gray-darkest cursor-pointer w-56 ml-8 mb-9 sm:w-40 sm:mx-3 sm:mb-6 xs:mx-1 xs:w-full">

                            <div class="w-full h-56 sm:w-40 sm:h-40 xs:w-full xs:h-auto overflow-hidden relative">
                                <div class="w-full h-56 sm:w-40 sm:h-40 xs:w-full placeholder-container">
                                </div>
                                <img *ngIf="getCarouselMainImg(artwork.customId, 'imageCarousel')"
                                    class="w-full h-56 object-cover sm:w-40 sm:h-40 xs:w-full xs:h-auto"
                                    [src]="getCarouselMainImg(artwork.customId, 'imageCarousel')" alt="">
                            </div>
                            <hr class="border-t w-12 border-gray-medium mt-5 mb-3">

                            <div class=" text-center w-full"><span *ngIf="artwork.name"
                                    class="uppercase text-gray-darkest font-trajan line-clamp-3">{{artwork.name[currentLanguage]}}</span>
                            </div>
                            <div class="text-center "><span
                                    *ngIf="getFirstEntityName(artwork.customId, 'culture')">{{getFirstEntityName(artwork.customId,
                                    'culture')[currentLanguage]}} ·
                                </span><span
                                    *ngIf="getFirstEntityName(artwork.customId, 'period')">{{getFirstEntityName(artwork.customId,
                                    'period')[currentLanguage]}}</span></div>

                        </div>
                    </a>
                </div>
            </div>

            <div *ngIf="filters.displayFiltered && !filters.filteredArtworks.length" class="p-8 text-center"><span>{{
                    generalTexts[currentLanguage].not_found }}</span></div>

            <div *ngIf="filters.displayFiltered && filters.filteredArtworks.length"
                class="flex flex-wrap justify-start fit-content  sm:justify-center w-auto sm:px-0  sm:my-6 lg:px-12">
                <div *ngFor="let artwork of filters.filteredArtworks" class="reveal">
                    <a *ngIf="artwork" [routerLink]="['/artworks/' + artwork.customId]">

                        <div [id]="artwork.customId" (click)="storeScrollTarget(artwork.customId)"
                            class="flex flex-col items-center overflow-hidden text-gray-darkest cursor-pointer w-56 ml-8 mb-9 sm:w-40 sm:mx-3 sm:mb-6 xs:mx-1 xs:w-full">

                            <div class="w-full h-56 sm:w-40 sm:h-40 xs:w-full xs:h-auto overflow-hidden relative">
                                <div class="w-full h-56 sm:w-40 sm:h-40 xs:w-full placeholder-container">
                                </div>
                                <img *ngIf="getCarouselMainImg(artwork.customId, 'imageCarousel')"
                                    class="w-full h-56 object-cover sm:w-40 sm:h-40 xs:w-full xs:h-auto"
                                    [src]="getCarouselMainImg(artwork.customId, 'imageCarousel')" alt="">
                            </div>

                            <hr class="border-t w-12 border-gray-medium mt-5 mb-3">

                            <div class=" text-center w-full"><span *ngIf="artwork.name"
                                    class="uppercase text-gray-darkest font-trajan line-clamp-3">{{artwork.name[currentLanguage]}}</span>
                            </div>
                            <div class="text-center "><span
                                    *ngIf="getFirstEntityName(artwork.customId, 'culture')">{{getFirstEntityName(artwork.customId,
                                    'culture')[currentLanguage]}} ·
                                </span><span
                                    *ngIf="getFirstEntityName(artwork.customId, 'period')">{{getFirstEntityName(artwork.customId,
                                    'period')[currentLanguage]}}</span></div>

                        </div>
                    </a>
                </div>
            </div>


        </div>

    </div>

</div>

<!-- SIDEBAR -->
<app-content-sidebar>
    <div class="flex flex-col py-5 border-b h-full overflow-y-auto hide-scrollbar text-sm">
        <div class="px-6 mb-6 pb-3 border-b flex flex-col">
            <h4 class="text-gray-outline text-xl mb-4">{{ generalTexts[currentLanguage].cultures }}</h4>

            <label *ngFor="let item of culturesArr; let i = index" class="custom-checkbox mb-2 capitalize">
                <input [id]="'sidebar-' + item" type="checkbox" (change)="triggerCheck(item, $event)">
                <p class="checkmark"></p>
                <span *ngIf="entityCache[item]">{{entityCache[item].name[currentLanguage]}}</span>
            </label>

        </div>
        <div class="px-6 mb-6 pb-3  border-b flex flex-col">
            <h4 class="text-gray-outline text-xl mb-4">{{ generalTexts[currentLanguage].typologies }}</h4>

            <label *ngFor="let item of typologiesArr; let i = index" class="custom-checkbox mb-2 capitalize">
                <input [id]="'sidebar-' + item" type="checkbox" (change)="triggerCheck(item, $event)">
                <p class="checkmark"></p>
                <span *ngIf="entityCache[item]">{{entityCache[item].name[currentLanguage]}}</span>
            </label>

        </div>
        <div class="px-6 pb-3 flex flex-col">
            <h4 class="text-gray-outline text-xl mb-4">{{ generalTexts[currentLanguage].materials }}</h4>

            <label *ngFor="let item of materialsArr; let i = index" class="custom-checkbox mb-2 capitalize">
                <input [id]="'sidebar-' + item" type="checkbox" (change)="triggerCheck(item, $event)">
                <p class="checkmark"></p>
                <span *ngIf="entityCache[item]">{{entityCache[item].name[currentLanguage]}}</span>
            </label>

        </div>
    </div>
</app-content-sidebar>