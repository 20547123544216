<div *ngIf="interfaceService.isLightboxOpen" class="w-full h-full bg-white fixed top-0 z-20 lightbox-container">
    <div class="flex justify-between px-6 pt-4 relative z-10">
        <img class="w-48 invert" src="assets/img/bagot-logo-full.svg" alt="">
        <div class="flex cursor-pointer" (click)="closeLightBox()">
            <span class="uppercase font-trajanBold text-xxs mr-2">{{ generalTexts[currentLanguage].close }}</span>
            <img class="invert w-3 h-3" src="assets/img/icon-close.svg" alt="">
        </div>
    </div>

    <div class="swiper-container">
        <app-lightbox-swiper [lightboxArr]="lightboxArr" [currentImage]="currentImage"></app-lightbox-swiper>
    </div>

</div>