<div class="pt-36 pb-12 sm:pt-24 text-sm">

    <div>
        <div class="flex justify-center mb-12 sm:mb-6 reveal">
            <h3 class="uppercase text-2xl text-center">{{ generalTexts[currentLanguage].publications_title }}</h3>
        </div>
        <!-- <div class="flex justify-center pb-12 border-b sm:hidden md:flex-wrap ">
            <div *ngFor="let year of yearsArr">
                <button class="border border-gray-medium w-32 h-12 uppercase mx-6 lg:mx-4 md:mb-4">{{ year }}</button>
            </div>
            <div>
                <button class="border border-gray-medium w-52 h-12 uppercase mx-6 lg:mx-4">ver todo</button>
            </div>
        </div> -->
    </div>

    <!-- <div class="smmin:hidden flex justify-between px-6 pb-3 border-b sm:border-gray-dropdown">
        <div class="flex items-center mr-9 cursor-pointer" (click)="interfaceService.toggleSidebar()">
            <span class="font-bold mr-2 whitespace-nowrap">{{ generalTexts[currentLanguage].filter }}</span>
            <img src="assets/img/filter.svg" alt="">
        </div>
        <div>
            <input type="text"
                class="form-input rounded-full border border-gray-medium focus:border-gray-medium focus:ring-0 w-80 h-10 pl-5 search-bg sm:w-40 sm:border-gray-dropdown"
                placeholder="Buscar">
        </div>
    </div> -->

    <div class="w-full text-center uppercase bg-light-offWhite py-2 reveal"><span class=" text-xs">{{ generalTexts[currentLanguage].shipping }}</span></div>


    <div class="mx-24 mt-14 sm:mx-6 sm:mt-6 lg:mx-12 3xlmin:mx-0 3xlmin:flex 3xlmin:justify-center">
        <div class="flex flex-wrap justify-center sm:w-full 3xlmin:w-350">
            <div *ngFor="let publication of publications; let i = index"
                class="flex flex-col items-center text-gray-darkest w-72 mb-16 mx-6 sm:mx-0 sm:w-auto reveal">
                <div class="img-container">
                    <img class="" [src]="publication.imgPath" alt="">
                </div>
                <hr class="border-t w-12 border-gray-medium mt-5 mb-6">

                <div class="text-xs font-bold mb-4"><span>{{publication.name}}</span></div>
                <div class="flex justify-center w-60 font-trajan"><span
                        class="uppercase text-base text-center line-clamp-2 mb-4">{{publication.subtitle}}</span></div>
                <div class="text-xs"><span>{{publication.pages}} {{ generalTexts[currentLanguage].pages }}</span></div>
                <div class="flex w-72 flex-col sm:w-full">
                    <div class="flex justify-between w-full">

                        <button (click)="navigateExternal(publication.link)" class="flex items-center justify-center border border-gray-medium text-xs px-4 py-2 mt-8 h-12 sm:w-44 sm:h-11 xs:w-40">
                            <span class="text-gray-darkest ">{{ generalTexts[currentLanguage].see_publication }}</span>
                            <img class="h-3 w-2 ml-2" src="assets/img/simple-arrow-right-dark.svg" alt="">
                        </button>
                        <button (click)="openForm(publication.name)" class="flex items-center justify-center bg-gray-dark text-xs text-white px-4 py-2 mt-8 h-12 sm:w-44 sm:h-11 xs:w-40">
                            <span>{{ generalTexts[currentLanguage].ask }} </span><span class="font-bold pl-2"> {{publication.price}}</span>
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

</div>
<app-consult-form [name]="formDetails['name']" [formDetails]="formDetails"></app-consult-form>