import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment"

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private apiRoot = '/auth';

    isLogged;

    constructor(private http: HttpClient){}

    public autoLogin(){
        const body = {
            username: 'bagotWebUser',
            password: '2P7RGzjsHBauzZnYvRhA'
        }
        return this.http.post<any>(`${environment.graphql_apiUrl}${this.apiRoot}/login-front`, body);
    }
}