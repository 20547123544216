import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { InterfaceService } from 'src/app/services/interface.service';

@Component({
  selector: 'app-sale-btn-group',
  templateUrl: './sale-btn-group.component.html',
  styleUrls: ['./sale-btn-group.component.scss'],
})
export class SaleBtnGroupComponent implements OnInit {
  @Input() currentArtwork;

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  currencySymbol = {
    EUR: '€',
  };


  constructor(
    private dataService: DataService,
    public interfaceService: InterfaceService
  ) {}

  ngOnInit(): void {}
}
