import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { InterfaceService } from '../../services/interface.service';
import { MailingService } from '../../services/mailing.service';

@Component({
  selector: 'app-consult-form',
  templateUrl: './consult-form.component.html',
  styleUrls: ['./consult-form.component.scss'],
})
export class ConsultFormComponent implements OnInit {
  @Input() formDetails;
  @Input() name;
  formData: { name: string; email: string; phone: string; text: string };

  get generalTexts() {
    return this.dataService.generalTexts;
  }

  get currentLanguage() {
    return this.dataService.currentLanguage;
  }

  constructor(
    public interfaceService: InterfaceService,
    private mailingService: MailingService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {}

  handleSubmit(form, details) {
    const { value: values } = form;

    let subject;

    const emailData = {
      subject: '',
      email: '',
      name: '',
      phone: '',
      text: '',
      img: '',
    };

    const isEmpty = Object.values(values).some((x) => x === null || x === '');
    if (isEmpty) {
      console.log('fields empty');
    } else {
      if (details.type === 'artwork') {
        subject = 'REF: ' + details.ref;
      } else if (details.type === 'publication') {
        subject = 'Catalogue: ' + details.name;
      }

      emailData.subject = subject;
      emailData.name = values.name;
      emailData.email = values.email;
      emailData.phone = values.phone;
      emailData.text = values.text;
      if (details.img) {
        emailData.img = details.img;
      }

      this.mailingService.sendMessage(emailData).subscribe((response) => {
        console.log('RES', response);
      });

      form.reset();
      this.interfaceService.displaySuccess = true;
    }
  }

  closeForm() {
    this.interfaceService.displayForm = false;
    this.interfaceService.displaySuccess = false;
  }
}
